import {
  defaultBrands,
  allowedAuthMethods
} from '@/configuration/sources/smartform/googlePay.yml'
import { CoBrands, SubBrands } from '@/configuration/sources/Brands.yml'

const baseRequest = {
  apiVersion: 2,
  apiVersionMinor: 0,
  emailRequired: true
}

export function getIsReadyToPayRequest({
  cvcRequired,
  dnaBrands,
  shippingAddressRequired
}) {
  return {
    ...baseRequest,
    allowedPaymentMethods: [
      baseCardPaymentMethod({
        cvcRequired,
        dnaBrands,
        shippingAddressRequired
      })
    ]
  }
}

/**
 * @typedef {{merchantName: string, merchantId: string, gateway: string, gatewayMerchantId: string, cvcRequired: boolean | undefined , countryCode: string, currencyCode: string, totalPrice: string, dnaBrands: string[] }} GooglePaymentParams
 */
/**
 * Configure support for the Google Pay API
 *  @param {GooglePaymentParams} param0
 * @see {@link https://developers.google.com/pay/api/web/reference/object#PaymentDataRequest|PaymentDataRequest}
 * @returns {object} PaymentDataRequest fields
 */
export function getGooglePaymentDataRequest({
  merchantName,
  merchantId,
  merchantOrigin,
  gateway,
  gatewayMerchantId,
  cvcRequired,
  countryCode,
  currencyCode,
  totalPrice,
  dnaBrands,
  shippingAddressRequired
}) {
  return {
    ...baseRequest,
    allowedPaymentMethods: [
      getCardPaymentMethod({
        gateway,
        gatewayMerchantId,
        cvcRequired,
        dnaBrands,
        shippingAddressRequired
      })
    ],
    transactionInfo: getGoogleTransactionInfo({
      countryCode,
      currencyCode,
      totalPrice
    }),
    merchantInfo: { merchantId, merchantName, merchantOrigin },
    shippingAddressRequired
  }
}

export function getAllowedCardNetworks({ dnaBrands }) {
  const defaultNetworks = defaultBrands
  if (!dnaBrands) return []

  const allowedGoogleBrands = dnaBrands.map(brand => {
    // LATAM brand variation to generic brand
    let formattedBrand = CoBrands.brands[brand] ?? brand
    // Specific changes of sub-brands must adapt to googlePay supported brands
    formattedBrand = SubBrands[formattedBrand] ?? formattedBrand
    return formattedBrand
  })

  return defaultNetworks.filter(b => allowedGoogleBrands.includes(b))
}

function baseCardPaymentMethod({
  cvcRequired,
  dnaBrands,
  shippingAddressRequired
}) {
  return {
    type: 'CARD',
    parameters: {
      allowedAuthMethods,
      allowedCardNetworks: getAllowedCardNetworks({ dnaBrands }),
      billingAddressRequired: true,
      billingAddressParameters: {
        // When asking for the shipping address we need to ask for the complete Billing details as well
        format: shippingAddressRequired ? 'FULL' : 'MIN'
      },
      assuranceDetailsRequired: true,
      cvcRequired
    }
  }
}

/**
 * Identify your gateway and your site's gateway merchant identifier
 *
 * The Google Pay API response will return an encrypted payment method capable
 * of being charged by a supported gateway after payer authorization
 *
 * @todo check with your gateway on the parameters to pass
 * @see {@link https://developers.google.com/pay/api/web/reference/object#Gateway|PaymentMethodTokenizationSpecification}
 */
function tokenizationSpecification({ gateway, gatewayMerchantId }) {
  return {
    type: 'PAYMENT_GATEWAY',
    parameters: {
      gateway,
      gatewayMerchantId
    }
  }
}

/**
 * Describe your site's support for the CARD payment method including optional
 * fields
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/object#CardParameters|CardParameters}
 */
function getCardPaymentMethod({
  gateway,
  gatewayMerchantId,
  cvcRequired,
  dnaBrands,
  shippingAddressRequired
}) {
  return {
    ...baseCardPaymentMethod({
      cvcRequired,
      dnaBrands,
      shippingAddressRequired
    }),
    tokenizationSpecification: tokenizationSpecification({
      gateway,
      gatewayMerchantId
    })
  }
}

/**
 * Provide Google Pay API with a payment amount, currency, and amount status
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/object#TransactionInfo|TransactionInfo}
 * @returns {object} transaction info, suitable for use as transactionInfo property of PaymentDataRequest
 */
function getGoogleTransactionInfo({ countryCode, totalPrice, currencyCode }) {
  return {
    currencyCode,
    totalPriceStatus: 'ESTIMATED',
    // set to cart total
    totalPrice,
    countryCode: countryCode
  }
}
