var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "kr-sp-panel-option", class: _vm.dynamicClasses },
    [
      _c("SplitPaymentPanelOptionActivator", {
        attrs: { selected: _vm.selected, label: _vm.label },
        on: {
          clicked: function ($event) {
            return _vm.onActivated()
          },
        },
      }),
      _vm.hasContent
        ? _c(
            "div",
            {
              ref: "content",
              staticClass: "kr-sp-panel-option__content",
              class: _vm.contentClasses,
            },
            [_vm._t("content")],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }