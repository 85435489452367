import cssVariables from '@/configuration/sources/CssVariables.yml'
import CssVariableVariations from '@/configuration/CssVariableVariations'

export const fieldStyle = {
  default: {
    backgroundColor: null,
    color: null,
    iconColor: null,
    icon: {
      visibility: null
    }
  },
  error: {
    backgroundColor: null,
    color: null,
    iconColor: null
  }
}

// Recursively search for store properties to be converted to css variables
export function getCssVariables(configSection, prefix, variables) {
  const { whitelist, global_variables } = cssVariables
  Object.entries(configSection).forEach(([key, value]) => {
    const cssVar = `${prefix}-${key}`
    // Property must be a string and exist on whitelabel or variable list
    if (
      (typeof value === 'string' || value instanceof String) &&
      (whitelist.includes(key) || global_variables.includes(key))
    ) {
      variables[cssVar] = value
    } else if (
      typeof value === 'object' &&
      !Array.isArray(value) &&
      value !== null
    ) {
      // One level deeper into the config object
      getCssVariables(value, cssVar, variables)
    }
  })
}

export function addCssVariables(variables, getValueFromCssVar) {
  // Add every variable found and create their variations
  Object.entries(variables).forEach(([varName, value]) => {
    document.documentElement.style.setProperty(varName, value)
    createCssVarVariants(varName, value, getValueFromCssVar)
  })
}

export function createCssVarVariants(varName, value, getValueFromCssVar) {
  const variations = CssVariableVariations[varName]

  if (variations) {
    CssVariableVariations[varName].forEach(({ variable, factory }) => {
      const variationValue = getValueFromCssVar(variable)
      // The variation variable must not have been specified in the store in order to dynamically add the variable.
      if (!variationValue)
        document.documentElement.style.setProperty(variable, factory(value))
    })
  }
}
