import _ from 'underscore'
import { getFieldsProperties } from '@/store/utils/css'

export const getCssState = () => {
  return {
    css: {
      ...getFieldsProperties({
        outer: {},
        innerDefault: {},
        innerError: {},
        fieldContainer: {},
        fieldRelativeWrapper: {},
        inputDefault: {},
        inputError: {},
        disabled: {}
      })
    }
  }
}

export const cssActions = {}
export const cssMutations = {}
export const cssGetters = {}
