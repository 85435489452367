import { RGBColor } from '@/common/model/color/RGBColor'

export class HSLColor {
  static fromRGB({ r, g, b }) {
    // Calc fractions of 1
    r /= 255
    g /= 255
    b /= 255

    // Find min and max values and get the difference
    let cmin = Math.min(r, g, b)
    let cmax = Math.max(r, g, b)
    let delta = cmax - cmin

    let h = 0
    let s = 0
    let l = 0

    // Calculate hue
    // No difference
    if (delta == 0) h = 0
    // Red is max
    else if (cmax == r) h = ((g - b) / delta) % 6
    // Green is max
    else if (cmax == g) h = (b - r) / delta + 2
    // Blue is max
    else h = (r - g) / delta + 4

    h = Math.round(h * 60)

    // Make negative hues positive behind 360°
    if (h < 0) h += 360

    // Calculate lightness
    l = (cmax + cmin) / 2

    // Calculate saturation
    s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))

    // Multiply l and s by 100
    s = +(s * 100).toFixed(1)
    l = +(l * 100).toFixed(1)

    return new HSLColor({ h, s, l })
  }

  static fromHex(hex) {
    return this.fromRGB(RGBColor.fromHex(hex))
  }

  constructor({ h, s, l }) {
    Object.assign(this, { h, s, l })
  }

  toString() {
    return `hsl(${this.h}, ${this.s}%, ${this.l}%)`
  }

  lighten(percentage) {
    if (typeof percentage !== 'number' || percentage > 100 || percentage < -100)
      throw new Error('percentage must be a number between -100 and 100')
    this.l += (100 - this.l) * (percentage / 100)
  }
}
