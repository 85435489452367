var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "kr-popin-amount" }, [
    _c("span", { style: { color: _vm.merchant.header.amount.color } }, [
      _vm._v(_vm._s(_vm.totalAmountLabel)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }