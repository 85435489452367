<template lang="pug">
  .kr-sp-panel-separator(v-show="hasInstallmentSelected")
    hr
</template>

<script>
import { mapGetters } from 'vuex'

/**
 * Visual separator to show once an option with installments is selected.
 *
 * @since KJS-4368
 */
export default {
  name: 'SplitPaymentPanelSeparator',

  computed: {
    ...mapGetters(['hasInstallmentSelected'])
  }
}
</script>
