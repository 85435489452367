<template lang="pug">
  button.kr-sp-option-activator(type="button", @click="onClick()", :class="dynamicClasses")
    SplitPaymentPanelOptionRadio(:checked="selected")
    span.kr-sp-option-activator-label {{ label }}
</template>

<script>
import SplitPaymentPanelOptionRadio from '@/host/components/smartform/splitPayment/PanelOptionRadio'

/**
 * Clickable part of SplitPayment panel option.
 * Simply emit en event when clicked and let the parents handle the logic.
 *
 * @since KJS-4363
 */
export default {
  name: 'SplitPaymentPanelOptionActivator',
  components: {
    SplitPaymentPanelOptionRadio
  },
  props: {
    selected: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: true
    }
  },
  computed: {
    dynamicClasses() {
      return {
        'kr-sp-option-activator--selected': this.selected
      }
    }
  },
  methods: {
    onClick() {
      this.$emit('clicked')
    }
  }
}
</script>
