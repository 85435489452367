var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "kr-methods-list", class: _vm.dynamicClasses.wrapper },
    [
      _vm.renderListHeader
        ? _c("div", { staticClass: "kr-methods-list-header" }, [
            _vm._v(
              _vm._s(_vm._f("colon")(_vm._f("capitalize")(_vm.listHeaderLabel)))
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "kr-methods-list-inner-wrapper" },
        [
          _c("PartialPaymentPanel"),
          _vm.renderExtraOptions
            ? _c(
                "div",
                {
                  staticClass:
                    "kr-methods-list-options kr-methods-list-options--extra",
                  class: _vm.dynamicClasses.listOptions,
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.walletMode,
                          expression: "walletMode",
                        },
                      ],
                      staticClass: "kr-methods-list-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "kr-smart-form-list-section-name" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("colon")(
                                _vm._f("capitalize")(_vm.walletHeaderLabel)
                              )
                            )
                          ),
                        ]
                      ),
                      _vm.walletMode
                        ? _c("SmartFormWallet", {
                            attrs: {
                              "all-locked": _vm.allLocked,
                              compact: _vm.isCompactWallet,
                              methods: _vm.filteredPaymentMethods.toString(),
                            },
                            on: { selected: _vm.loadMethod },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.renderIsolatedCards
                    ? _c("div", { staticClass: "kr-methods-list-row" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  !_vm.hasCardFormExpanded ||
                                  (_vm.walletMode && !_vm.registerMode),
                                expression:
                                  "!hasCardFormExpanded || (walletMode && !registerMode)",
                              },
                            ],
                            staticClass: "kr-smart-form-list-section-name",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("colon")(
                                  _vm._f("capitalize")(_vm.registerCardLabel)
                                )
                              )
                            ),
                          ]
                        ),
                        !_vm.hasCardFormExpanded
                          ? _c(
                              "div",
                              {
                                staticClass: "kr-methods-list-options-item",
                                class: _vm.methodClass("CARDS"),
                              },
                              [
                                _c("SmartFormMethodLabel", {
                                  key: "CARDS",
                                  attrs: {
                                    method: "CARDS",
                                    locked: _vm.allLocked,
                                  },
                                  on: { selected: _vm.loadMethod },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.hasCardFormExpanded
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "kr-methods-list-card-form-wrapper",
                              },
                              [
                                _vm.shouldRenderSPCardHeader
                                  ? _c("SplitPaymentCardLabel", {
                                      staticClass:
                                        "kr-smart-form-list-section-name",
                                    })
                                  : _vm._e(),
                                _vm.hasCardHeader
                                  ? _c("SmartFormCardHeader")
                                  : _vm._e(),
                                _vm.registerMode || _vm.hasPartialPayments
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "kr-smart-form-list-section-name",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("colon")(
                                              _vm._f("capitalize")(
                                                _vm.registerCardLabel
                                              )
                                            )
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._t("cardForm"),
                              ],
                              2
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm.hasAnyItem
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "kr-smart-form-list-section-name kr-smart-form-list-section-name--other",
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("colon")(
                                _vm._f("capitalize")(_vm.otherLabel)
                              )
                            )
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "kr-methods-list-options",
              class: _vm.dynamicClasses.listOptions,
            },
            [
              _vm._l(_vm.items, function (methods, key) {
                return [
                  key === "single"
                    ? _vm._l(methods, function (method) {
                        return _c(
                          "div",
                          {
                            key: method,
                            staticClass: "kr-methods-list-options-item",
                            class: _vm.methodClass(method),
                          },
                          [
                            _c("SmartFormMethodLabel", {
                              key: method,
                              attrs: { method: method, locked: _vm.allLocked },
                              on: { selected: _vm.loadMethod },
                            }),
                          ],
                          1
                        )
                      })
                    : _vm._e(),
                  key !== "single"
                    ? _c(
                        "div",
                        { staticClass: "kr-methods-list-options-item" },
                        [
                          _c("SmartFormMethodsGroup", {
                            key: key,
                            attrs: {
                              name: key,
                              "payment-methods": methods,
                              locked: _vm.allLocked,
                              "icons-filter": _vm.getIcons(key),
                            },
                            on: { selected: _vm.loadGroup },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm.mode === "groups"
        ? _c("SmartFormError")
        : _vm.mode === "methods"
        ? _c("SmartFormGroupError")
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }