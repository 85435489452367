import _ from 'underscore'
import AbstractStrategy from '@/ghost/workflow/strategy/AbstractStrategy'
import ErrorsConf from '@/configuration/sources/ErrorsConfiguration.yml'

export default class RegularStrategy extends AbstractStrategy {
  constructor(fieldName, iframeHandler, $locator, formId) {
    super(fieldName)
    this.value = ''
    this.$locator = $locator
    if (fieldName === 'doRegister') this.value = false
    this.targetIFrame = fieldName
    this.iframeHandler = iframeHandler
    this.storeFactory = $locator.storeFactory
    this.proxy = $locator.proxy
    this.formId = formId
  }

  getValue() {
    return this.$locator.$store.state[`cardForm_${this.formId}`]
      .nonSensitiveValues[this.fieldName]
  }

  validate(silent, field, fields) {
    const { $store } = this.$locator
    const isFieldViable =
      $store.getters[`cardForm_${this.formId}/isFieldViable`]
    const getRegularFieldError =
      $store.getters[`cardForm_${this.formId}/getRegularFieldError`]

    if (!isFieldViable(this.fieldName)) {
      const errorCode =
        getRegularFieldError(this.fieldName) ??
        ErrorsConf.fields.default[this.fieldName]
      return {
        errorCode,
        field: this.fieldName
      }
    }

    return null
  }

  setAutofillValue({ fieldName, value }) {
    // sending through the host app to avoid a race condition with other nonSensitiveValues updates in the synchronizer
    this.proxy.send(
      this.storeFactory.create('nonSensitiveAutofillUpdate', {
        formId: this.formId,
        payload: { [fieldName]: value }
      })
    )
  }
}
