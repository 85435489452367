import { uniqueId, object } from 'underscore'
import Zepto from 'zepto-webpack'

import renderCustomFields from '@/host/custom/Renderer'
import {
  fieldNameAsSelector,
  fieldNameAsClassName,
  fieldNameAsComponent
} from '@/common/util/fieldName'
import { fillRequiredElements, innerText } from '@/common/util/dom'
import { injectIntoHead } from '@/common/util/style'
import fieldTypes from '@/configuration/sources/FieldTypes.yml'
import Events from '@/configuration/Events'

import Mimic from '@/host/render/Mimic'
import SmartFormRenderer from '@/host/render/SmartForm'
import { spacedToKebabCase } from '@/common/util/string'

export default class FormRenderer {
  constructor({
    $bus,
    $store,
    storeFactory,
    ghostQueue,
    formStyler,
    iframeBuilder,
    domReader
  }) {
    this.$bus = $bus
    this.$store = $store
    this.$getters = $store.getters
    this.storeFactory = storeFactory
    this.ghostQueue = ghostQueue
    this.formStyler = formStyler
    this.iframeBuilder = iframeBuilder
    this.domReader = domReader
    this.appendClone = () => {}
    this.resetRender()

    this.mimic = new Mimic($store)
    this.smartFormRenderer = new SmartFormRenderer($bus, $store)

    // Start subscriptions
    this.$bus.$on(Events.krypton.destroy, () => this.resetRender())
  }

  /**
   * Main render method
   */
  async render() {
    this.setMerchantResourceFlag()
    // Form already rendered or no form
    const {
      isFormPopin,
      isSmartForm,
      getFormElement,
      hasSmartElements,
      isFormRendered
    } = this.$store.getters

    const formElement = getFormElement()
    const smartElements = hasSmartElements()

    if (formElement) {
      formElement.style.removeProperty('display')
    }

    // If it's a smartform with embedded form inside
    // Fallback formMode to default
    if (smartElements) {
      this.initializeSmartFormWallet()
    }

    // If during the dom collection or now there was no DOM elements, skip the render
    if (!this.domReader.hasDomElements || (!formElement && !smartElements))
      return Promise.resolve(false)
    // If there has been a previous render and the form is actually rendered, check only for clone
    if (this.rendered && isFormRendered()) {
      // If form has already been rendered, check if clone should be rendered
      if (this.rendered && this.shouldRenderClone()) {
        this.appendClone()
      }
      return Promise.resolve(!!formElement)
    }
    // In case it's smart form, start the render
    if (smartElements) {
      const smfRendered = this.smartFormRenderer.render()
      // If it's not been rendered, skip the rest (not supported)
      if (!smfRendered) return Promise.resolve(false)
    }
    // Form Render - Call renderPopin|renderEmbedded
    const rendered = await this[`render${isFormPopin ? 'Popin' : 'Embedded'}`](
      isSmartForm
    )
    // Set the flag according to the render process result
    this.rendered = rendered
    return Promise.resolve(rendered)
  }

  /**
   * Use a function to mock it properly in tests
   *
   * @returns {boolean}
   * @since KJS-4435
   */
  isRendered() {
    return this.rendered
  }

  initializeSmartFormWallet($smartForm = null) {
    const { formMode } = this.$store.state
    const { getFormElement, getSmartFormElement } = this.$store.getters
    $smartForm = $smartForm || getSmartFormElement()

    if ($smartForm && getFormElement($smartForm)) {
      if (formMode === 'wallet') {
        this.$store.dispatch('addFormPreset', {
          action: 'update',
          params: { walletMode: 'newCard' }
        })
      }
    }
  }

  /**
   * Renders the embedded form
   */
  async renderEmbedded(isSmartForm) {
    const element = await this.renderForm()
    // Append modal + layer
    if (!isSmartForm)
      element.append(`
        <simple-modal></simple-modal>
        <krypton-layer mode="unified"></krypton-layer>
      `)
    await this.startRender(element)
    if (this.$store.getters.isSmartForm) this.showForm()
    return Promise.resolve(true)
  }

  /**
   * Renders the popin form
   */
  async renderPopin(isSmartForm) {
    const popinId = uniqueId('kr-popin-')
    const hasWrapper = this.$store.state.form.wrapper
    const element = await this.renderForm()
    // Add a wrapper for the fields
    if (hasWrapper) {
      const content = element.html()
      element.empty()
      element.append(
        `<div class="kr-inner-popin-wrapper" kr-resource>${content}</div>`
      )
    }
    element.prepend(
      '<krypton-popin-header-wrapper></krypton-popin-header-wrapper>'
    )
    element.append('<krypton-popin-footer></krypton-popin-footer>')
    // Append modal + layer
    if (!isSmartForm)
      element.append(`
        <simple-modal></simple-modal>
        <krypton-layer></krypton-layer>
      `)
    element.before(`<div class="kr-popin-wrapper"></div>`)
    element.before(`
      <div class="kr-popin-utils" kr-popin-utils-id="${popinId}">
          <krypton-popin-background></krypton-popin-background>
          <krypton-popin-button></krypton-popin-button>
      </div>
    `)
    element.appendTo('.kr-popin-wrapper')
    await this.startRender(element)

    this.$bus.$emit(Events.krypton.data.newPopin, { popinId })
    this.showForm()
    return Promise.resolve(true)
  }

  async renderForm() {
    const promisedFields = []
    let $form
    const { getSmartFormElement, isSmartForm } = this.$store.getters

    // If used renderElements with selectors we need to ensure kr-element attribute is added to kr-embedded even inside $smartform
    const $smartform = getSmartFormElement()
    if (this.$store.state.dom.onlyTaggedElements && $smartform) {
      // Form exists already outside of smartform
      $form = this.$store.getters.getFormElement()
      if (!$form) {
        $form = $smartform.querySelector('.kr-embedded')
        $form.setAttribute('kr-element', '')
      }
    } else {
      $form = this.$store.getters.getFormElement()
    }

    $form.setAttribute('is', 'krypton-card-form')
    const $wrapper = Zepto($form)

    // Inject Base CSS
    injectIntoHead(`${this.formStyler.getBaseStyles()}`)

    // Wrapper attributes and classes
    this.addWrapperAttributes($wrapper)

    // Fill the missing elements
    fillRequiredElements($form)

    // Get fields config from dna
    let fields = this.$store.state.dna.cards.DEFAULT.fields

    // Unlink from the dna config
    fields = JSON.parse(JSON.stringify(fields))
    // Add the field name in the field conf
    for (const fieldName in fields) {
      fields[fieldName].fieldName = fieldName
    }
    fields = Object.values(fields)

    // Add iframes field representations to the dom (styles)
    this.setupFieldRepresentations(fields, $wrapper)

    // Generate fields
    for (const { fieldName } of fields) {
      promisedFields.push(
        this.locateAndCreateEmbeddedField(fieldName, $wrapper)
      )
    }

    // Generate payment button and form error
    const $paymentButton = $form.querySelector('.kr-payment-button')
    let formHasDiscountPanel = false

    // Get the current label
    if ($paymentButton) {
      const label = innerText($paymentButton).replace(/\n/g, '')
      if (label.length) {
        this.$store.dispatch('update', { button: { label } })
        Zepto($paymentButton).empty()
      }
      $paymentButton.setAttribute('is', 'krypton-payment-button')

      Zepto($paymentButton).after(
        '<krypton-currency-conversion></krypton-currency-conversion>'
      )

      // Add Discount Panel content receiver
      let $discountPanel = $form.querySelector('.kr-discount-panel')
      if ($discountPanel) {
        // Inside form, custom position
        const discountPaneloverride = document.createElement(
          'krypton-discount-panel'
        )
        discountPaneloverride.setAttribute('attach', '')
        $discountPanel.classList.remove('kr-discount-panel')
        $discountPanel.classList.add('kr-discount-panel--container')
        $discountPanel.appendChild(discountPaneloverride)
        formHasDiscountPanel = true
      } else {
        // Outside form
        $discountPanel = document.querySelector('.kr-discount-panel')

        // Inside form, default position
        if (!$discountPanel) {
          $discountPanel = document.createElement('krypton-discount-panel')
          $discountPanel.setAttribute('attach', '')
          $discountPanel.setAttribute('default', '')
          $paymentButton.parentNode.insertBefore($discountPanel, $paymentButton)
          formHasDiscountPanel = true
        }
      }
    }

    // Detect Split Payment Panel external wrapper
    let $splitPaymentPanel = document.querySelector('.kr-payment-schedule')
    if ($splitPaymentPanel) {
      this.$store.dispatch('detachSplitPayment')
    }

    // Error label
    const $formError = $form.querySelector('.kr-form-error')
    if ($formError) {
      Zepto($formError).empty()
      Zepto($formError).removeClass('kr-form-error')
      $formError.setAttribute('is', 'krypton-form-error')
      $formError.setAttribute('methods', 'CARDS')
    }

    // Generate brand buttons
    let $brandButton = document.querySelector('.kr-brand-buttons')
    if ($brandButton?.getAttribute('kr-brands')) {
      const showLabels = $brandButton.getAttribute('kr-show-labels')
      if (!showLabels || (showLabels != 'true' && showLabels != 'false')) {
        $brandButton.setAttribute('kr-show-labels', 'true')
      }
      $brandButton.setAttribute('is', 'krypton-brand-buttons')
    }

    // Resolve the promises
    await Promise.all(promisedFields)

    // Add custom fields inside a Vue wrapper
    renderCustomFields($wrapper)

    if (!isSmartForm) {
      // Wallet
      $wrapper.prepend(`
        <krypton-wallet-tabs></krypton-wallet-tabs>
        <krypton-wallet-card-list></krypton-wallet-card-list>
        `)
    }

    // SmartForm
    const { outsideCardsForm, isFormPopin } = this.$store.getters
    const { smartForm } = this.$store.state
    if (outsideCardsForm && !isFormPopin && smartForm.displayOptions.cardHeader)
      $wrapper.prepend(`<smart-form-card-header></smart-form-card-header>`)

    // Append control elements for the App (Vue)
    $wrapper.prepend(`
      <krypton-tab-handler position="first"></krypton-tab-handler>
    `)

    if (!formHasDiscountPanel) {
      $wrapper.append(
        `<krypton-discount-panel attach=".kr-discount-panel:not(.kr-discount-panel--insider)"></krypton-discount-panel>`
      )
    }

    // modal
    $wrapper.append(`
      <krypton-tab-handler position="last"></krypton-tab-handler>
    `)

    /**
     * Clone is required with SmartForm when the card form is expanded AND
     * at least one of the following condition is fulfilled:
     * - A CARDS SmartButton exists
     * - DNA contains some Wallet Cards
     */
    if (isSmartForm) {
      const append = this.requiresClone()
      await this.createClone($form, fields, append)
    }

    return Promise.resolve($wrapper)
  }

  requiresClone() {
    const {
      isSmartForm,
      cardsFormExpanded,
      hasSmartButton,
      hasCardTokens,
      isWallet,
      isFormPopin
    } = this.$store.getters
    return (
      isSmartForm &&
      (isFormPopin || cardsFormExpanded) &&
      (hasSmartButton('CARDS') || (hasCardTokens && isWallet))
    )
  }

  shouldRenderClone() {
    return !this.cloneRendered && this.requiresClone()
  }

  async createClone(form, fields, append = true) {
    const { dna } = this.$store.state
    const promisedFields = []
    const clone = form.cloneNode(false)
    const $clone = Zepto(clone)
    const formId = 'C' + form.getAttribute('kr-form')
    const store = this.storeFactory.create('actionNewForm', { formId, dna })

    clone.style.removeProperty('display')
    clone.removeAttribute('kr-popin')
    clone.setAttribute('kr-type', 'embedded')
    clone.setAttribute('kr-form', formId)

    fillRequiredElements($clone[0])
    this.setupFieldRepresentations(fields, $clone)

    const $formError = clone.querySelector('.kr-form-error')
    if ($formError) {
      Zepto($formError).empty()
      Zepto($formError).removeClass('kr-form-error')
      $formError.setAttribute('is', 'krypton-form-error')
    }

    const $paymentButton = clone.querySelector('.kr-payment-button')
    if ($paymentButton)
      $paymentButton.setAttribute('is', 'krypton-payment-button')

    for (const { fieldName } of fields) {
      promisedFields.push(this.locateAndCreateEmbeddedField(fieldName, $clone))
    }

    await Promise.all(promisedFields)

    const slot = document.querySelector('template[v-slot="extra"]')
    if (!slot)
      return Promise.reject(
        new Error('Form HTML template not found during the rendering')
      )
    slot.appendChild(clone)

    // Should be called only once the clone form is required
    // Called immediately if already has to be rendered
    this.appendClone = () => {
      this.$store.dispatch('renderCardForm', formId)
      this.ghostQueue.send(store)
      this.$bus.$emit(Events.krypton.form.new, { formId })
      this.$store.dispatch('addForm', {
        label: 'clone',
        id: formId
      })
      this.$store.dispatch('registerCardFormModule', { id: formId })

      this.appendClone = () => {}
      this.cloneRendered = true
    }

    if (append) {
      this.appendClone()
    }
  }

  /**
   * Start the rendering
   */
  startRender(element) {
    const { baseAddress } = this.$store.state
    const { getSmartFormElement, getFormElement } = this.$store.getters

    // If a smartform contains the given form element, take it as a root element
    // else if it contains another form, run loader Form again
    // else if no card form is found, run loader SmartForm instead.
    // Finally run loader Form at least once in any case.
    const $smartForm = getSmartFormElement()
    if ($smartForm) {
      if (!getFormElement($smartForm)) {
        this.$bus.$emit(Events.krypton.smartform.ready, {
          element: $smartForm
        })
      } else {
        this.initializeSmartFormWallet($smartForm)

        if ($smartForm.contains(element[0])) {
          element = Zepto($smartForm)
        } else {
          this.$bus.$emit(Events.krypton.form.ready, {
            element: Zepto($smartForm),
            baseAddress
          })
        }
      }
    }
    this.$bus.$emit(Events.krypton.form.ready, { element, baseAddress })

    // Brand buttons
    const $brandButtons = Zepto('.kr-brand-buttons')
    // If it's defined but outside the kr-embedded, use a new vue app
    if ($brandButtons.size() && !$brandButtons.closest('.kr-embedded').size()) {
      const brandButtonsId = uniqueId('kr-brand-buttons-')
      const showLabels = $brandButtons.attr('kr-show-labels')
        ? $brandButtons.attr('kr-show-labels') == 'true'
        : true
      $brandButtons.before(`
        <div class="kr-brand-buttons-wrapper" kr-brand-buttons-id="${brandButtonsId}">
            <krypton-brand-buttons
                kr-brands="${$brandButtons.attr('kr-brands')}"
                kr-show-labels="${showLabels}"></krypton-brand-buttons>
        </div>
      `)
      $brandButtons.remove()
      this.$bus.$emit(Events.krypton.data.newBrandButtons, { brandButtonsId })
    }
    if ($brandButtons.size()) $brandButtons.removeClass('kr-brand-buttons')
    return Promise.resolve()
  }

  /**
   * Returns the template to render the component
   */
  getTemplate(formId, fieldName) {
    const { translate } = this.$store.getters
    let html

    if (~fieldTypes.iframe.indexOf(fieldName)) {
      return new Promise((resolve, reject) => {
        // Wait until the page is fully loaded
        const loadSlavesCheckInterval = setInterval(() => {
          if (document.readyState === 'complete') {
            clearInterval(loadSlavesCheckInterval)

            // Render an iframe for the text field
            this.iframeBuilder
              .createEmbeddedHTML(
                formId,
                fieldName,
                // Read the field styles representation
                ...this.calculateFieldRepresentationStyles(fieldName)
              )
              .then(resolve)
              .catch(reject)
          }
        }, 20)
      })
    } else if (fieldName === 'identityDocumentType') {
      html = `<krypton-identity-doc-type kr-resource></krypton-identity-doc-type>`
    } else if (fieldName === 'identityDocumentNumber') {
      html = `<krypton-identity-doc-number kr-resource></krypton-identity-doc-number>`
    } else if (fieldName === 'cardHolderName') {
      html = `<krypton-card-holder-name kr-resource></krypton-card-holder-name>`
    } else if (fieldName === 'cardHolderMail') {
      html = `<krypton-card-holder-mail kr-resource></krypton-card-holder-mail>`
    } else if (fieldName === 'installmentNumber') {
      html = `<krypton-installments kr-resource></krypton-installments>`
    } else if (fieldName === 'firstInstallmentDelay') {
      html = `<krypton-first-installment-delay kr-resource></krypton-first-installment-delay>`
    } else if (fieldName === 'doRegister') {
      const label = translate('ask_register_pay')
      html = `<krypton-do-register kr-resource label="${label}"></kr-do-register>`
    }

    return Promise.resolve(html)
  }

  /**
   * Generates the field template and adds it to the dom
   */
  async locateAndCreateEmbeddedField(fieldName, $wrapper) {
    const formId = $wrapper[0].getAttribute('kr-form')
    const html = await this.getTemplate(formId, fieldName)
    const $el = $wrapper.find(fieldNameAsSelector(fieldName))
    // If element is already defined, change it by vue component
    if ($el.length) {
      const component = fieldNameAsComponent(fieldName)
      $el.attr({
        'kr-resource': '',
        'field-name': fieldName,
        is: component
      })
      $el.html(html)
      return Promise.resolve()
    }
    // If there is control fields, append it field just before
    for (let i = 0; i < $wrapper[0].childNodes.length; i++) {
      const son = $wrapper[0].childNodes[i]
      const controls = ['krypton-form-error', 'krypton-payment-button']
      if (son.getAttribute && ~controls.indexOf(son.getAttribute('is'))) {
        Zepto(son).before(html)
        return Promise.resolve()
      }
    }
    // Else, append field at the end
    $wrapper.append(html)
    return Promise.resolve()
  }

  /**
   * Adds the proper attributes to the wrapper element
   */
  addWrapperAttributes($wrapper) {
    const { isIos } = this.$store.getters
    const { forms, postUrlSuccess, zIndex } = this.$store.state
    // Add form id to wrapper
    $wrapper.attr('kr-form', forms.main)
    // Add the kr-post-url-success to the wrapper
    $wrapper.attr('kr-post-url-success', postUrlSuccess)

    // Add the z-index style to the wrapper
    if (zIndex) $wrapper.css('z-index', `${parseInt(zIndex)}`)

    // Browser and OS as class
    let classesToAdd = []
    const { browser, os } = this.$store.state
    const nameClass = `kr-${spacedToKebabCase(browser.name)}`
    const osnameClass = `kr-${spacedToKebabCase(os.name)}`
    if (browser.name) classesToAdd.push(nameClass)
    if (os.name) classesToAdd.push(osnameClass)
    if (isIos) classesToAdd.push(`${osnameClass}-${os.version.split('.')[0]}`)
    // Help button format
    classesToAdd.push(`kr-help-button-inner-field`)

    $wrapper.addClass(classesToAdd.join(' '))
  }

  /**
   * Calculate the field styles from the host representations
   */
  calculateFieldRepresentationStyles(fieldName) {
    const className = fieldNameAsClassName(fieldName)
    const sizeResults = this.mimic.calculateSize(className)
    const fieldCss = this.mimic.calculateFormCSS(className, [])

    // Set the styles in the store
    this.$store.dispatch('update', { css: object([fieldName], [fieldCss]) })

    return [sizeResults, fieldCss.inputDefault['font-family']]
  }

  /**
   * Generates the iframe field representations
   *
   * @see KJS-2030  Add aria-hidden=true
   */
  setupFieldRepresentations(fields, $wrapper) {
    let fieldRepresentation = ''

    for (const fieldConf of fields) {
      fieldRepresentation += `
            <div class="kr-outer-wrapper kr-outer-${fieldNameAsClassName(
              fieldConf.fieldName
            )}">${this.getInnerWrapperTemplate()}</div>`
    }

    $wrapper.append(`
      <div kr-resource id="krFieldRepresentation" aria-hidden="true">${fieldRepresentation}</div>
      <div kr-resource id="krFieldRepresentationError" aria-hidden="true" class="kr-outer-wrapper">
        ${this.getInnerWrapperTemplate('kr-on-error')}
      </div>
      <div kr-resource id="krFieldRepresentationDisabled" aria-hidden="true" class="kr-outer-wrapper">
        ${this.getInnerWrapperTemplate('kr-disabled')}
      </div>`)
  }

  getInnerWrapperTemplate(className = '') {
    return `
      <div class="kr-inner-wrapper ${className}">
        <div class="kr-field-container">
            <div class="kr-field-relative-wrapper">
                <div class="kr-input-relative-wrapper">
                    <input class="kr-input-field ${className}" type="hidden"/>
                </div>
            </div>
        </div>
      </div>`
  }

  showForm() {
    // Unset inline style display: none
    Zepto(this.$store.getters.getFormElement()).css('display', null)
  }

  resetRender() {
    this.rendered = false
    this.cloneRendered = false
  }

  setMerchantResourceFlag() {
    const { getSmartFormElement, getFormElement } = this.$store.getters
    const { onlyTaggedElements } = this.$store.state.dom

    // set kr-merchant-resource attr if embedded has been added by the merchant
    const $smartform = getSmartFormElement()

    let $embedded
    if ($smartform) {
      // inside smartform kr-embedded may not exist yet, exist with kr-element or exist without it
      $embedded =
        $smartform.querySelector('.kr-embedded[kr-element]') ??
        $smartform.querySelector('.kr-embedded')
    } else {
      // without smartform if onlyTaggedElements is set, must have kr-element attr
      $embedded = document.querySelector(
        onlyTaggedElements ? '.kr-embedded[kr-element]' : '.kr-embedded'
      )
    }

    if ($embedded) $embedded.setAttribute('kr-merchant-resource', '')
  }
}
