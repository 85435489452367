import _ from 'underscore'
import { mapState } from 'vuex'
import Events from '@/configuration/Events'

export default {
  data() {
    return {
      value: ''
    }
  },
  computed: {
    ...mapState('field', [
      'formId',
      'name',
      'commEstablished',
      'awareId',
      'ready'
    ]),
    ...mapState(['formToken'])
  },
  watch: {
    formToken(newVal) {
      if (!_.isString(newVal)) return
      this.$store.dispatch('field/update', {
        formTokenPrefix: newVal.substring(0, 2)
      })
    }
  },
  created() {
    this.startEventSubscriptions()
    this.$bus.$on(Events.slave.configuration.parsed, this.init)
  },
  methods: {
    init(configuration) {
      const interfaceMap = {
        ECHO: 'echo',
        FOCUS: 'focus',
        GET_VALUE: 'getValue',
        ERROR: 'getError',
        VALID: 'validate',
        SYNC: 'sync',
        SET_AUTOFILL_VALUE: 'setAutofillValue'
      }

      for (const key in interfaceMap) {
        window[key] = data => this[interfaceMap[key]](data)
      }

      window.FIELD = configuration.name
      window.FORM_ID = configuration.formId
      this.$store.dispatch('field/update', {
        formTokenPrefix: configuration.formTokenPrefix
      })
    },
    startEventSubscriptions() {
      this.$bus.$on(Events.slave.field.change, ({ cleanValue }) => {
        this.value = cleanValue

        this.$bus.$emit('proxy.send', {
          _name: 'fieldLength',
          formId: this.formId,
          name: this.name,
          length: `${cleanValue}`.length
        })
      })
    },

    echo(store) {
      if (
        store.formId === this.formId &&
        store.awareId === this.awareId &&
        store.fieldName === this.name &&
        !this.commEstablished
      ) {
        this.$store.dispatch('field/communicationEstablished')
      }
    },

    focus() {
      this.$bus.$emit(Events.slave.field.focus.forced)
    },

    getValue() {
      return this.value
    },

    getError() {
      return this.error
    },

    validate(silent = false) {
      // Get from child component validation result
      if (silent) this.hasError = !this.isViable
      return this.isViable
    },

    sync(store) {
      this.$bus.$emit(Events.krypton.message.sync, {
        data: store.payload,
        origin: store.origin,
        metadata: {
          namespace: store.namespace
        }
      })
    },

    setAutofillValue({ fieldName, value }) {
      this.$store.dispatch('field/autofill', { [fieldName]: value })
    }
  }
}
