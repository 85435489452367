<template lang="pug">
  .kr-spinner
    .kr-icon
      svg(width="24px" height="24px" viewBox="0 0 64 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink")
        circle.kr-border(cx="32" cy="32" r="30" stroke-linecap="round" stroke-width="4" stroke="#000" fill="none")
        path.kr-checkmark(
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M23.375 31.5488281 28.8840688 38.0578969 42.891932 25.0500338"
          stroke-width="3"
          stroke="#000"
          fill="none"
        )
</template>

<script>
/**
 * Theme material.
 * Moved from theme JS to make it a component.
 *
 * @since KJS-4716
 */
export default {
  name: 'KryptonAnimationSpinnerCircle'
}
</script>
