import { render, staticRenderFns } from "./ButtonLabel.vue?vue&type=template&id=5eaea7e8&lang=pug&"
import script from "./ButtonLabel.vue?vue&type=script&lang=js&"
export * from "./ButtonLabel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/VAD_KJS_build_hotfix/proxy-purebilling/krypton-js-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5eaea7e8')) {
      api.createRecord('5eaea7e8', component.options)
    } else {
      api.reload('5eaea7e8', component.options)
    }
    module.hot.accept("./ButtonLabel.vue?vue&type=template&id=5eaea7e8&lang=pug&", function () {
      api.rerender('5eaea7e8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/host/components/smartform/splitPayment/ButtonLabel.vue"
export default component.exports