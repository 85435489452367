<template lang="pug">
.kr-smart-form-radio-button(:class="dynamicClasses")
  span
    svg(width="12px", height="9px", viewbox="0 0 12 9")
      polyline(points="1 5 4 8 11 1")
</template>

<script>
import { RadioButtonMixin } from '@/host/components/mixins/smartform/RadioButton'

/**
 * This component is a visual checkbox added for Single Payment Button mode.
 * As of now it used in method labels and card form header.
 *
 * @since KJS-2662
 */
export default {
  name: 'SmartFormRadioButton',
  mixins: [RadioButtonMixin],
  computed: {
    dynamicClasses() {
      return {
        'kr-smart-form-radio-button--checked': this.isChecked,
        'kr-smart-form-radio-button--hover': this.isHovered
      }
    }
  }
}
</script>
