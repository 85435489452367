<template lang="pug">
  header.kr-sf-header(v-if="renderGenericEmbeddedHeader || hasCardHeader")
    SplitPaymentCardLabel.kr-sf-header__label(v-if="shouldRenderSPCardHeader")
    SmartFormEmbeddedHeader(v-if="renderGenericEmbeddedHeader")
    SmartFormCardHeader(v-else-if="hasCardHeader")
</template>

<script>
import { mapGetters } from 'vuex'

import SmartFormCardHeader from '@/host/components/smartform/CardHeader'
import SmartFormEmbeddedHeader from '@/host/components/smartform/EmbeddedHeader'
import SplitPaymentCardLabel from '@/host/components/smartform/splitPayment/CardLabel'

export default {
  name: 'SmartFormHeader',
  components: {
    SmartFormCardHeader,
    SmartFormEmbeddedHeader,
    SplitPaymentCardLabel
  },
  computed: {
    ...mapGetters([
      'renderGenericEmbeddedHeader',
      'hasCardHeader',
      'shouldRenderSPCardHeader'
    ])
  }
}
</script>
