<template lang="pug">
  .kr-sp-timeline
    section.kr-sp-first-payment
      .kr-sp-first-payment-content
        SplitPaymentCircleGraph(width="48", height="48", :percentage="getPercentage(0)")
        .kr-sp-first-payment-text
          span.kr-sp-first-payment-amount {{ formatAmount(firstPayment.amount) }}
          span.kr-sp-first-payment-date(v-html="firstPaymentLabel")
    section.kr-sp-schedule(:class="dynamicClass.schedule")
      template(v-for="(p, idx) in nextPayments")
        VerticalDashedLine.kr-sp-schedule-dashed-line(v-if="idx > 0 || nextPayments.length === 1")
        .kr-sp-schedule-row
          SplitPaymentCircleGraph(width="24", height="24", :percentage="getPercentage(idx + 1)")
          .kr-sp-schedule-payment-text
            span.kr-sp-schedule-payment-amount {{ formatAmount(p.amount) }}
            span.kr-sp-schedule-payment-date(v-html="formatDateLabel(p.date)")
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import SplitPaymentCircleGraph from '@/host/components/smartform/splitPayment/CircleGraph'
import VerticalDashedLine from '@/host/components/smartform/splitPayment/VerticalDashLine'

import { InstallmentSchedule } from '@/common/model/'

const TRANSLATIONS = {
  TODAY: 'split_payment_date_today',
  TOMORROW: 'split_payment_date_tomorrow',
  SHORT_TERM_DATE: 'split_payment_date_number_of_days',
  LONG_TERM_DATE: 'split_payment_date_on'
}

/**
 * Display amount to pay now then up to 3 future payment with their
 * associated amount and date.
 *
 * E.g: Given the following pug template
 *  SplitPaymentTimeline(:schedule="schedule")
 *
 * The variable "schedule" is expected to be an array so that:
 * - the first element is an object containing only amount<number>
 * - every subsequent element is an object containing
 *   amount<numbre> & date<Date>
 *
 * [
 *   { date: new Date('2024-12-01T00:00:00Z'), amount: 8000 },
 *   { date: new Date('2025-01-01T00:00:00Z'), amount: 8100 },
 *   { date: new Date('2025-02-01T00:00:00Z'), amount: 8100 },
 *   { date: new Date('2025-03-01T00:00:00Z'), amount: 8100 }
 * ]
 *
 * @since KJS-4362
 */
export default {
  name: 'SplitPaymentTimeline',
  components: {
    SplitPaymentCircleGraph,
    VerticalDashedLine
  },
  props: {
    schedule: {
      type: InstallmentSchedule,
      required: true
    }
  },
  computed: {
    ...mapState(['amount', 'language']),
    ...mapState({
      percentage: state => state.smartForm.splitPayment.percentage
    }),
    ...mapState({
      timezone: state => state._internals.timezone
    }),
    ...mapGetters(['getAmountLabel', 'translate']),
    dynamicClass() {
      return {
        schedule: {
          [`kr-sp-schedule--${this.nextPayments.length}`]: true
        }
      }
    },
    numberPayments() {
      return this.schedule.length
    },
    firstPayment() {
      return this.schedule.firstInstallment()
    },
    firstPaymentLabel() {
      this.timezone && void 0 // force value computation if timezone change

      const { date: firstPaymentDate } = this.firstPayment
      const daysDifference = this.getDaysDifference(firstPaymentDate)

      if (daysDifference === 0) return this.todayLabel
      if (daysDifference === 1) return this.tomorrowLabel
      if (daysDifference < 30) {
        return this.formatShortTermDateLabel(firstPaymentDate, daysDifference)
      }

      return this.formatDateLabel(firstPaymentDate)
    },
    nextPayments() {
      return this.schedule.subsequentInstallments()
    },
    todayLabel() {
      return this.translate(TRANSLATIONS.TODAY)
    },
    tomorrowLabel() {
      return this.translate(TRANSLATIONS.TOMORROW)
    }
  },
  methods: {
    getDaysDifference(date) {
      const today = this.formatDate(new Date(), 'en-CA')
      const targetDate = this.formatDate(new Date(date), 'en-CA')
      const diffTime = new Date(targetDate) - new Date(today) // Difference in milliseconds
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24)) // Convert to days
    },
    /**
     * For a given index in the installment list, return the percentage paid.
     *
     * Given percentage == 'amount' (default):
     *  For 10EUR, 40EUR & 50EUR out of 100EUR: [10, 40, 50]
     *   A 5% lower threshold is applied for visual reason.
     *
     * Given percentage == 'time':
     *  For 4 installments : [25, 50, 75, 100]
     *  For 3 installments : [1/3, 2/3, 100]
     *  For 2 installments : [50, 100]
     *
     * @param idx Index within the installment list
     * @see KJS-4546 Calculation by default is now a percentage of cumulated
     *               paid amount instead of time (optional)
     */
    getPercentage(idx) {
      if (this.percentage === 'time') {
        return (100 / this.numberPayments) * (idx + 1)
      }
      let cumulatedAmount = 0
      for (let i = 0; i <= idx; ++i) {
        cumulatedAmount += this.schedule.at(i).amount
      }

      return Math.max((cumulatedAmount * 100) / this.amount, 5)
    },
    formatAmount(amount) {
      return this.getAmountLabel(amount)
    },
    formatDate(date, languageOverride) {
      const options = {}
      if (this.timezone) {
        options.timeZone = this.timezone
      }
      return date.toLocaleDateString(languageOverride || this.language, options)
    },
    formatDateWithTranslation(translationKey, placeholders = {}) {
      let translation = this.translate(translationKey)

      Object.entries(placeholders).forEach(([key, value]) => {
        translation = translation
          .replace(`[${key}]`, value)
          .replace(/(\s)(?![^<]*>)/g, '&nbsp;') // Replace spaces with &nbsp; only outside of HTML tags
      })

      return translation
    },
    formatShortTermDateLabel(date, daysDifference) {
      const formattedDate = `<span class="kr-sp-first-payment-auxiliary-date">(${this.formatDate(
        date
      )})</span>`

      return this.formatDateWithTranslation(TRANSLATIONS.SHORT_TERM_DATE, {
        COUNT: daysDifference,
        DATE: formattedDate
      })
    },
    formatDateLabel(date) {
      return this.formatDateWithTranslation(TRANSLATIONS.LONG_TERM_DATE, {
        DATE: `<b>${this.formatDate(date)}</b>`
      })
    }
  }
}
</script>
