/**
 * Extracted from FiltersMixin.
 *
 * By default return `${text}:`
 * For fr language: `${text} :`
 * We like good grammar.
 *
 * @param {string} text
 * @param {string} locale
 * @returns {string}
 * @since KJS-4368
 */
export const colon = (text, locale = navigator.language) => {
  if (!text) return ''
  text = text.toString()
  const colonChar = locale.startsWith('fr-') ? ' :' : ':'
  return text + colonChar
}
