<template lang="pug">
  .kr-sf-modal-wrapper(v-show="open")
    SmartFormGridModal(
      :open="open"
      v-on:close="closeModal"
      v-on:selected="openMethod"
    )
      template(v-slot:cardForm="")
        slot(name="cardForm")
      template(v-slot:extra="")
        slot(name="extra")
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import SmartFormGridModal from '@/host/components/smartform/grid/modal/Modal'

export default {
  name: 'SmartFormGridModalWrapper',
  components: {
    SmartFormGridModal
  },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['abortedErrorCode', 'hasDeadEndPaymentMethod']),
    ...mapState([
      'dccReceiptOpen',
      'redirectTransaction',
      'redirectIframeOpen',
      'disabledForm'
    ]),
    ...mapState({
      activeMethod: state => state.smartForm.activeMethod
    })
  },
  methods: {
    ...mapActions([
      'error',
      'closeSmartFormPopin',
      'finishRedirection',
      'closePopin',
      'forceFieldClear'
    ]),
    closeModal() {
      if (this.dccReceiptOpen) {
        this.$proxy.send(this.$storeFactory.create('closeReceipt', {}))
        return
      }
      if (this.activeMethod && !this.redirectIframeOpen) {
        this.error(this.abortedErrorCode)
        this.closeSmartFormPopin()
      } else {
        if (this.redirectIframeOpen) {
          if (
            !this.hasDeadEndPaymentMethod() &&
            !this.redirectTransaction &&
            !this.disabledForm
          )
            this.error(this.abortedErrorCode)

          this.finishRedirection(true)
        }
        this.closePopin()

        const $ghost = this.$locator.iframeController.ghostContainer
        $ghost.hide()

        // Report by event the closing action of the popin
        this.forceFieldClear()
      }
    },
    openMethod(method) {
      this.$emit('selected', method)
    }
  }
}
</script>
