<template lang="pug">
  .kr-spinner
    i.kr-loader
</template>

<script>
/**
 * Theme neon.
 * Moved from theme JS to make it a component.
 *
 * @since KJS-4716
 */
export default {
  name: 'KryptonAnimationSpinningSquares'
}
</script>
