<template lang="pug">
  .kr-loading-animation(:class="dynamicClasses.wrapper")
    i.kr-loader(:class="dynamicClasses.loader")
</template>

<script>
import Events from '@/configuration/Events'

/**
 * Unify smartform's spinner includes with a single component.
 *
 * It reacts to the following events:
 *  - krypton.smartform.spinner.freeze
 *  - krypton.smartform.spinner.unfreeze
 *
 * This allow to stop the CSS animation for e2e visual tests.
 *
 * @since KJS-2895
 */
export default {
  name: 'KryptonLoadingAnimation',
  data() {
    return {
      frozen: false
    }
  },
  computed: {
    dynamicClasses() {
      return {
        wrapper: {
          'kr-loading-animation--frozen': this.frozen
        },
        loader: {
          'kr-loader--frozen': this.frozen
        }
      }
    }
  },
  created() {
    this.$busOn(Events.krypton.smartform.spinner.freeze, () => {
      this.frozen = true
    })
    this.$busOn(Events.krypton.smartform.spinner.unfreeze, () => {
      this.frozen = false
    })
  }
}
</script>
