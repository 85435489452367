import _ from 'underscore'
import Events from '@/configuration/Events'

import endpointMap from '@/configuration/sources/EndpointMap.yml'
import domainsWhitelist from '@/configuration/sources/DomainsWhitelist.yml'

/**
 * Class to handle the endpoint detection with the form token
 * and global context variables
 */
export default class EndpointDetector {
  /**
   * @public
   */
  constructor({ configuration, proxy, storeFactory, $bus, $store }) {
    this.configuration = configuration
    this.proxy = proxy
    this.storeFactory = storeFactory
    this.$store = $store
    this.$bus = $bus

    // Start subscriptions
    $bus.$on(Events.krypton.message.actionSetEndpoint, message => {
      if (message.endpoint) this.setForcedEndpoint(message.endpoint)
    })
  }

  detect(formToken) {
    const forcedEndpoint = this.$store.state.endpoint
    if (forcedEndpoint) return forcedEndpoint

    let endpoint = this.getEndpointRoot(formToken)

    if (endpoint) return `${endpoint.endpoint.api}/api-payment`
    return endpoint
  }

  getEndpointRoot(formToken) {
    const endpoints = endpointMap.items
    const prefix = formToken.substring(0, 2)
    return _.findWhere(endpoints, { prefix })
  }

  /**
   * Set the endpoint if it's allowed
   */
  setForcedEndpoint(endpointObj) {
    if (!_.isObject(endpointObj)) return null

    const endpointUrl = this.buildDomainName(endpointObj)

    if (endpointUrl && this.isLocalDomain(endpointUrl)) {
      this.$store.dispatch('update', { endpoint: endpointUrl })
    } else {
      this.$store.dispatch('error', 'CLIENT_996')
    }
  }

  /**
   * Build the domain url from the object if the format is ok
   */
  buildDomainName(endpointObj) {
    // http[a]://[b].[c].office.[d].lyra:[e]
    if (!_.isBoolean(endpointObj.a)) return null
    const a = endpointObj.a ? 's' : ''
    const b = endpointObj.b
    const c = endpointObj.c
    const d = endpointObj.d
    let e = endpointObj.e
    if (e && !_.isString(e) && !_.isBoolean(e)) e = e.toString()

    if (
      b &&
      _.isString(b) &&
      b.match(/^[\da-zA-Z-_\.]+$/g) &&
      c &&
      _.isString(c) &&
      c.match(/^([\da-zA-Z]{1,10})$/g) &&
      d &&
      _.isString(d) &&
      d.match(/^([\da-zA-Z]{2}|[\da-zA-Z]{4})$/g) &&
      e &&
      e.match(/^[\d]+$/g)
    ) {
      return `http${a}://${b}.${c}.office.${d}.lyra:${e}`
    }

    return null
  }

  /**
   * Checks if the endpoint is whitelisted
   */
  isWhitelistedEndpoint(endpointUrl) {
    // forcedEndpoint (allowed local domain)
    if (this.$store.state.endpoint) return true

    const endpointDomain =
      /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/g.exec(
        endpointUrl
      )[1]

    for (const domainRegex of domainsWhitelist.endpoint_white_list) {
      let dmRx = new RegExp(domainRegex)
      if (dmRx.test(endpointDomain)) return true
    }

    return false
  }

  /**
   * Checks if the domain name is "local" (*.lyra domain)
   *
   * @param {string} url
   */
  isLocalDomain(url) {
    let endpointDomain =
      /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^\/\n\?\=\(\)\{\}]+)/g.exec(
        url
      )[1]

    return !!(endpointDomain && /^.*\.lyra?(:\d{1,6})$/g.test(endpointDomain))
  }

  setEndpointDomain(formToken) {
    const tokenPrefix = formToken.substring(0, 2)
    const domain = endpointMap.items.find(
      ({ prefix }) => prefix === tokenPrefix
    ).endpoint.api

    this.$store.dispatch('update', { endpointDomain: domain })
  }

  validateEndpoint(endpoint) {
    if (!this.isWhitelistedEndpoint(endpoint)) {
      return Promise.reject('CLIENT_997')
    }
    return Promise.resolve()
  }
}
