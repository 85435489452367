import { has, clone, extend as shallowExtend } from 'underscore'
import extend from 'deep-extend'
import { updatedDiff } from 'deep-object-diff'
import CommonAttrs from '@/configuration/sources/domAttributes/Common.yml'
import FormAttrs from '@/configuration/sources/domAttributes/Form.yml'
import SmartFormAttrs from '@/configuration/sources/domAttributes/SmartForm.yml'
import paymentMethodsConf from '@/configuration/sources/smartform/paymentMethodsConf.yml'
import gridValidation from '@/configuration/sources/smartform/gridValidation.yml'
import PreloadedAssets from '@/configuration/PreloadedAssets'
import whiteLabelMap from '@/configuration/sources/WhiteLabelsConfig.yml'
import { getFieldsProperties } from '@/store/modules/form'
import { setValueToPath, isPlainObject } from '@/common/util/object'
import { camelCaseFormat } from '@/common/util/attribute'
import { castValue } from '@/common/util/values'
import { getConfigState } from '@/store/modules/config'
import { getCssVariables, addCssVariables } from '@/store/utils/config'

export default class ConfigurationHandler {
  formProps = ['placeholders', 'labels', 'contentLabels']
  cachedConfig = {}
  initialEventListenersSet = false

  constructor($locator) {
    this.$locator = $locator
    this.$bus = $locator.$bus
    this.$store = $locator.$store

    this.cachedProps = Object.keys(getConfigState())
  }

  // Called from blender boot on app initialization (or rerender)
  setupInitialConfig() {
    this._setupWhitelabelConfig()
    this._setupKRConfiguration()
    if (!this.initialEventListenersSet) {
      this.setupConfigurationEvents(this.$store.state.events)
      this.initialEventListenersSet = true
    }
    this.$store.dispatch('update', this.cachedConfig)
    this.$locator.$store.dispatch('parseCssConfig')
  }

  // Called from KR.setFormConfig calls
  setFormConfig(config) {
    // TODO: Refactor the filter to work also with dom attrs
    // this._filterAllowedConfiguration(config, this.$locator.$store.state)

    if (!config) return

    if (config.fields && config.fields.all) {
      const newConfig = config.fields

      // Give to all fields the new configuration
      const fieldList = Object.keys(getFieldsProperties(null, false))
      config.fields = fieldList.reduce((acc, x) => {
        acc[x] = JSON.parse(JSON.stringify(newConfig.all))
        return acc
      }, {})

      // Give to fields their special configuration
      delete newConfig.all
      for (let field in newConfig) {
        for (let type in newConfig[field]) {
          extend(config.fields[field][type], newConfig[field][type])
        }
      }
    }

    this._filterNotAllowedProps(config)
    this._filterInvalidGridConfig(config)

    // Update the store
    this._updateStore(config)
  }

  /**
   * -----------------------
   * NOT USED FOR THE MOMENT
   * -----------------------
   * The allowed config will have the following requirements
   * Property already exists on state
   * 'Object' properties cannot be override with a non object value
   *    ex. 'smartForm: "any string"' cannot be set
   */
  _filterAllowedConfiguration(config, stateSection) {
    Object.keys(config).forEach(key => {
      if (!stateSection.hasOwnProperty(key)) {
        //property not in state
        delete config[key]
        console.error(`Property ${key} does not exist`)
      } else {
        if (isPlainObject(stateSection[key])) {
          // property is a object with sub-properties
          if (!isPlainObject(config[key])) {
            delete config[key]
            console.error('You cannot set a Object property to any other type')
          } else {
            // look on a deeper level the same logic
            this.filterAllowedConfiguration(config[key], stateSection[key])
            if (Object.keys(config[key]).length === 0) {
              // remove empty object
              console.error(`No correct properties for ${key} found`)
              delete config[key]
            }
          }
        }
      }
    })
  }

  /**
   * Updates the store using the proper object path of
   * given attributes
   */
  _updateStore(updateObj) {
    let conf = {}
    for (const key in updateObj) {
      const [value, path] = this._getPath(key, updateObj[key])
      // setup the update conf object
      conf = setValueToPath(conf, path, value)
      // Props with values in form and root
      if (~this.formProps.indexOf(path.split('.')[0])) {
        conf = setValueToPath(conf, `form.${path}`, value)
      }
    }

    this.$store.dispatch('update', conf)
    this._saveStyleConfigCalls(conf)
  }

  /**
   * Used to:
   * - Cache style changes to reapply them on re-render
   * - Create/update css variables linked to the configuration
   */
  _saveStyleConfigCalls(conf) {
    Object.entries(conf)
      .filter(([key, _value]) => this.cachedProps.includes(key))
      .forEach(([key, value]) => {
        if (!this.cachedConfig[key]) this.cachedConfig[key] = {}
        extend(this.cachedConfig[key], value)

        const variables = {}
        getCssVariables(value, `--kr-${key}`, variables)
        addCssVariables(variables, this.$store.getters.getValueFromCssVar)
      })
  }

  /**
   * Gets the store path of a given key
   */
  _getPath(key, value) {
    const domAttrs = [...CommonAttrs, ...FormAttrs, ...SmartFormAttrs]

    for (const attrConf of domAttrs) {
      const attr = attrConf.attribute
      if (attr === key || camelCaseFormat(attr) === key) {
        return [
          castValue(this.$locator, value, attr, attrConf.casting),
          attrConf.name
        ]
      }
    }

    return [value, key]
  }

  _filterNotAllowedProps(config) {
    const { isMethodWhitelisted } = this.$locator.$store.getters
    // Icon label customization for disallowed methods
    if (config.smartForm?.paymentMethods) {
      const methods = Object.keys(config.smartForm?.paymentMethods)
      const children = []
      for (let i = 0; i < methods.length; i++) {
        const method = methods[i]
        if (
          !isMethodWhitelisted(method) ||
          paymentMethodsConf.notCustomizableMethods.includes(method)
        ) {
          children.push({
            errorCode: 'CLIENT_714',
            metadata: {
              property: `smartForm.paymentMethods.${method}`
            }
          })
          delete config.smartForm.paymentMethods[method]
        }
      }

      if (children.length > 0) {
        this.$locator.$store.dispatch('error', {
          errorCode: 'CLIENT_713',
          children,
          metadata: {
            console: true
          }
        })
      }
    }
  }

  _validGridProperty(propertyValue, type, possibleValues) {
    const configPropertyTypeValidators = {
      integer: v => Number.isInteger(v),
      integerOrMax: v => Number.isInteger(v) || v === 'max',
      option: (v, possibleValues) => possibleValues.includes(v)
    }
    const validator = configPropertyTypeValidators[type]
    if (!validator) return true
    return validator(propertyValue, possibleValues)
  }

  /**
   *
   * @returns the allowed values for the grid property type
   */
  _invalidGridPropertyMessage({ propertyName, type, possibleValues }) {
    const configPropertyTypeErrorMessages = {
      integer: () => 'an Integer',
      integerOrMax: () => `an Integer or 'max'`,
      /**
       * @param {string[]} possibleValues
       * @returns the possible values separated with ',' or 'or' for the last one
       */
      option: possibleValues => {
        let availableOptions = ''
        availableOptions += `'${possibleValues[0]}'`
        availableOptions = possibleValues
          .slice(1, -1)
          .reduce((acc, curr) => acc + `, '${curr}'`, availableOptions)
        if (possibleValues.length > 1) availableOptions += ' or '
        availableOptions += `'${possibleValues[possibleValues.length - 1]}'`
        return availableOptions
      }
    }
    const availableOptionsCalculator = configPropertyTypeErrorMessages[type]
    const availableOptions = availableOptionsCalculator
      ? availableOptionsCalculator(possibleValues)
      : ''
    return `The provided value for ${propertyName} is not valid it should be ${availableOptions}. The value has been ignored`
  }

  /**
   * Removes invalid grid configuration (including smartForm.layout)
   * @since KJS-4173
   */
  _filterInvalidGridConfig(config) {
    const rules = gridValidation.gridValidation
    rules.forEach(
      ({ property: propertyName, type, values: possibleValues }) => {
        const attributeNames = propertyName.split('.')
        const propertyValue = attributeNames.reduce(
          (acc, curr) => (acc ? acc[curr] : undefined),
          config
        )
        if (
          propertyValue != null &&
          !this._validGridProperty(propertyValue, type, possibleValues)
        ) {
          console.warn(
            this._invalidGridPropertyMessage({
              propertyName,
              type,
              possibleValues
            })
          )

          const lastAttributeName = attributeNames[attributeNames.length - 1]
          const lastConfigObject = attributeNames
            .slice(0, -1)
            .reduce((acc, curr) => acc[curr], config)
          delete lastConfigObject[lastAttributeName]
        }
      }
    )
  }

  _setupWhitelabelConfig() {
    const { formToken } = this.$store.state
    let prefix = formToken.substring(0, 2)

    // Fix: If the prefix starts with a zero, cut it
    if (prefix.length === 2 && prefix.indexOf('0') === 0) {
      prefix = prefix.substr(1)
    }

    let config = {
      form: {
        popin: {
          footer: {
            logo: {
              default: (whiteLabelMap[prefix] || whiteLabelMap.default).logo
            }
          }
        },
        smartform: {
          overlay: {
            logo: {
              file:
                (whiteLabelMap[prefix] || whiteLabelMap.default).logoLight ||
                null
            }
          }
        }
      }
    }

    // If a config parameter is not defined, use default
    const whitelabelConfig = Object.create(whiteLabelMap.default.config)
    if (whiteLabelMap[prefix]?.config) {
      shallowExtend(whitelabelConfig, whiteLabelMap[prefix].config)
    }
    for (const property in whitelabelConfig) {
      config = setValueToPath(config, property, whitelabelConfig[property])
    }

    this.$store.dispatch('update', config)
  }

  _setupKRConfiguration() {
    const { isFormPopin, isSmartForm } = this.$store.getters
    const isRegularPopin = isFormPopin && !isSmartForm
    if (has(window, 'KR_CONFIGURATION')) {
      // Get the unlinked data of object
      let config = clone(window.KR_CONFIGURATION)
      this.$locator.themeHandler.checkConflicts(config)

      // If there is a default config, reset the values
      if (this.$store.state.defaultConfig) {
        extend(config, this.$store.state.defaultConfig)
      }

      // Popin specific config - override the main props
      if (isRegularPopin && config.popin) {
        // First, save the default values overridden by specific popin config
        config.defaultConfig = updatedDiff(config.popin, config)
        extend(config, config.popin)
      }

      // Smartform specific config - override the main props
      if (isSmartForm && config.smartform) {
        // First, save the default values overridden by specific smartform config
        config.defaultConfig = updatedDiff(config.smartform, config)
        extend(config, config.smartform)
      }

      if (config.fields && config.fields.icons) {
        extend(PreloadedAssets, config.fields.icons)
      }

      // Extend the store default state with the data defined in KR_CONFIGURATION
      this.$store.dispatch('update', config)
    }
  }

  setupConfigurationEvents(eventsConfig) {
    for (const event in eventsConfig) {
      // Deduce it from the root evens
      const splittedKey = event.replace(/([A-Z])/g, ' $1').split(' ')
      // Remove the 'on' part
      splittedKey.shift()
      // Set the listener
      this.$bus.$on(
        `krypton.${splittedKey.join('.').toLowerCase()}`,
        message => {
          eventsConfig[event](message, Zepto)
        }
      )
    }
  }
}
