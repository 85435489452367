import _ from 'underscore'
import { mapState } from 'vuex'
import TestCards from '@/configuration/sources/TestCards.yml'
import ErrorsConf from '@/configuration/sources/ErrorsConfiguration.yml'

export const LuhnValidatorMixin = {
  computed: {
    ...mapState(['publicKey', 'currency'])
  },
  watch: {
    inputValue: 'validate',
    focus(focus) {
      if (!focus) {
        /**
         * On chrome autofill, the blur event is triggered before the value is updated. To
         * avoid the invalidation of the field and flickering, we need to delay the
         * validation.
         */
        setTimeout(() => {
          const isViable = this.validate()
          this.hasError = !isViable
        }, 50)
      }
    }
  },
  created() {
    this.validate()
  },
  methods: {
    validate() {
      const value = this.inputValue.replace(/\s/g, '')
      this.error = null // reset before running new validation

      // Check if the value is valid or if it's a test card
      const isViable =
        this.paymentMethodToken ||
        this.luhnAlgorithm(value) ||
        this.checkTestCards(value) ||
        this.isAnException(value)

      this.isViable = !!isViable
      return !!isViable
    },

    /**
     * Luhn algorithm validation
     */
    luhnAlgorithm(value) {
      if (value.length && _.isString(value)) {
        value = value.replace(/\s/g, '')
        if (!parseInt(value)) return false

        let odd = true
        let sum = 0
        let digits = `${value}`.split('').reverse()
        digits.forEach(digit => {
          digit = parseInt(digit, 10)
          if ((odd = !odd)) digit *= 2
          if (digit > 9) digit -= 9
          sum += digit
        })

        if (sum % 10 === 0) return true
      } else {
        this.error = ErrorsConf.fields.required.pan
      }

      return false
    },

    /**
     * Check if the value match with any of the test cards (in case
     * of the current environment is test)
     */
    checkTestCards(value) {
      const isTest = /testpublic/i.test(this.publicKey)

      // If it's not test environment, avoid the check
      if (!isTest) return false

      const brands = TestCards.brands
      for (const brand in brands) {
        for (const cardType in brands[brand]) {
          if (cardType.indexOf('_') !== 0) {
            for (const cardNumber in brands[brand][cardType]) {
              if (cardNumber === value) return true
            }
          }
        }
      }

      return false
    },

    /**
     * Checks if the case is an exception
     * FIXME: KJS-629.
     */
    isAnException(value) {
      return this.currency === 'ARS' && value.length === 19
    }
  }
}
