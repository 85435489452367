import {
  pick,
  defer,
  union,
  intersection,
  uniq,
  isObject,
  isArray,
  isString,
  isEqual
} from 'underscore'
import equal from 'deep-equal'
import extend from 'deep-extend'
import Events from '@/configuration/Events'
import synchronization from '@/configuration/sources/Synchronization.yml'

export default class Synchronizer {
  constructor($locator, app) {
    this.$locator = $locator
    this.app = app
    this.debug = false
    this.syncRecieved = false

    this.startStoreSubscription()
    this.startSyncSubscription()
    this.startGhostReloadSubscription()
    this.startGlobalSyncSubscription()
  }

  /**
   * Listen to changes on the store
   */
  startStoreSubscription() {
    const $store = this.$locator.$store
    let slaveFirstLoad = true

    $store.subscribe((mutation, state) => {
      // Slave first load report
      if (this.app === 'slave' && slaveFirstLoad && this.syncRecieved) {
        slaveFirstLoad = false
        this.reportSlaveLoad()
      }
      // Avoid sync mutations, they require another behavior
      if (this.isSyncMutation(mutation)) return

      if (!this.isFromFieldModule(mutation) && mutation.payload) {
        const namespace = this.isFromCardFormModule(mutation)
          ? mutation.type.split('/')[0]
          : null
        if (namespace && $store.hasModule(namespace)) {
          state = state[namespace]
        }
        if (this.debug) {
          this.log(
            `[${namespace}]: store updated`,
            pick(state, ...Object.keys(mutation.payload))
          )
        }
        this.sendUpdate(mutation.payload, this.app, namespace)
      }
    })
  }

  /**
   * Listen to sync events
   *
   * @see KJS-3458 Now sync event can be sent with the parameter "force"
   */
  startSyncSubscription() {
    const $bus = this.$locator.$bus
    const $store = this.$locator.$store

    $bus.$on(Events.krypton.message.sync, message => {
      const namespace = message?.metadata?.namespace || null
      const force = message?.force === true || false
      if (this.debug)
        this.log(
          `[${namespace}]: receiving sync (force: ${force})`,
          message.data
        )
      this.syncRecieved = true
      this.syncStore(message.data, message.origin, namespace, force)
      if (this.app === 'host' && !$store.state.synced) {
        $store.dispatch('synced')
      }
    })
  }

  /**
   * Listens when the ghost is reloaded to setup the store data
   */
  startGhostReloadSubscription() {
    const $bus = this.$locator.$bus
    const $store = this.$locator.$store

    $bus.$on(Events.krypton.message.echo, message => {
      if ($store.state.allIFramesReady && ~['ghost'].indexOf(message.source)) {
        this.sendUpdate($store.state, this.app)
        $bus.$emit(Events.krypton.sync.ghostReload)
      }
    })
  }

  /**
   * Listen to the global sync event to force a global sync of the store
   */
  startGlobalSyncSubscription() {
    const $bus = this.$locator.$bus
    const $store = this.$locator.$store

    $bus.$on(Events.krypton.message.globalSync, message => {
      if (this.debug) this.log(`receiving globalSync order`)
      const globalState = JSON.parse(JSON.stringify($store.state))
      for (const key in globalState) {
        if ($store.hasModule(key)) {
          // Filter cardForm_<formId> keys which are namespaces
          this.sendUpdate(globalState[key], this.app, key)
          delete globalState[key]
        }
      }
      this.sendUpdate(globalState, this.app)
    })
  }

  /**
   * Sends a sync event to the proper app
   */
  sendUpdate(payload, origin, namespace = null) {
    const app = this.app
    const storeFactory = this.$locator.storeFactory
    const allReadyQueue = this.$locator.allReadyQueue
    const proxy = this.$locator.proxy
    const $bus = this.$locator.$bus

    // Clone the payload to avoid mutations
    payload = JSON.parse(JSON.stringify(payload))

    // Check if we don't need to send the data
    if (this.isLastOfTheChain(app, origin)) return

    switch (app) {
      case 'host':
        // Send the sync event to the GHOST
        allReadyQueue.send(
          storeFactory.create('sync', {
            data: payload,
            metadata: {
              namespace
            },
            origin
          })
        )
        break
      case 'ghost':
        // Send the sync event to the SLAVES
        let ghost = this.$locator.ghost
        if (ghost) {
          ghost.reportForm('sync', { payload, origin, namespace }, false)
        } else {
          // FIX for IE. The store update is received before the app created callback
          const _this = this
          defer(() => {
            ghost = _this.$locator.ghost
            if (ghost)
              ghost.reportForm('sync', { payload, origin, namespace }, false)
          })
        }
        break
      case 'slave':
        // Send the sync event to the HOST
        $bus.$emit('proxy.send', {
          _name: 'sync',
          data: payload,
          metadata: {
            namespace
          },
          origin
        })
        break
      case 'redirect':
        proxy.send(
          storeFactory.create('sync', {
            data: payload,
            metadata: {},
            origin
          })
        )
        break
    }
  }

  /**
   * @see KJS-3458 Set formId for AllReadyQueue to identify the event source
   * and handle form initialization appropriately.
   */
  reportSlaveLoad() {
    const $store = this.$locator.$store
    if (!$store.state.field.ready) $store.dispatch('field/ready')
    this.$locator.$bus.$emit('proxy.send', {
      _name: 'loaded',
      formId: $store.state.field.formId
    })
  }

  /**
   * Syncronize the store with the given payload (if it's necessary)
   */
  syncStore(payload, origin, namespace, force = false) {
    this.cleanPayload(payload)
    if (
      Object.keys(payload).length &&
      (force || this.isAnUpdate(payload, namespace))
    ) {
      this.updateStore(payload, origin, namespace, force)
    }
  }

  /**
   * Cleans the data of the payload that shouldn't be propagated
   * For slave context, also:
   * Filter the properties that should not be propagated in 'loop'. The ones
   * that are only necessary in the ghost&host
   */
  cleanPayload(payload) {
    let excludedProps = synchronization.blackList.all

    // Slave
    if (this.app === 'slave') {
      excludedProps = union(excludedProps, synchronization.blackList.slave)
    }

    // Host
    if (this.app === 'host') {
      excludedProps = union(excludedProps, synchronization.blackList.host)
    }
    const avProps = intersection(excludedProps, Object.keys(payload))
    if (!avProps.length) return
    for (const prop of avProps) delete payload[prop]
  }

  /**
   * Checks if the given data is different from the app store
   */
  isAnUpdate(payload, namespace) {
    const { $store } = this.$locator
    const state =
      namespace && $store.hasModule(namespace)
        ? $store.state[namespace]
        : $store.state

    for (const key in payload) {
      // Objects
      if (
        isObject(state[key]) &&
        isObject(payload[key]) &&
        !isArray(state[key]) &&
        !isArray(payload[key])
      ) {
        if (
          equal(
            JSON.parse(JSON.stringify(state[key])),
            extend(
              JSON.parse(JSON.stringify(state[key])),
              JSON.parse(JSON.stringify(payload[key]))
            )
          )
        ) {
          delete payload[key]
        }
      } else if (
        // Arrays
        isArray(state[key]) &&
        isArray(payload[key]) &&
        isEqual(state[key], payload[key])
      ) {
        delete payload[key]
      } else if (
        // Non-objects/arrays
        (!isObject(state[key]) &&
          !isObject(payload[key]) &&
          state[key] === payload[key]) ||
        (isString(state[key]) &&
          isString(payload[key]) &&
          uniq(union([state[key]], [payload[key]])).length === 1)
      ) {
        delete payload[key]
      }
    }

    return !!Object.keys(payload).length
  }

  /**
   * Dispatch an update on the store
   */
  updateStore(payload, origin, namespace, force = false) {
    const $store = this.$locator.$store

    if (this.debug) this.log(`[${namespace}]: updating store:`, payload)
    const actionName =
      namespace && $store.hasModule(namespace) ? `${namespace}/sync` : 'sync'
    // Update the store with a different action to split the behaviours
    $store.dispatch(actionName, payload)
    if (this.debug) {
      this.log(`[${namespace}]: store updated, origin: ${origin}`, payload)
    }
    // Send the data to the next app of the chain
    this.sendUpdate(payload, origin, namespace, force)
  }

  /**
   * Checks if the current app is the last of the chain of communication in
   * order to prevent infinite loops
   */
  isLastOfTheChain(app, origin) {
    return (
      (app === 'host' && origin === 'ghost') ||
      (app === 'ghost' && origin === 'slave') ||
      (app === 'slave' && origin === 'host') ||
      (app === 'host' && origin === 'redirect')
    )
  }

  /**
   * Checks if the mutation is SYNC type
   */
  isSyncMutation(mutation) {
    return mutation.type === 'SYNC' || /\/SYNC$/.test(mutation.type)
  }

  /**
   * Checks if the mutation is from field store module
   */
  isFromFieldModule(mutation) {
    return mutation.type.indexOf('field/') === 0
  }

  isFromCardFormModule(mutation) {
    return mutation.type.indexOf('cardForm_') === 0
  }

  /**
   * @private
   */
  log(message, obj = {}) {
    const color =
      this.app === 'slave'
        ? '#bbbbbb'
        : this.app === 'ghost'
        ? '#eb4034'
        : '#68eb34'
    let app = this.app
    if (app === 'slave') {
      app += ' ' + this.$locator.$store.state.field.formId
    }
    // eslint-disable-next-line no-console
    console.log(
      '%c%s %s %O (%s)',
      `background: #222; color: ${color}`,
      app,
      message,
      JSON.parse(JSON.stringify(obj)),
      Object.keys(obj).join(', ')
    )
  }
}
