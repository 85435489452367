import { pick, isEqual, extend, difference, without } from 'underscore'
import deepExtend from 'deep-extend'
import { setSentryBasicInfo } from '@/common/util/sentry'
import { getTokenHelper } from '@/host/service/DNA/Helper/wallet'
import { onPaymentTokenDeletedFormatter } from '@/host/service/onPaymentTokenDeletedFormatter'

export const getCardFormState = () => {
  return {
    selectedBrand: 'DEFAULT',
    paymentBrand: 'DEFAULT',
    detectedBrands: ['DEFAULT'],
    unfilteredBrands: ['DEFAULT'],
    brandFallbacks: {},
    visibleFields: ['pan', 'expiryDate', 'securityCode'],
    fieldsOrder: [],
    disabledCardForm: false,
    filledInputs: [],
    disabledFields: [],
    invalidFields: [], // Fields with invalid value
    viableFields: [],
    regularFieldError: {
      identityDocumentType: null,
      identityDocumentNumber: null,
      cardHolderName: null,
      cardHolderMail: null,
      installmentNumber: null
    },
    isFormValid: false,
    securedCvv: false,
    cardBrandHelpVisibility: undefined,
    nonSensitiveValues: {
      paymentMethodToken: null,
      installmentNumber: -1,
      identityDocumentNumber: null,
      identityDocumentType: null,
      doRegister: null,
      cardHolderName: null,
      cardHolderMail: null,
      firstInstallmentDelay: null
    },
    bin: '',
    bin8: '',
    binOptions: {
      cuotas: null,
      delay: null
    },
    cvvSize: 3,
    testCard: null,

    // Action properties / triggers
    fieldClear: false,
    forcedFieldClear: false,
    forcedFieldBlur: false,
    // wallet
    walletMode: null,
    selectFields: {
      firstInstallmentDelay: false,
      identityDocumentType: false,
      installmentNumber: false
    }
  }
}

/**
 * Shared by Host & Ghost, slaves do not need other forms data
 */
export default app => {
  return {
    namespaced: true,
    state: getCardFormState(),
    actions: {
      update({ commit }, data) {
        commit('UPDATE', data)
      },
      sync({ commit }, data) {
        commit('SYNC', data)
      },
      disableField({ commit, state }, fieldName) {
        const { disabledFields } = state
        if (!~disabledFields.indexOf(fieldName)) {
          disabledFields.push(fieldName)
          commit('UPDATE', { disabledFields })
        }
      },
      enableField({ commit, state }, fieldName) {
        const { disabledFields } = state
        const index = disabledFields.indexOf(fieldName)
        if (~index) {
          disabledFields.splice(index, 1)
          commit('UPDATE', { disabledFields })
        }
      },
      hideField({ commit, state }, fieldName) {
        const { visibleFields } = state
        const index = visibleFields.indexOf(fieldName)
        if (~index) {
          visibleFields.splice(index, 1)
          commit('UPDATE', { visibleFields })
        }
      },
      showField({ commit, state }, fieldName) {
        const { visibleFields } = state
        if (!~visibleFields.indexOf(fieldName)) {
          visibleFields.push(fieldName)
          commit('UPDATE', { visibleFields })
        }
      },
      unsetInputFilled({ commit, state }, fieldName) {
        const { filledInputs } = state
        const index = filledInputs.indexOf(fieldName)
        if (~index) {
          filledInputs.splice(index, 1)
          commit('UPDATE', { filledInputs })
        }
      },
      setInputFilled({ commit, state, getters }, fieldName) {
        const { filledInputs } = state
        const { isFieldVisible } = getters
        if (isFieldVisible(fieldName)) {
          if (!~filledInputs.indexOf(fieldName)) {
            filledInputs.push(fieldName)
            commit('UPDATE', { filledInputs })
          }
        }
      },
      validateField({ commit, state }, fieldName) {
        const { invalidFields } = state
        const index = invalidFields.indexOf(fieldName)
        if (~index) {
          invalidFields.splice(index, 1)
          commit('UPDATE', { invalidFields })
        }
      },
      invalidateField({ commit, state }, fieldName) {
        const { invalidFields } = state
        if (!~invalidFields.indexOf(fieldName)) {
          invalidFields.push(fieldName)
          commit('UPDATE', { invalidFields })
        }
      },
      viableField({ commit, state }, fieldName) {
        const { viableFields } = state
        if (!~viableFields.indexOf(fieldName)) {
          viableFields.push(fieldName)
          commit('UPDATE', { viableFields })
        }
      },
      inviableField({ commit, state }, fieldName) {
        const { viableFields } = state
        const index = viableFields.indexOf(fieldName)
        if (~index) {
          viableFields.splice(index, 1)
          commit('UPDATE', { viableFields })
        }
      },
      setRegularFieldError({ commit }, { fieldName, errorCode }) {
        commit('UPDATE', {
          regularFieldError: {
            [fieldName]: errorCode
          }
        })
      },
      resetRegularFieldError({ commit }, fieldName) {
        commit('UPDATE', {
          regularFieldError: {
            [fieldName]: null
          }
        })
      },
      forceFieldClear({ commit, dispatch }) {
        commit('UPDATE', { forcedFieldClear: true, invalidFields: [] })
        dispatch('resetBin')
      },
      forceFieldBlur({ commit }) {
        commit('UPDATE', { forcedFieldBlur: true })
      },
      fieldClear({ commit }) {
        commit('UPDATE', { fieldClear: true })
      },
      resetBrand({ commit }) {
        commit('RESET_BRAND')
        commit('RESET_BRAND', null, { root: null })
      },
      updateNonSensitiveValue({ commit, state }, fieldObj) {
        if (app !== 'host') {
          return
        }
        const nonSensitiveValues = state.nonSensitiveValues
        const currentValues = pick(nonSensitiveValues, ...Object.keys(fieldObj))
        if (!isEqual(currentValues, fieldObj)) {
          extend(nonSensitiveValues, fieldObj)
          commit('UPDATE', {
            nonSensitiveValues
          })
        }
      },
      startWalletPayment({ dispatch, commit, rootState }, token) {
        const dnaCards = rootState.dna?.tokens?.cards || []
        const card = dnaCards.find(card => card.token === token)
        const detectedBrands = [card?.brand || 'DEFAULT']

        // Set disabled fields
        const disabledFields = []
        for (const [fieldName, data] of Object.entries(card?.fields || {})) {
          if (data.value && !data.inherit) disabledFields.push(fieldName)
        }

        commit('UPDATE', { disabledFields, detectedBrands })
        dispatch('updateNonSensitiveValue', {
          paymentMethodToken: token
        })
        dispatch('setWalletMode', 'myCards')
      },
      closeWalletPayment({ dispatch, rootState }) {
        if (rootState.formMode !== 'wallet') return

        dispatch('updateNonSensitiveValue', { paymentMethodToken: null })
        dispatch('setWalletMode', 'newCard')
        dispatch('fieldClear')
        dispatch('resetBrand')
      },
      changeWalletTab({ commit, dispatch }, mode) {
        dispatch('cleanError', null, { root: true })
        commit('UPDATE', { walletMode: mode })
      },
      setWalletMode({ commit }, mode) {
        commit('UPDATE', { walletMode: mode })
      },
      resetBinOptions({ commit }) {
        commit('UPDATE', { binOptions: getCardFormState().binOptions })
      },
      resetBin({ commit }) {
        commit('UPDATE', {
          bin: getCardFormState().bin,
          bin8: getCardFormState().bin8
        })
      },
      resetForm({ commit }) {
        const formSetupValues = ['disabledFields', 'visibleFields']

        const state = {}
        const defaultState = getCardFormState()
        for (const key of formSetupValues) {
          state[key] = defaultState[key]
        }

        commit('UPDATE', state)
      },
      deleteDNAToken(
        { state, commit, rootState, rootGetters, dispatch },
        paymentMethodToken
      ) {
        const cardTokens = rootState.dna?.tokens?.cards || []
        const smartFormTokens = rootState.dna?.tokens?.smartForm || []
        const tokens = [...cardTokens, ...smartFormTokens]

        if (tokens.length > 0) {
          // Filter the token if exists
          const cards = cardTokens.filter(x => x.token !== paymentMethodToken)
          const smartForm = smartFormTokens.filter(
            x => x.token !== paymentMethodToken
          )

          const dna = JSON.parse(JSON.stringify(rootState.dna))
          dna.tokens = { ...dna.tokens, cards, smartForm }
          const update = { dna }

          const { nonSensitiveValues } = state
          if (nonSensitiveValues.paymentMethodToken === paymentMethodToken) {
            commit('UPDATE', {
              nonSensitiveValues: {
                paymentMethodToken: null
              }
            })
          }
          if (rootGetters.isSelectedMethod(`CARDS:${paymentMethodToken}`)) {
            update.smartForm = {
              selectedMethod: 'CARDS'
            }
          }
          // If no payment method token remains, rollback to default formMode
          if (cards.length === 0 && smartForm.length === 0) {
            update.formMode = 'default'
          }

          commit('UPDATE', update, { root: true })

          const foundToken = getTokenHelper(
            [
              ...cardTokens.map(t => ({ ...t, paymentMethod: 'CARDS' })),
              ...smartFormTokens
            ],
            paymentMethodToken
          )
          if (foundToken) {
            dispatch(
              'intercept',
              {
                name: 'onPaymentTokenDeleted',
                args: [onPaymentTokenDeletedFormatter(foundToken)]
              },
              { root: true }
            )
          }

          return Promise.resolve()
        }
        return Promise.reject()
      },
      selectBrand({ state, getters, commit, rootGetters }, brand) {
        if (brand !== state.selectedBrand) {
          const brandToSel =
            rootGetters.isBrandAvailable(brand) ||
            rootGetters.isBrandForced ||
            getters.isDefaultBrand
              ? brand
              : 'DEFAULT'
          commit('UPDATE', {
            selectedBrand: brandToSel,
            paymentBrand: brandToSel
          })
        }
      },
      setPaymentBrand({ commit }, brand) {
        commit('UPDATE', { paymentBrand: brand })
      },
      disableCardForm({ commit, dispatch }) {
        commit('UPDATE', { disabledCardForm: true })
        dispatch('disableButton', null, { root: true })
      },
      enableCardForm({ commit, dispatch }) {
        commit('UPDATE', { disabledCardForm: false })
        dispatch('enableButton', null, { root: true })
      },
      enableButton({ commit }) {
        commit('UPDATE', { button: { disabled: false } })
      },
      disableButton({ commit }) {
        commit('UPDATE', { button: { disabled: true } })
      },
      setTestCard({ commit, dispatch, rootState }, testCard) {
        if (!rootState.disabledForm)
          dispatch('cleanError', null, { root: true })
        commit('UPDATE', { testCard, invalidFields: [] })
      },
      setBrandFallback({ commit }, brandFallbacks) {
        commit('UPDATE', { brandFallbacks })
      },
      toggleSelectField({ commit, state }, { status, fieldName }) {
        const { selectFields } = state
        selectFields[fieldName] = status

        commit('UPDATE', { selectFields: selectFields })
      },
      setBin(
        {
          commit,
          rootState: { binUpdateNotification, binOptionsMap },
          dispatch
        },
        bin
      ) {
        const obj = { bin: bin.substring(0, 6) }
        obj.bin8 = binUpdateNotification.url && bin.length === 8 ? bin : ''
        commit('UPDATE', obj)

        // setBinOptions
        if (bin.length >= 4 && bin.length <= 8) {
          for (const binConf of binOptionsMap) {
            let binBase = bin
            // Adapt the length to the config
            if (binConf.bin.length < bin.length)
              binBase = binBase.substr(0, binConf.bin.length)
            if (binConf.bin === binBase) {
              commit('UPDATE', {
                binOptions: pick(binConf, 'cuotas', 'delay')
              })
              return
            }
          }
        }

        // Nothing found, set the default value
        dispatch('resetBinOptions')
      }
    },
    getters: {
      isDefaultBrand: ({ selectedBrand }) => selectedBrand === 'DEFAULT',
      isFormFilled: state => state.filledInputs.length > 0,
      isFieldDisabled: state => fieldName =>
        !!(state.disabledCardForm || ~state.disabledFields.indexOf(fieldName)),
      isFieldVisible: (state, _getters, rootState) => fieldName => {
        if (
          fieldName === 'doRegister' &&
          rootState._internals.doRegister.forced === true
        ) {
          return false
        }
        return !!~state.visibleFields.indexOf(fieldName)
      },
      lastVisibleField: ({ fieldsOrder }) =>
        fieldsOrder && fieldsOrder[fieldsOrder?.length - 1],
      isFieldInvalid: state => fieldName =>
        !!~state.invalidFields.indexOf(fieldName),
      isFieldViable: state => fieldName =>
        !!~state.viableFields.indexOf(fieldName),
      isDebitBrand:
        (state, getters, rootState, rootGetters) =>
        (brand = state.selectedBrand) => {
          return rootGetters.isDebitBrand(brand)
        },
      isWalletMyCards: (state, getters, rootState) =>
        rootState.formMode === 'wallet' && state.walletMode === 'myCards',
      isTokenActive: state => {
        return token => state.nonSensitiveValues.paymentMethodToken === token
      },
      isAnyTokenActive: state =>
        state.nonSensitiveValues.paymentMethodToken !== null,
      isTokenForced: ({ formMode }) => formMode === 'token',
      getRegularFieldError: ({ regularFieldError }) => {
        return fieldName => regularFieldError[fieldName]
      },
      activeToken: state => state.nonSensitiveValues.paymentMethodToken
    },
    mutations: {
      UPDATE: (state, updateObject) => {
        // brand fallback (payment brand)
        if ('selectedBrand' in updateObject) {
          updateObject.paymentBrand =
            updateObject.selectedBrand in state.brandFallbacks
              ? state.brandFallbacks[updateObject.selectedBrand]
              : updateObject.selectedBrand
        }
        // Form valid
        if ('viableFields' in updateObject) {
          const visibleFields = without(state.visibleFields, 'walletCardList')
          updateObject.isFormValid = !difference(
            visibleFields,
            updateObject.viableFields
          ).length
        }
        // Extend state
        state = deepExtend(state, updateObject)
        setSentryBasicInfo(state)
      },
      RESET_BRAND: state => {
        const valuesToClean = [
          'selectedBrand',
          'detectedBrands',
          'paymentBrand'
        ]
        const defaultFormState = getCardFormState()
        for (const field of valuesToClean) {
          state[field] = defaultFormState[field]
        }
        setSentryBasicInfo(state)
      },
      SYNC: (state, updateObject) => {
        state = deepExtend(state, updateObject)
        setSentryBasicInfo(state)
      }
    }
  }
}
