<template lang="pug">
  svg(width='100%' height='100%' xmlns='http://www.w3.org/2000/svg')
    line(x1='50%' x2='50%' stroke='black' stroke-dasharray='2 2' y1='0' y2='100%')
</template>

<script>
export default {
  name: 'VerticalDashedLine'
}
</script>
