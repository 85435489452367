/**
 * Allow returning the same object regardless of whether or not a factory method
 * was successfully executed, return warnings & errors gathered during the
 * object creation.
 *
 * @since KJS-4764
 */
export default class FactoryResultWrapper {
  /**
   * @param {any} value
   * @param {FactoryLogger} logger
   */
  constructor(value, logger) {
    this.value = value
    this.logger = logger
  }

  isValid() {
    return !!this.value
  }

  getErrors() {
    return this.logger.errors
  }
}
