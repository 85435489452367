import { allowedBrands } from '@/configuration/sources/smartform/samsungPay.yml'
import { getSamsungPayFormattedAmount } from '../../../common/util/currencyFormatter'
import { CoBrands, SubBrands } from '@/configuration/sources/Brands.yml'

/**
 * File dedicated to build SamsungPay data structures needed by the client
 * https://developer.samsung.com/pay/web/api-reference.html
 */

export function getAllowedBrands(dnaBrands) {
  if (!dnaBrands) return []
  const transformedDnaBrands = dnaBrands.map(brand => {
    // LATAM brand variation to generic brand
    let formattedBrand = CoBrands.brands[brand] ?? brand
    // Specific changes of sub-brands must adapt to googlePay supported brands
    formattedBrand = SubBrands[formattedBrand] ?? formattedBrand
    return formattedBrand
  })
  const samsungAllowedBrands = transformedDnaBrands
    .map(brand => allowedBrands[brand])
    .filter(b => !!b)
  return Array.from(new Set(samsungAllowedBrands))
}
export function getSamsungPaymentMethods({ samsungPayServiceId, dnaBrands }) {
  return {
    version: '2',
    serviceId: samsungPayServiceId,
    protocol: 'PROTOCOL_3DS',
    allowedBrands: getAllowedBrands(dnaBrands),
    merchantChoiceBrands: {
      type: 'PREFERENCE',
      brands: ['cb']
    }
  }
}

export function getSamungTransactionDetails({
  orderNumber,
  merchantName,
  merchantUrl,
  merchantId,
  countryCode,
  amount,
  currency
}) {
  return {
    orderNumber,
    merchant: {
      name: merchantName,
      url: merchantUrl,
      id: merchantId,
      countryCode
    },
    amount: {
      option: 'FORMAT_TOTAL_ESTIMATED_AMOUNT',
      currency: currency,
      total: getSamsungPayFormattedAmount(amount)
    }
  }
}
