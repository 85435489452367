import { ensureUniqId } from '@/common/util/svg'

export const assets = {
  regular: {
    branded: {
      KADEOS_CULTURE: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-kadeos_culture" */ '@/assets/images/icons/regular/brands/kadeos_culture.svg'
            )
          ).default
        ),
      KADEOS_GIFT: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-kadeos_gift" */ '@/assets/images/icons/regular/brands/kadeos_gift.svg'
            )
          ).default
        ),
      TUYA: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-tuya" */ '@/assets/images/icons/regular/brands/tuya.svg'
            )
          ).default
        ),
      CARNET: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-carnet" */ '@/assets/images/icons/regular/brands/carnet.svg'
            )
          ).default
        ),
      CONECS: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-conecs" */ '@/assets/images/icons/regular/brands/conecs.svg'
            )
          ).default
        ),
      DINERS: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-diners" */ '@/assets/images/icons/regular/brands/diners.svg'
            )
          ).default
        ),
      SODEXO: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-sodexo" */ '@/assets/images/icons/regular/brands/sodexo.svg'
            )
          ).default
        ),
      EDENRED_CO: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-edenred_co" */ '@/assets/images/icons/regular/brands/edenred_co.svg'
            )
          ).default
        ),
      EDENRED_TR: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-edenred_co" */ '@/assets/images/icons/regular/brands/edenred_co.svg'
            )
          ).default
        ),
      EDENRED_TC: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-edenred_co" */ '@/assets/images/icons/regular/brands/edenred_co.svg'
            )
          ).default
        ),
      EDENRED_SC: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-edenred_co" */ '@/assets/images/icons/regular/brands/edenred_co.svg'
            )
          ).default
        ),
      EDENRED_EC: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-edenred_co" */ '@/assets/images/icons/regular/brands/edenred_co.svg'
            )
          ).default
        ),
      CHQ_DEJ: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-chq_dej" */ '@/assets/images/icons/regular/brands/chq_dej.svg'
            )
          ).default
        ),
      APETIZ: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-apetiz" */ '@/assets/images/icons/regular/brands/apetiz.svg'
            )
          ).default
        ),
      MASTERCARD: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-mastercard" */ '@/assets/images/icons/regular/brands/mastercard.svg'
            )
          ).default
        ),
      MASTERCARD_DEBIT: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-mastercard_debit" */ '@/assets/images/icons/regular/brands/mastercard_debit.svg'
            )
          ).default
        ),
      AMEX: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-amex" */ '@/assets/images/icons/regular/brands/amex.svg'
            )
          ).default
        ),
      CB: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-cb" */ '@/assets/images/icons/regular/brands/cb.svg'
            )
          ).default
        ),
      MAESTRO: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-maestro" */ '@/assets/images/icons/regular/brands/maestro.svg'
            )
          ).default
        ),
      BANCONTACT: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-bancontact" */ '@/assets/images/icons/regular/brands/bancontact.svg'
            )
          ).default
        ),
      VISA: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-visa" */ '@/assets/images/icons/regular/brands/visa.svg'
            )
          ).default
        ),
      VISA_DEBIT: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-visa_debit" */ '@/assets/images/icons/regular/brands/visa_debit.svg'
            )
          ).default
        ),
      VISA_CREDIT: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-visa" */ '@/assets/images/icons/regular/brands/visa.svg'
            )
          ).default
        ),
      VISA_ELECTRON: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-visa_electron" */ '@/assets/images/icons/regular/brands/visa_electron.svg'
            )
          ).default
        ),
      VPAY: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-vpay" */ '@/assets/images/icons/regular/brands/vpay.svg'
            )
          ).default
        ),
      CABAL: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-cabal" */ '@/assets/images/icons/regular/brands/cabal.svg'
            )
          ).default
        ),
      CABAL_DEBIT: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-cabal" */ '@/assets/images/icons/regular/brands/cabal.svg'
            )
          ).default
        ),
      DCC: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-planet_dcc" */ '@/assets/images/icons/regular/brands/planet_dcc.svg'
            )
          ).default
        ),
      CMR: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-cmr" */ '@/assets/images/icons/regular/brands/cmr.svg'
            )
          ).default
        ),
      DISCOVER: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-discover" */ '@/assets/images/icons/regular/brands/discover.svg'
            )
          ).default
        ),
      OSB: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-osb" */ '@/assets/images/icons/regular/brands/osb.svg'
            )
          ).default
        ),
      PRV_SMART_CARD: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-prv_smart_card" */ '@/assets/images/icons/regular/brands/prv_smart_card.svg'
            )
          ).default
        ),
      PRV_OPT: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-prv_opt" */ '@/assets/images/icons/regular/brands/prv_opt.svg'
            )
          ).default
        ),
      'E-CARTEBLEUE': async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-e_cartebleue" */ '@/assets/images/icons/regular/brands/e_cartebleue.svg'
            )
          ).default
        ),
      JCB: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-jcb" */ '@/assets/images/icons/regular/brands/jcb.svg'
            )
          ).default
        ),
      NARANJA: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-naranja" */ '@/assets/images/icons/regular/brands/naranja.svg'
            )
          ).default
        ),
      AURA: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-aura" */ '@/assets/images/icons/regular/brands/aura.svg'
            )
          ).default
        ),
      ELO: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-elo" */ '@/assets/images/icons/regular/brands/elo.svg'
            )
          ).default
        ),
      HIPER: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-hiper" */ '@/assets/images/icons/regular/brands/hiper.svg'
            )
          ).default
        ),
      HIPERCARD: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-hipercard" */ '@/assets/images/icons/regular/brands/hipercard.svg'
            )
          ).default
        ),
      PRV_BDP: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-prv_bdp" */ '@/assets/images/icons/regular/brands/prv_bdp.svg'
            )
          ).default
        ),
      PRV_SOC: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-prv_socredo" */ '@/assets/images/icons/regular/brands/prv_socredo.svg'
            )
          ).default
        )
    }
  },
  outline: {
    branded: {
      DINERS: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-diners_outline" */ '@/assets/images/icons/regular/brands/diners_outline.svg'
            )
          ).default
        )
    }
  },
  error: {
    pan: async () =>
      ensureUniqId(
        (
          await import(
            /* webpackChunkName: "kr-asset-error-card" */ '@/assets/images/icons/errors/fields/card.svg'
          )
        ).default
      ),
    expiryDate: async () =>
      ensureUniqId(
        (
          await import(
            /* webpackChunkName: "kr-asset-error-expiry" */ '@/assets/images/icons/errors/fields/expiry.svg'
          )
        ).default
      ),
    securityCode: async () =>
      ensureUniqId(
        (
          await import(
            /* webpackChunkName: "kr-asset-error-securityCode" */ '@/assets/images/icons/errors/fields/securityCode.svg'
          )
        ).default
      ),
    identityDocumentNumber: async () =>
      ensureUniqId(
        (
          await import(
            /* webpackChunkName: "kr-asset-error-idCard" */ '@/assets/images/icons/errors/fields/idCard.svg'
          )
        ).default
      ),
    cardHolderName: async () =>
      ensureUniqId(
        (
          await import(
            /* webpackChunkName: "kr-asset-error-cardName" */ '@/assets/images/icons/errors/fields/cardName.svg'
          )
        ).default
      ),
    cardHolderMail: async () =>
      ensureUniqId(
        (
          await import(
            /* webpackChunkName: "kr-asset-error-cardMail" */ '@/assets/images/icons/errors/fields/cardMail.svg'
          )
        ).default
      ),
    branded: {
      KADEOS_CULTURE: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-error-kadeos_culture" */ '@/assets/images/icons/errors/brands/kadeos_culture.svg'
            )
          ).default
        ),
      KADEOS_GIFT: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-error-kadeos_gift" */ '@/assets/images/icons/errors/brands/kadeos_gift.svg'
            )
          ).default
        ),
      TUYA: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-error-tuya" */ '@/assets/images/icons/errors/brands/tuya.svg'
            )
          ).default
        ),
      CARNET: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-error-carnet" */ '@/assets/images/icons/errors/brands/carnet.svg'
            )
          ).default
        ),
      CONECS: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-error-conecs" */ '@/assets/images/icons/errors/brands/conecs.svg'
            )
          ).default
        ),
      DINERS: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-error-diners" */ '@/assets/images/icons/errors/brands/diners.svg'
            )
          ).default
        ),
      SODEXO: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-error-sodexo" */ '@/assets/images/icons/errors/brands/sodexo.svg'
            )
          ).default
        ),
      EDENRED_CO: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-error-edenred_co" */ '@/assets/images/icons/errors/brands/edenred_co.svg'
            )
          ).default
        ),
      CHQ_DEJ: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-error-chq_dej" */ '@/assets/images/icons/errors/brands/chq_dej.svg'
            )
          ).default
        ),
      APETIZ: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-error-apetiz" */ '@/assets/images/icons/errors/brands/apetiz.svg'
            )
          ).default
        ),
      MASTERCARD: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-error-mastercard" */ '@/assets/images/icons/errors/brands/mastercard.svg'
            )
          ).default
        ),
      MASTERCARD_DEBIT: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-error-mastercard_debit" */ '@/assets/images/icons/errors/brands/mastercard_debit.svg'
            )
          ).default
        ),
      AMEX: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-error-amex" */ '@/assets/images/icons/errors/brands/amex.svg'
            )
          ).default
        ),
      CB: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-error-cb" */ '@/assets/images/icons/errors/brands/cb.svg'
            )
          ).default
        ),
      MAESTRO: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-error-maestro" */ '@/assets/images/icons/errors/brands/maestro.svg'
            )
          ).default
        ),
      BANCONTACT: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-error-bancontact" */ '@/assets/images/icons/errors/brands/bancontact.svg'
            )
          ).default
        ),
      VISA: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-error-visa" */ '@/assets/images/icons/errors/brands/visa.svg'
            )
          ).default
        ),
      VISA_DEBIT: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-error-visa_debit" */ '@/assets/images/icons/errors/brands/visa_debit.svg'
            )
          ).default
        ),
      VISA_CREDIT: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-error-visa" */ '@/assets/images/icons/errors/brands/visa.svg'
            )
          ).default
        ),
      VISA_ELECTRON: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-error-visa_electron" */ '@/assets/images/icons/errors/brands/visa_electron.svg'
            )
          ).default
        ),
      VPAY: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-error-vpay" */ '@/assets/images/icons/errors/brands/vpay.svg'
            )
          ).default
        ),
      CABAL: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-error-cabal" */ '@/assets/images/icons/errors/brands/cabal.svg'
            )
          ).default
        ),
      CABAL_DEBIT: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-error-cabal" */ '@/assets/images/icons/errors/brands/cabal.svg'
            )
          ).default
        ),
      DCC: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-error-planet_dcc" */ '@/assets/images/icons/errors/brands/planet_dcc.svg'
            )
          ).default
        ),
      CMR: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-error-cmr" */ '@/assets/images/icons/errors/brands/cmr.svg'
            )
          ).default
        ),
      DISCOVER: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-error-discover" */ '@/assets/images/icons/errors/brands/discover.svg'
            )
          ).default
        ),
      OSB: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-error-osb" */ '@/assets/images/icons/errors/brands/osb.svg'
            )
          ).default
        ),
      PRV_SMART_CARD: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-error-prv_smart_card" */ '@/assets/images/icons/errors/brands/prv_smart_card.svg'
            )
          ).default
        ),
      'E-CARTEBLEUE': async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-error-e_cartebleue" */ '@/assets/images/icons/errors/brands/e_cartebleue.svg'
            )
          ).default
        ),
      JCB: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-error-jcb" */ '@/assets/images/icons/errors/brands/jcb.svg'
            )
          ).default
        ),
      NARANJA: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-error-naranja" */ '@/assets/images/icons/errors/brands/naranja.svg'
            )
          ).default
        ),
      AURA: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-error-aura" */ '@/assets/images/icons/errors/brands/aura.svg'
            )
          ).default
        ),
      ELO: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-error-elo" */ '@/assets/images/icons/errors/brands/elo.svg'
            )
          ).default
        ),
      HIPER: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-error-hiper" */ '@/assets/images/icons/errors/brands/hiper.svg'
            )
          ).default
        ),
      HIPERCARD: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-error-hipercard" */ '@/assets/images/icons/errors/brands/hipercard.svg'
            )
          ).default
        ),
      PRV_BDP: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-error-prv_bdp" */ '@/assets/images/icons/errors/brands/prv_bdp.svg'
            )
          ).default
        ),
      PRV_SOC: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-error-prv_socredo" */ '@/assets/images/icons/errors/brands/prv_socredo.svg'
            )
          ).default
        )
    }
  },
  help: {
    securityCode: async () =>
      ensureUniqId(
        (
          await import(
            /* webpackChunkName: "kr-asset-help-security_code" */ '@/assets/images/icons/help_securityCode.svg'
          )
        ).default
      ),
    securityCodeAmex: async () =>
      ensureUniqId(
        (
          await import(
            /* webpackChunkName: "kr-asset-help-security_code_amex" */ '@/assets/images/icons/help_securityCode_amex.svg'
          )
        ).default
      )
  },
  visibility: {
    on: async () =>
      ensureUniqId(
        (
          await import(
            /* webpackChunkName: "kr-asset-visibility-on" */ '@/assets/images/icons/visibility.svg'
          )
        ).default
      ),
    off: async () =>
      ensureUniqId(
        (
          await import(
            /* webpackChunkName: "kr-asset-visibility-off" */ '@/assets/images/icons/visibilityOff.svg'
          )
        ).default
      )
  },
  paymentMethods: {
    regular: {
      BIZUM: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-bizum" */ '@/assets/images/icons/regular/payment_methods/bizum.svg'
            )
          ).default
        ),
      PSE: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-pse" */ '@/assets/images/icons/regular/payment_methods/pse.svg'
            )
          ).default
        ),
      FRANFINANCE: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-franfinance" */ '@/assets/images/icons/regular/payment_methods/franfinance.svg'
            )
          ).default
        ),
      FRANFINANCE_3X: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-franfinance" */ '@/assets/images/icons/regular/payment_methods/franfinance.svg'
            )
          ).default
        ),
      FRANFINANCE_4X: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-franfinance" */ '@/assets/images/icons/regular/payment_methods/franfinance.svg'
            )
          ).default
        ),
      SEPA: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-sepa" */ '@/assets/images/icons/regular/payment_methods/sepa.svg'
            )
          ).default
        ),
      EFECTY: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-efecty" */ '@/assets/images/icons/regular/payment_methods/efecty.svg'
            )
          ).default
        ),
      PAGOEFECTIVO: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-pagoefectivo" */ '@/assets/images/icons/regular/payment_methods/pagoefectivo.svg'
            )
          ).default
        ),
      PIX: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-pix" */ '@/assets/images/icons/regular/payment_methods/pix.svg'
            )
          ).default
        ),
      IP_WIRE: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-ip_wire" */ '@/assets/images/icons/regular/payment_methods/ip_wire.svg'
            )
          ).default
        ),
      IP_WIRE_INST: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-ip_wire" */ '@/assets/images/icons/regular/payment_methods/ip_wire.svg'
            )
          ).default
        ),
      AVWU: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-avwu" */ '@/assets/images/icons/regular/payment_methods/avwu.svg'
            )
          ).default
        ),
      BALOTO: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-baloto" */ '@/assets/images/icons/regular/payment_methods/baloto.svg'
            )
          ).default
        ),
      RED_AVAL: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-red_aval" */ '@/assets/images/icons/regular/payment_methods/red_aval.svg'
            )
          ).default
        ),
      INTERRAPIDISIMO: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-interrapidisimo" */ '@/assets/images/icons/regular/payment_methods/interrapidisimo.svg'
            )
          ).default
        ),
      SURED: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-sured" */ '@/assets/images/icons/regular/payment_methods/sured.svg'
            )
          ).default
        ),
      MOVIL_RED: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-movil_red" */ '@/assets/images/icons/regular/payment_methods/movil_red.svg'
            )
          ).default
        ),
      EXITO_CASH: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-exito_cash" */ '@/assets/images/icons/regular/payment_methods/exito_cash.svg'
            )
          ).default
        ),
      BANCOLOMBIA_IP: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-bancolombia" */ '@/assets/images/icons/regular/payment_methods/bancolombia.svg'
            )
          ).default
        ),
      NEQUI: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-nequi" */ '@/assets/images/icons/regular/payment_methods/nequi.svg'
            )
          ).default
        ),
      APPLE_PAY: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-apple_pay" */ '@/assets/images/icons/regular/payment_methods/apple_pay.svg'
            )
          ).default
        ),
      PAYPAL: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-paypal" */ '@/assets/images/icons/regular/payment_methods/paypal.svg'
            )
          ).default
        ),
      PAYPAL_SB: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-paypal" */ '@/assets/images/icons/regular/payment_methods/paypal.svg'
            )
          ).default
        ),
      PAYPAL_BNPL: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-paypal" */ '@/assets/images/icons/regular/payment_methods/paypal.svg'
            )
          ).default
        ),
      PAYPAL_BNPL_SB: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-paypal" */ '@/assets/images/icons/regular/payment_methods/paypal.svg'
            )
          ).default
        ),
      ALMA: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-alma" */ '@/assets/images/icons/regular/payment_methods/alma.svg'
            )
          ).default
        ),
      ALMA_2X: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-alma" */ '@/assets/images/icons/regular/payment_methods/alma.svg'
            )
          ).default
        ),
      ALMA_3X: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-alma" */ '@/assets/images/icons/regular/payment_methods/alma.svg'
            )
          ).default
        ),
      ALMA_4X: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-alma" */ '@/assets/images/icons/regular/payment_methods/alma.svg'
            )
          ).default
        ),
      ALMA_10X: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-alma" */ '@/assets/images/icons/regular/payment_methods/alma.svg'
            )
          ).default
        ),
      ALMA_12X: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-alma" */ '@/assets/images/icons/regular/payment_methods/alma.svg'
            )
          ).default
        ),
      COFIDIS: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-cofidis" */ '@/assets/images/icons/regular/payment_methods/cofidis.svg'
            )
          ).default
        ),
      COFIDIS_DFPAY_FR: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-cofidis_dfpay" */ '@/assets/images/icons/regular/payment_methods/cofidis_dfpay.svg'
            )
          ).default
        ),
      COFIDIS_FRAC_ES: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-cofidis_frac_es" */ '@/assets/images/icons/regular/payment_methods/cofidis_frac_es.svg'
            )
          ).default
        ),
      COFIDIS_LOAN_BE: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-cofidis_loan_be" */ '@/assets/images/icons/regular/payment_methods/cofidis_loan_be.svg'
            )
          ).default
        ),
      COFIDIS_LOAN_CB: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-cofidis_loan_cb" */ '@/assets/images/icons/regular/payment_methods/cofidis_loan_cb.svg'
            )
          ).default
        ),
      COFIDIS_LOAN_ES: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-cofidis_loan_es" */ '@/assets/images/icons/regular/payment_methods/cofidis_loan_es.svg'
            )
          ).default
        ),
      COFIDIS_LOAN_FR: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-cofidis_loan_fr" */ '@/assets/images/icons/regular/payment_methods/cofidis_loan_fr.svg'
            )
          ).default
        ),
      COFIDIS_LOAN_IT: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-cofidis_loan_it" */ '@/assets/images/icons/regular/payment_methods/cofidis_loan_it.svg'
            )
          ).default
        ),
      COFIDIS_PAY_FR: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-cofidis_pay_fr" */ '@/assets/images/icons/regular/payment_methods/cofidis_pay_fr.svg'
            )
          ).default
        ),
      COFIDIS_4X_ES: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-cofidis_4x" */ '@/assets/images/icons/regular/payment_methods/cofidis_4x.svg'
            )
          ).default
        ),
      COFIDIS_4X_FR: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-cofidis_4x" */ '@/assets/images/icons/regular/payment_methods/cofidis_4x.svg'
            )
          ).default
        ),
      COFIDIS_3X_BE: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-cofidis_3x" */ '@/assets/images/icons/regular/payment_methods/cofidis_3x.svg'
            )
          ).default
        ),
      COFIDIS_3X_FR: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-cofidis_3x" */ '@/assets/images/icons/regular/payment_methods/cofidis_3x.svg'
            )
          ).default
        ),
      SICREDI_BOLETO: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-sicredi_boleto" */ '@/assets/images/icons/regular/payment_methods/sicredi_boleto.svg'
            )
          ).default
        ),
      BRADESCO_BOLETO: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-bradesco" */ '@/assets/images/icons/regular/payment_methods/bradesco.svg'
            )
          ).default
        ),
      SICOOB_BOLETO: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-sicoob" */ '@/assets/images/icons/regular/payment_methods/sicoob.svg'
            )
          ).default
        ),
      PAYCONIQ: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-payconiq" */ '@/assets/images/icons/regular/payment_methods/payconiq.svg'
            )
          ).default
        ),
      UPI: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-upi" */ '@/assets/images/icons/regular/payment_methods/upi.svg'
            )
          ).default
        ),
      MULTIBANCO: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-multibanco" */ '@/assets/images/icons/regular/payment_methods/multibanco.svg'
            )
          ).default
        ),
      MB_WAY: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-mb_way" */ '@/assets/images/icons/regular/payment_methods/mb_way.svg'
            )
          ).default
        ),
      WEBPAY_PLUS: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-web_pay_plus" */ '@/assets/images/icons/regular/payment_methods/web_pay_plus.svg'
            )
          ).default
        ),
      GOOGLEPAY: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-google_pay" */ '@/assets/images/icons/regular/payment_methods/google_pay.svg'
            )
          ).default
        ),
      ALINEA: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-alinea" */ '@/assets/images/icons/regular/payment_methods/alinea.svg'
            )
          ).default
        ),
      ALINEA_SB: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-alinea" */ '@/assets/images/icons/regular/payment_methods/alinea.svg'
            )
          ).default
        ),
      AUCHAN: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-auchan" */ '@/assets/images/icons/regular/payment_methods/auchan.svg'
            )
          ).default
        ),
      AUCHAN_SB: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-auchan" */ '@/assets/images/icons/regular/payment_methods/auchan.svg'
            )
          ).default
        ),
      BOULANGER: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-boulanger" */ '@/assets/images/icons/regular/payment_methods/boulanger.svg'
            )
          ).default
        ),
      BOULANGER_SB: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-boulanger" */ '@/assets/images/icons/regular/payment_methods/boulanger.svg'
            )
          ).default
        ),
      NORAUTO: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-norauto" */ '@/assets/images/icons/regular/payment_methods/norauto.svg'
            )
          ).default
        ),
      NORAUTO_SB: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-norauto" */ '@/assets/images/icons/regular/payment_methods/norauto.svg'
            )
          ).default
        ),
      ONEY: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-oney" */ '@/assets/images/icons/regular/payment_methods/oney.svg'
            )
          ).default
        ),
      ONEY_3X_4X: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-oney_3x_4x" */ '@/assets/images/icons/regular/payment_methods/oney_3x_4x.svg'
            )
          ).default
        ),
      ONEY_10X_12X: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-oney_10x_12x" */ '@/assets/images/icons/regular/payment_methods/oney_10x_12x.svg'
            )
          ).default
        ),
      ONEY_ENSEIGNE: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-oney_enseigne" */ '@/assets/images/icons/regular/payment_methods/oney_enseigne.svg'
            )
          ).default
        ),
      ONEY_PAYLATER: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-oney_paylater" */ '@/assets/images/icons/regular/payment_methods/oney_paylater.svg'
            )
          ).default
        ),
      PICWIC: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-picwic" */ '@/assets/images/icons/regular/payment_methods/picwic.svg'
            )
          ).default
        ),
      PICWIC_SB: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-picwic" */ '@/assets/images/icons/regular/payment_methods/picwic.svg'
            )
          ).default
        ),
      CASH_LATAM: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-cash_latam" */ '@/assets/images/icons/regular/payment_methods/cash_latam.svg'
            )
          ).default
        ),
      CVCO: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-cvco" */ '@/assets/images/icons/regular/payment_methods/cvco.svg'
            )
          ).default
        ),
      ALIPAY_PLUS: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-alipay_plus" */ '@/assets/images/icons/regular/payment_methods/alipay_plus.svg'
            )
          ).default
        ),
      AKULAKU_PAYLATER: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-akulaku_paylater" */ '@/assets/images/icons/regular/payment_methods/akulaku_paylater.svg'
            )
          ).default
        ),
      ALIPAY_CN: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-alipay_cn" */ '@/assets/images/icons/regular/payment_methods/alipay_cn.svg'
            )
          ).default
        ),
      ALIPAY_HK: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-alipay_hk" */ '@/assets/images/icons/regular/payment_methods/alipay_hk.svg'
            )
          ).default
        ),
      BILLEASE: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-billease" */ '@/assets/images/icons/regular/payment_methods/billease.svg'
            )
          ).default
        ),
      BOOST: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-boost" */ '@/assets/images/icons/regular/payment_methods/boost.svg'
            )
          ).default
        ),
      BPI: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-bpi" */ '@/assets/images/icons/regular/payment_methods/bpi.svg'
            )
          ).default
        ),
      DANA: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-dana" */ '@/assets/images/icons/regular/payment_methods/dana.svg'
            )
          ).default
        ),
      GCASH: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-gcash" */ '@/assets/images/icons/regular/payment_methods/gcash.svg'
            )
          ).default
        ),
      KAKAOPAY: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-kakaopay" */ '@/assets/images/icons/regular/payment_methods/kakaopay.svg'
            )
          ).default
        ),
      KREDIVO_ID: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-kredivo_id" */ '@/assets/images/icons/regular/payment_methods/kredivo_id.svg'
            )
          ).default
        ),
      MAYA: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-maya" */ '@/assets/images/icons/regular/payment_methods/maya.svg'
            )
          ).default
        ),
      MPAY: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-mpay" */ '@/assets/images/icons/regular/payment_methods/mpay.svg'
            )
          ).default
        ),
      RABBIT_LINE_PAY: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-rabbit_line_pay" */ '@/assets/images/icons/regular/payment_methods/rabbit_line_pay.svg'
            )
          ).default
        ),
      TNG: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-tng" */ '@/assets/images/icons/regular/payment_methods/tng.svg'
            )
          ).default
        ),
      TRUEMONEY: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-truemoney" */ '@/assets/images/icons/regular/payment_methods/truemoney.svg'
            )
          ).default
        ),
      FLOA: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-floa" */ '@/assets/images/icons/regular/payment_methods/floa.svg'
            )
          ).default
        ),
      FLOA_3X: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-floa_3x" */ '@/assets/images/icons/regular/payment_methods/floa_3x.svg'
            )
          ).default
        ),
      FLOA_4X: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-floa_4x" */ '@/assets/images/icons/regular/payment_methods/floa_4x.svg'
            )
          ).default
        ),
      FLOA_10X: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-floa_10x" */ '@/assets/images/icons/regular/payment_methods/floa_10x.svg'
            )
          ).default
        ),
      FLOA_PAYLATER: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-floa_paylater" */ '@/assets/images/icons/regular/payment_methods/floa_paylater.svg'
            )
          ).default
        ),
      TRANSFIYA: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-transfiya" */ '@/assets/images/icons/regular/payment_methods/transfiya.svg'
            )
          ).default
        ),
      WECHAT_PAY: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-wechatpay" */ '@/assets/images/icons/regular/payment_methods/wechat_pay.svg'
            )
          ).default
        ),
      TOSS: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-toss" */ '@/assets/images/icons/regular/payment_methods/toss.svg'
            )
          ).default
        ),
      SAMSUNG_PAY: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-samsung_pay" */ '@/assets/images/icons/regular/payment_methods/samsung_pay.svg'
            )
          ).default
        )
    },
    compact: {
      APPLE_PAY: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-compact-apple_pay" */ '@/assets/images/icons/regular/payment_methods/compact/apple_pay.svg'
            )
          ).default
        ),
      GOOGLEPAY: async () =>
        ensureUniqId(
          (
            await import(
              /* webpackChunkName: "kr-asset-compact-google_pay" */ '@/assets/images/icons/regular/payment_methods/compact/google_pay.svg'
            )
          ).default
        )
    }
  }
}
