import { formGetters } from '@/store/modules/form'
import { popinGetters } from '@/store/modules/popin'
import { iosGetters } from '@/store/modules/ios'
import { cssGetters } from '@/store/modules/css'
import { redirectGetters } from '@/store/modules/redirect'
import { errorGetters } from '@/store/modules/error'
import { translationGetters } from '@/store/modules/translation'
import { environmentGetters } from '@/store/modules/environment'
import { smartFormGetters } from '@/store/modules/smartform'
import { navigationGetters } from '@/store/modules/navigation'
import { windowGetters } from '@/store/modules/window'
import { configGetters } from '@/store/modules/config'
import { internalsGetters } from '@/store/modules/internals'
import { applePayGetters } from '@/store/modules/applePay'
import { googlePayGetters } from '@/store/modules/googlePay'
import { samsungPayGetters } from '@/store/modules/samsungPay'
import { amountGetters } from '@/store/modules/amount'
import { toolbarGetters } from '@/store/modules/toolbar'
import { extrasFormGetters } from '@/store/modules/extrasForm'
import { domGetters } from '@/store/modules/dom'
import { walletGetters } from '@/store/modules/wallet'
import { partialPaymentGetters } from '@/store/modules/partialPayment'
import tokens from '@/configuration/sources/Tokens.yml'

// root getters
export default async app => {
  const transGetters = await translationGetters(app)
  return {
    isProduction: state => {
      return !state.testKeys && ~process.env.NODE_ENV.indexOf('production')
    },
    isDefaultToken: ({ formToken }) =>
      formToken === tokens.noToken || formToken === tokens.demoToken,
    hasValidToken: ({ parsingToken }, { isDefaultToken }) =>
      !parsingToken && !isDefaultToken,
    isDemoToken: ({ formToken, prefixedDemoToken }) =>
      formToken === tokens.demoTokenReplacement || // token replacemente string
      formToken === tokens.demoToken || // token is default demo token
      /^ff.*$/.test(formToken) || // tokens starting by 'ff'
      prefixedDemoToken, // tokens with 'demo-' prefix
    ecsOrderId: ({ formToken }) => {
      if (!formToken) return ''
      const toHexString = str =>
        Array.from(str) // Convert string to array of characters
          .map(c => c.charCodeAt(0).toString(16).padStart(2, '0')) // Convert to hex
          .join('')
      const encodedString = formToken?.slice(2, 24)
      const binaryString = atob(
        encodedString.replace(/-/g, '+').replace(/_/g, '/')
      )
      return toHexString(binaryString)
    },
    ...formGetters,
    ...popinGetters,
    ...iosGetters,
    ...cssGetters,
    ...redirectGetters,
    ...errorGetters,
    ...transGetters,
    ...environmentGetters,
    ...smartFormGetters,
    ...navigationGetters,
    ...windowGetters,
    ...configGetters,
    ...internalsGetters,
    ...applePayGetters,
    ...googlePayGetters,
    ...samsungPayGetters,
    ...amountGetters,
    ...toolbarGetters,
    ...extrasFormGetters,
    ...domGetters,
    ...walletGetters,
    ...partialPaymentGetters
  }
}
