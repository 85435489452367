import { has } from 'underscore'

export default class RestAPI {
  constructor({ $store }) {
    this.$store = $store
  }

  getProcessPaymentUrl(endpoint) {
    return `${endpoint}/%%webservices_version%%/Charge/Public/ProcessPayment/2`
  }

  getGetApplePayTokenUrl(endpoint) {
    return `${endpoint}/%%webservices_version%%/Charge/Public/GetApplePayToken`
  }

  getGetApplePayPayloadInfos(endpoint) {
    return `${endpoint}/%%webservices_version%%/Charge/Public/GetPayloadInfos`
  }

  getPaymentGetUrl(endpoint) {
    return `${endpoint}/%%webservices_version%%/Charge/Public/Payment/Get`
  }

  getTokenCancelUrl(endpoint) {
    return `${endpoint}/%%webservices_version%%/Charge/Public/Token/Cancel`
  }

  getDeclinePartialPaymentUrl(endpoint) {
    return `${endpoint}/%%webservices_version%%/Charge/Public/DeclinePartialPayment`
  }

  getReceiptUrl(endpoint) {
    return `${endpoint}/vads-payment/ecs.return.a`
  }

  addJSessionID(url, dna = null) {
    if (!dna) dna = this.$store.state.dna
    const query = has(dna, 'jSessionId')
      ? `;jsessionid=${dna['jSessionId']}`
      : ''
    return `${url}${query}`
  }

  addECSOrderId(url) {
    return `${url}?orderId=${this.$store.getters.ecsOrderId}`
  }

  getWSUser() {
    return 'JS Client'
  }

  getDeviceData() {
    const {
      hostViewport: { height, width },
      language
    } = this.$store.state
    return {
      deviceType: 'BROWSER',
      acceptHeader: 'application/json',
      userAgent: `${window.navigator.userAgent}`,
      javaEnabled: true,
      language,
      colorDepth: `${window.screen.colorDepth}`,
      screenHeight: height,
      screenWidth: width,
      timeZoneOffset: `${new Date().getTimezoneOffset()}`
    }
  }

  getClientVersion(paymentForm = null) {
    const { endpointDomain, baseAddress } = this.$store.state
    const { isFormPopin, isSmartForm, isUsingCDN, integrationCase } =
      this.$store.getters
    const version = '%%library_version%%'.replace('V', '')
    const build = '%%build_number%%'
    let clientVersion = `js-${version}-${build} ${
      isSmartForm ? 'smf' : isFormPopin ? 'popin' : 'emb'
    }`

    if (paymentForm) {
      const { dna } = this.$store.state
      const hiddenRegister =
        dna.cards.DEFAULT.fields.doRegister?.hidden === false
      if (hiddenRegister && paymentForm.doRegister) clientVersion += ' arp'
    }

    if (typeof navigator.cookieEnabled === 'boolean')
      clientVersion += navigator.cookieEnabled ? ' c1' : ' c2'

    if (!isUsingCDN) clientVersion += ' no-cdn'

    if (integrationCase) clientVersion += ` h${integrationCase}`

    if (!this.shareBaseDomain(endpointDomain, baseAddress))
      clientVersion += ' x1'

    return clientVersion
  }

  /**
   * Compare base domain, ignoring subdomains.
   * E.g:
   *  static.lyra.com & api.lyra.com match
   *  lyra.com & lyra.lat DO NOT MATCH
   * @since KJS-3413
   */
  shareBaseDomain(url1, url2) {
    const domain1 = new URL(url1).hostname.split('.').slice(-2).join('.')
    const domain2 = new URL(url2).hostname.split('.').slice(-2).join('.')
    return domain1 === domain2
  }

  buildProcessPaymentWalletPayload({ payload, payloadType, endpoint }) {
    const { formToken, publicKey, remoteId } = this.$store.state
    const { partialPaymentsAllowed, getFormAdditionalData } =
      this.$store.getters

    const objectData = {
      formToken,
      publicKey,
      paymentForm: {
        walletPayload: JSON.stringify(payload),
        brand: payloadType,
        ...getFormAdditionalData
      },
      remoteId,
      clientVersion: this.getClientVersion(),
      device: this.getDeviceData(),
      wsUser: this.getWSUser(),
      partialPaymentsAllowed
    }
    const url = this.addJSessionID(this.getProcessPaymentUrl(endpoint))
    return { objectData, url }
  }
}
