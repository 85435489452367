<script>
import { mapGetters, mapState } from 'vuex'
import { HtmlToVueCompiler } from '@/common/util/templateCompiler'

import KryptonButtonAnimation from '@/host/components/controls/animation/ButtonAnimation'

/**
 * !! Stricly handle the visible rendering of the SimpleModal footer buttons !!
 * !! Logic should remain in parent components !!
 *
 * Props:
 *  The "config" follows the format used in SimpleModal.yml configuration for
 *  the buttons parameter.

 *  config: {
 *    label: string
 *    type: string
 *    action: string | string[]
 *  }
 * loading?: boolean (default: false)
 *
 * E.g: In the default case with neon
 * Given
 *  label: 'remove',
 *  action: 'deleteToken',
 *  type: 'error'
 * 
 * Rendering shall look like:
 * button.kr-btn-error.kr-btn-action(title='Remove' type='button')
 *  span Remove
 *  KryptonButtonAnimation(type="spinning-squares")
 *
 * E.g 2: A non-action button (action: closeModal) does not have any loading
 * animation.
 * Given:
 *  label: 'cancel',
 *  action: 'closeModal',
 *  type: 'secondary'
 * 
 * button.kr-btn-secondary(title='Close' type='button')
 *  span Close
 *
 * @since KJS-4825
 */
export default {
  name: 'SimpleModalButton',
  components: {
    KryptonButtonAnimation
  },
  props: {
    config: {
      type: Object,
      required: true,
      validator: value => {
        if (typeof value.label !== 'string') return false
        if (typeof value.type !== 'string') return false
        if (typeof value.action !== 'string' && !Array.isArray(value.action))
          return false
        return true
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['translate']),
    ...mapState({
      template: state => state.button.template,
      animation: ({ button }) => button.animation
    }),
    dynamicClasses() {
      return {
        [`kr-btn-${this.config.type}`]: true,
        'kr-btn-action': this.isActionType,
        'kr-loading': this.hasAnimation && this.loading
      }
    },
    hasAnimation() {
      return this.isActionType && this.animation
    },
    isActionType() {
      return this.config.action !== 'closeModal'
    },
    text() {
      return this.translate(this.config.label)
    },
    html() {
      if (!this.isActionType) return this.text

      return this.template
        .replace(/\s*\{price\}\s*/, '')
        .replace('{label}', this.text)
        .trim()
    }
  },
  render($createElement) {
    const compiler = new HtmlToVueCompiler($createElement)
    const label = compiler.render(this.html)

    const children = [label]
    if (this.hasAnimation) {
      children.push(compiler.render(this.animation))
    }

    const attrs = {
      type: 'button',
      title: this.text
    }

    return $createElement(
      'button',
      {
        class: this.dynamicClasses,
        attrs: { ...attrs, ...this.$attrs },
        on: this.$listeners
      },
      children
    )
  }
}
</script>
