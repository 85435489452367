var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "kr-sp-option-activator",
      class: _vm.dynamicClasses,
      attrs: { type: "button" },
      on: {
        click: function ($event) {
          return _vm.onClick()
        },
      },
    },
    [
      _c("SplitPaymentPanelOptionRadio", { attrs: { checked: _vm.selected } }),
      _c("span", { staticClass: "kr-sp-option-activator-label" }, [
        _vm._v(_vm._s(_vm.label)),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }