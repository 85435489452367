var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "kr-popin-modal-header",
      class: {
        "kr-secure-iframe-visible": _vm.redirectIframeOpen,
        "kr-long-shop-name": !_vm.singleLineShopName,
        "kr-smart-form-modal-header": _vm.isSmartForm,
      },
      attrs: { "kr-resource": "" },
    },
    [
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.redirectIframeOpen,
            expression: "!redirectIframeOpen",
          },
        ],
        staticClass: "kr-popin-modal-header-background",
        style: { backgroundColor: _vm.merchant.header.backgroundColor },
      }),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.redirectIframeOpen,
            expression: "!redirectIframeOpen",
          },
        ],
        staticClass: "kr-popin-modal-header-background-image",
        style: { background: _vm.popinHeaderBackground },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasHeaderImage,
              expression: "hasHeaderImage",
            },
          ],
          staticClass: "kr-popin-modal-header-image",
        },
        [
          _c("div", { staticClass: "kr-header-border-shadow" }),
          _c("div", { staticClass: "kr-header-border" }),
          _c("div", { staticClass: "kr-header-logo-shadow" }),
          _c("div", {
            staticClass: "kr-header-logo",
            style: {
              backgroundColor: _vm.merchant.header.image.backgroundColor,
            },
          }),
          _c("img", {
            staticClass: "kr-header-logo",
            style: {
              backgroundColor: _vm.merchant.header.image.backgroundColor,
            },
            attrs: { src: _vm.headerLogoSrc, "aria-hidden": "true" },
          }),
        ]
      ),
      _c("i", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.redirectIframeOpen,
            expression: "redirectIframeOpen",
          },
        ],
        staticClass: "fi-lock",
        style: _vm.logoStyles,
        domProps: { innerHTML: _vm._s(_vm.logoSVG) },
      }),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.redirectIframeOpen,
              expression: "redirectIframeOpen",
            },
          ],
          staticClass: "kr-popin-header",
          style: _vm.labelStyles,
        },
        [
          _vm._v(
            _vm._s(
              _vm.redirectIframeOpen ? _vm.translate(_vm.headerTransKey) : ""
            )
          ),
        ]
      ),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.redirectIframeOpen && _vm.shopNameVisible,
              expression: "!redirectIframeOpen && shopNameVisible",
            },
          ],
          staticClass: "kr-popin-shop-name",
          style: {
            background: _vm.popinLogoShadow,
            borderColor: _vm.shopNameBorderColor,
          },
        },
        [
          _c("span", { style: { color: _vm.merchant.header.shopName.color } }, [
            _vm._v(_vm._s(_vm.shopName)),
          ]),
        ]
      ),
      !_vm.redirectIframeOpen && _vm.amountVisible && !_vm.isAmountNull
        ? [_c("KryptonPopinHeaderLabel", { attrs: { type: _vm.headerLabel } })]
        : _vm._e(),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                !_vm.redirectIframeOpen &&
                _vm.orderIdVisible &&
                !_vm.hasPartialPayments,
              expression:
                "!redirectIframeOpen && orderIdVisible && !hasPartialPayments",
            },
          ],
          staticClass: "kr-popin-order-id",
        },
        [
          _c("span", { style: { color: _vm.merchant.header.orderId.color } }, [
            _vm._v(_vm._s(_vm.orderId)),
          ]),
        ]
      ),
      _c("CloseButton", {
        attrs: { "is-visible": _vm.hasCloseButton },
        on: {
          close: function ($event) {
            return _vm.onClose()
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }