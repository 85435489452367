import {
  getSamsungPaymentMethods,
  getSamungTransactionDetails
} from '@/ghost/service/samsungPay/SamsungPayData'
import { logSentryException } from '@/common/util/sentry'
import Events from '@/configuration/Events'

/**
 * Class used to call the SamsungPay client
 * see the doc for more details
 * https://developer.samsung.com/pay/web/api-reference.html
 */
export default class SamsungPayManager {
  constructor({ $store, $bus, proxy, storeFactory }) {
    this.$store = $store
    this.$bus = $bus
    this.proxy = proxy
    this.storeFactory = storeFactory
  }

  static clientInstance = undefined

  static getSumsungClientInstance() {
    if (this.clientInstance) return this.clientInstance
    if (typeof window.SamsungPay?.PaymentClient !== 'function') return undefined
    // always sending production to be able to do real payments in inte
    this.clientInstance = new SamsungPay.PaymentClient({
      environment: 'PRODUCTION',
      nonce: '019523ff-7032-75e2-80ea-1b7bdbc4dc88'
    })
    return this.clientInstance
  }

  static isReadyToPayCall({ samsungPayServiceId, dnaBrands }) {
    const samsungClient = SamsungPayManager.getSumsungClientInstance()
    return samsungClient?.isReadyToPay(
      getSamsungPaymentMethods({ samsungPayServiceId, dnaBrands })
    )
  }

  /**
   * @param {{status: 'CHARGED' | 'CANCELED' | 'REJECTED' | 'ERRED'}} status
   */
  static notifyResultToSamsungPay({ status }) {
    const samsungClient = SamsungPayManager.getSumsungClientInstance()
    samsungClient?.notify({ status, provider: 'Lyra Network' })
  }

  async startPayment(paymentParams) {
    const { testMode } = paymentParams
    try {
      const result = await this.getPaymentResult(paymentParams)

      // SamsungPay payment will be triggered from the GetPayloadInfos or the extra fields modal
      this.$bus.$emit(Events.krypton.message.getPayloadInfos, {
        payload: result,
        payloadType: 'SAMSUNG_PAY',
        testMode
      })
    } catch (samsungPayError) {
      const error = {
        errorCode:
          samsungPayError === 'user_cancel' ? 'CLIENT_101' : 'CLIENT_001',
        paymentMethod: 'SAMSUNG_PAY',
        metadata: { samsungPayError }
      }

      this.$store.dispatch('error', error)
      console.error(samsungPayError)
      logSentryException(samsungPayError, 'ghost/service/SamsungPayManager')
    }
  }

  async getPaymentResult(paymentParams) {
    const { testMode, testModeWithoutSimulator } = paymentParams

    if (testMode && !testModeWithoutSimulator) {
      return this.getResultFromSimulator()
    }

    return this.getPaymentResultFromSamsungPay(paymentParams)
  }

  getResultFromSimulator() {
    // This call is made from the ghost and the simulator is in the host
    // Need communication via proxy.send
    const result = new Promise((resolve, reject) => {
      this.$bus.$on(
        Events.krypton.message.samsungPaySimulatorAction,
        actionInfo => {
          if (actionInfo.type === 'close') {
            this.proxy.send(
              this.storeFactory.create('closeSamsungPaySimulatorStore')
            )

            // Only unsubscribe on close, as it can receive multiple events before closing
            this.$bus.$off(Events.krypton.message.samsungPaySimulatorAction)
            reject('user_cancel')
            return
          }
          resolve(actionInfo.payload)
        }
      )
    })

    this.$store.dispatch('openSamsungPaySimulator')
    return result
  }

  async getPaymentResultFromSamsungPay(paymentParams) {
    const { samsungPayServiceId, dnaBrands } = paymentParams
    const samsungClient = SamsungPayManager.getSumsungClientInstance()
    this.$bus.$once(Events.krypton.message.samsungPayPaymentError, () => {
      SamsungPayManager.notifyResultToSamsungPay({
        status: 'ERRED'
      })
    })
    this.$bus.$once(Events.krypton.message.payment, () => {
      SamsungPayManager.notifyResultToSamsungPay({
        status: 'CHARGED'
      })
    })

    const modalOptions = {
      margin: {
        left: 'auto',
        right: 'auto',
        top: 'auto',
        bottom: 'auto'
      }
    }

    const result = await samsungClient?.loadPaymentSheet(
      getSamsungPaymentMethods({ samsungPayServiceId, dnaBrands }),
      getSamungTransactionDetails(paymentParams),
      modalOptions
    )
    return result
  }
}
