var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", {
    staticClass: "kr-sp-header-label",
    style: _vm.dynamicStyles,
    domProps: { innerHTML: _vm._s(_vm.content) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }