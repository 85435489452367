var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "kr-sp-panel" },
    [
      _vm.showPayNowOption
        ? _c("SplitPaymentPanelOption", {
            attrs: {
              selected: _vm.isOptionSelected(_vm.PAY_NOW_OPTION),
              label: _vm.getOptionLabel(_vm.PAY_NOW_OPTION),
            },
            on: {
              selected: function ($event) {
                return _vm.selectOption(_vm.PAY_NOW_OPTION)
              },
            },
          })
        : _vm._e(),
      _vm._l(_vm.installmentOptions, function (option, idx) {
        return [
          _c("SplitPaymentPanelOption", {
            attrs: {
              selected: _vm.isOptionSelected(idx),
              label: _vm.getOptionLabel(option),
              "data-lang": _vm.language,
            },
            on: {
              selected: function ($event) {
                return _vm.selectOption(idx)
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "kr-sp-option-timeline-wrapper" },
                        [
                          _c("SplitPaymentTimeline", {
                            attrs: { schedule: option.schedules },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }