<template lang="pug">
  .kr-methods(:class="dynamicClasses.wrapper", :style="dynamicStyles")
    SmartFormMethodContent(
      v-if="!hasCardMethodOption"
      key="CARDS"
      :visible="(!hasCardMethodOption && isActiveMethod('CARDS')) || paymentDone"
      v-on:back="closeMethod"
    )
      slot
    SmartFormMethodContent(
      v-for="method in paymentMethods"
      :key="method"
      :visible="isActiveMethod(method)"
      :method="method"
      v-on:back="closeMethod"
    )
      slot(v-if="isCardMethod(method)")
    .kr-smart-form-iframe-container(v-show="showIframe", :class="dynamicClasses.iframe")
      KryptonGhostContainer
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

import KryptonGhostContainer from '@/host/components/KryptonGhostContainer'
import SmartFormMethodContent from '@/host/components/smartform/MethodContent'
import { PopinSizeMixin } from '@/host/components/mixins/PopinSize'

export default {
  name: 'SmartFormMethodsListContent',
  components: { KryptonGhostContainer, SmartFormMethodContent },
  mixins: [PopinSizeMixin],
  props: {
    mode: { type: String, default: 'all' }
  },
  data() {
    return {
      heightDelta: 0
    }
  },
  computed: {
    ...mapGetters([
      'translate',
      'isActiveMethod',
      'isCardMethod',
      'hasCardMethodOption'
    ]),
    ...mapGetters({
      paymentMethods: 'effectivePaymentMethodList'
    }),
    ...mapState({
      activeMethod: state => state.smartForm.activeMethod,
      paymentDone: state => state.smartForm.paymentDone,
      backButtonConfig: state => state.form.smartform.modal.backButton
    }),
    ...mapState(['redirectIframeOpen', 'redirectTransaction']),
    isBackButtonHidden() {
      return !!this.redirectTransaction
    },
    dynamicClasses() {
      const classes = {
        wrapper: {
          'kr-method-active': this.activeMethod,
          'kr-secure-iframe-open': this.redirectIframeOpen
        },
        iframe: {}
      }
      if (this.activeMethod) {
        const methodClass = `kr-method-${this.activeMethod.toLowerCase()}`
        classes.wrapper[methodClass] = true
        classes.iframe[methodClass] = true
      }
      return classes
    },
    dynamicStyles() {
      if (!this.redirectIframeOpen) {
        return {}
      }
      let { width, height } = this.popinSize
      const styles = {
        width: `${width}px`
      }
      if (!this.isBackButtonHidden) {
        if (this.heightDelta) {
          height += this.heightDelta
        }
      }
      return styles
    },
    showIframe() {
      return !!this.redirectIframeOpen
    }
  },
  watch: {
    redirectIframeOpen: 'updateHeightDelta'
  },
  mounted() {
    const $form = this.$el.querySelector('.kr-embedded')
    if ($form) $form.style.display = 'flex'
  },
  methods: {
    ...mapActions(['closeMethod']),
    closeMethod() {
      this.closeMethod()
      this.$emit('back')
    },
    /**
     * In order to calculate the wrapper height, we must consider the
     * back button height only if displayed.
     * Therefore for each MethodContent child, we search for one visible
     * AND whose back button is rendered (it can be disabled).
     * If none is found, then delta is set to 0.
     * If one is found, then set delta to the back button height
     * defined in the config.
     *
     * @since KJS-2512
     */
    updateHeightDelta() {
      const visibleButton = this.$children.find(
        c => c.visible && c.renderBackButton && !c.isBackButtonHidden
      )
      const modalHeight = parseInt(this.backButtonConfig.$redirect.height ?? 0)
      if (visibleButton && modalHeight) {
        this.heightDelta = modalHeight
      } else {
        this.heightDelta = 0
      }
    }
  }
}
</script>
