var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "kr-loading-animation", class: _vm.dynamicClasses.wrapper },
    [_c("i", { staticClass: "kr-loader", class: _vm.dynamicClasses.loader })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }