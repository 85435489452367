import { extend, isString } from 'underscore'

export default class ResponseHandler {
  constructor($locator) {
    this.$locator = $locator
    this.$store = $locator.$store
    this.$bus = $locator.$bus
    this.storeFactory = $locator.storeFactory
  }

  /**
   * Create a billing store or redirect store
   * reading the raw answer form the server
   */
  async factoryBillingOrRedirect(paymentResponse) {
    const answer = paymentResponse.answer

    // Log
    this.$locator.logger.log('info', `ProcessPayment response`, paymentResponse)

    // Detect a redirection (3dsecure)
    if (answer._type === '%%webservices_version%%/Charge/RedirectRequest') {
      const redirect = this.storeFactory.create('billingRedirect', answer)
      return Promise.resolve(redirect)
    } else if (
      answer.clientAnswer.orderStatus === 'PARTIALLY_PAID' &&
      answer.clientAnswer.orderCycle === 'OPEN' &&
      answer.clientAnswer.transactions[0].status === 'PAID'
    ) {
      // Clear the field here and not in the addSplitPayment action because the proxy.send adds an ugly delay
      this.$store.dispatch('forceFieldClear')
      return Promise.resolve(
        this.storeFactory.create(
          'billingPartialPayment',
          extend(answer, {
            rawClientAnswer: JSON.stringify(answer.clientAnswer),
            formId: this.$store.getters.activeFormId
          })
        )
      )
    } else if (answer.errorCode || !this.isAcceptable(answer)) {
      return Promise.reject({ metadata: answer })
    }

    return await this.getTransactionStore(paymentResponse)
  }

  /**
   * Factories the store depending on the received answer
   * from server
   */
  getTransactionStore(payResp) {
    const $store = this.$locator.$store
    const answer = payResp.answer
    const rawReserved = payResp._reserved
    const status = answer?.clientAnswer?.transactions[0]?.status.toUpperCase()
    const ppRegex = /^V\d{1}.*\/Charge\/ProcessPaymentAnswer$/

    const serverDateTime = payResp.serverDate || payResp.serverDateTime
    if (serverDateTime) $store.dispatch('update', { serverDateTime })

    // Payment accepted or running, billing with it : BillingTransaction
    if (~['PAID', 'RUNNING'].indexOf(status) && ppRegex.test(answer._type)) {
      if (rawReserved?.receipt) answer.receipt = rawReserved.receipt

      return Promise.resolve(
        this.storeFactory.create(
          'billingTransaction',
          extend(answer, {
            rawClientAnswer: JSON.stringify(answer.clientAnswer)
          })
        )
      )
    } else {
      const error = {}
      if (status === 'UNPAID') {
        if (this.has3DSAuthenticationFailed(answer)) {
          const cardHolderInfo = this.get3DSFailedAuthenticationMessage(answer)
          if (cardHolderInfo) {
            error.errorCode = 'ACQ_001'
            error.errorMessage = '%translation% - ' + cardHolderInfo
          }
        }
        error.metadata = { answer }
      }

      return Promise.reject(error)
    }
  }

  /**
   * @param {Object} answer
   * @returns {boolean}
   * @since KJS-3830
   */
  has3DSAuthenticationFailed(answer) {
    return (
      answer?.clientAnswer?.transactions[0]?.transactionDetails?.cardDetails
        ?.authenticationResponse?.value?.status === 'FAILED'
    )
  }

  /**
   * @param {Object} answer
   * @returns {string}
   * @since KJS-3830
   */
  get3DSFailedAuthenticationMessage(answer) {
    return (
      answer?.clientAnswer?.transactions[0]?.transactionDetails?.cardDetails
        ?.authenticationResponse?.value?.extension?.cardHolderInfo ?? null
    )
  }

  /**
   * Checks if the response from the server is acceptable.
   * Checks the structure and the data type, does not make any
   * data check.
   */
  isAcceptable(answer) {
    return (
      isString(answer?.clientAnswer?.transactions[0]?.status) &&
      answer?.clientAnswer?.transactions[0]?.uuid
    )
  }
}
