<template lang="pug">
    .kr-brand-buttons
        .kr-brand-button(
            v-for="brandOption in brandOptions",
            :class="[(brandOption === selectedOption) ? 'kr-selected' : '', 'kr-' + brandOption.class]",
            :style="buttonStyle",
            @click="selectedOption = brandOption"
        )
            .kr-brand-button-icon(v-html="brandOption.icon")
            span.kr-brand-button-label(v-show="krShowLabels == 'true'", :style="{ visibility: (brandOption.label) ? 'visible' : 'hidden' }") {{brandOption.label}}
</template>

<script>
import brandButtonLabels from '@/configuration/sources/BrandButtonLabels.yml'
import PreloadedAssets from '@/configuration/PreloadedAssets'
import { assets } from '@/common/loader/assets'

/** MIXINS */
import BrandMixin from '@/common/components/mixins/Brand'

export default {
  name: 'KryptonBrandButtons',
  mixins: [BrandMixin],
  props: ['krBrands', 'krShowLabels'],
  data() {
    return {
      brandOptions: [],
      selectedOption: {
        brand: null,
        class: null,
        icon: null,
        label: null
      },
      buttonStyle: {
        cursor: 'pointer'
      }
    }
  },
  watch: {
    selectedBrand(brand) {
      if (brand !== this.selectedOption.brand) this.selectedOption = {}
    },
    selectedOption({ brand }) {
      if (brand && this.detectedBrands[0] === 'DEFAULT') {
        this.selectedBrand = brand
      }
    }
  },
  async mounted() {
    await this.generateButtons()
  },
  methods: {
    /**
     * Generate the buttons from the given brands
     */
    async generateButtons() {
      const brandOptions = this.krBrands.split(',')
      const { regular } = assets

      for (const brand of brandOptions) {
        const option = {
          icon:
            (await regular.branded[brand]()) || PreloadedAssets.regular.card,
          class: brand.toLowerCase().replace(/\_/g, '-'),
          label: brandButtonLabels.brands[brand],
          brand
        }
        this.brandOptions.push(option)
      }
    }
  }
}
</script>
