export const RadioButtonMixin = {
  props: {
    checked: {
      type: [Boolean, String],
      default: false
    },
    hover: {
      type: [Boolean, String],
      default: false
    }
  },
  computed: {
    /**
     * For isChecked & isHovered, the three following cases are covered in
     * order to normalize its value:
     *   If used as a boolean prop (<radio-button  checked>)
     *   If bound to a boolean (<radio-button :checked="true">)
     *   If bound as boolean prop in pug (radio-button (checked))
     */
    isChecked() {
      return (
        this.checked === '' ||
        this.checked === true ||
        this.checked === 'checked'
      )
    },
    isHovered() {
      return this.hover === '' || this.hover === true || this.hover === 'hover'
    }
  }
}
