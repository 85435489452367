<template lang="pug">
  .kr-spinner
    .kr-bounce1
    .kr-bounce2
    .kr-bounce3
</template>

<script>
/**
 * Theme classic.
 * Moved from theme JS to make it a component.
 *
 * @since KJS-4716
 */
export default {
  name: 'KryptonAnimationBubbles'
}
</script>
