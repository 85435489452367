/**
 * Extended by InstallmentOptionCollection & InstallmentSchedule
 *
 * @since KJS-4363
 */
export default class Collection {
  constructor(collection) {
    this.collection = collection
  }

  toArray() {
    return this.collection.slice(0)
  }

  toJSON() {
    return JSON.stringify(this.collection)
  }

  at(idx) {
    return this.collection[idx]
  }

  last() {
    return this.collection[this.collection.length - 1]
  }

  find(fn) {
    return this.collection.find(fn)
  }

  get length() {
    return this.collection.length
  }

  *[Symbol.iterator]() {
    for (const value of this.collection) {
      yield value
    }
  }
}
