import { InstallmentOptionCollection } from '@/common/model/'

export const getSplitPaymentState = () => {
  return {
    splitPayment: {
      outsidePanel: false,
      selectedOptionId: null,
      percentage: 'amount', // amount | time
      showPayNowOption: true
    }
  }
}

export const getSplitPaymentActions = () => {
  return {
    detachSplitPayment({ commit }) {
      commit('UPDATE', { smartForm: { splitPayment: { outsidePanel: true } } })
    },
    selectInstallmentOption({ commit }, id) {
      commit('UPDATE', {
        smartForm: { splitPayment: { selectedOptionId: id } }
      })
    }
  }
}

export const getSplitPaymentGetters = () => {
  return {
    isSplitPaymentPanelOutside(state) {
      return state.smartForm.splitPayment.outsidePanel
    },
    isSplitPaymentEnabled(state, getters) {
      const { dna } = state
      const { isSmartForm, splitPaymentsAllowed } = getters
      return !!(isSmartForm && splitPaymentsAllowed && dna && dna?.installments)
    },
    hasDnaInstallments(state, getters) {
      const { dna } = state
      const { isSmartForm } = getters
      return !!(isSmartForm && dna && dna?.installments)
    },
    shouldRenderSPCardHeader(_state, getters) {
      const { isSplitPaymentEnabled, isSplitPaymentPanelOutside } = getters
      return isSplitPaymentEnabled && !isSplitPaymentPanelOutside
    },
    rawInstallmentOptions(state, getters) {
      const { dna } = state
      return getters.isSplitPaymentEnabled ? dna.installments : null
    },
    installmentOptionsWrapper(_state, getters) {
      const { rawInstallmentOptions } = getters

      if (!rawInstallmentOptions) return null

      return InstallmentOptionCollection.create(rawInstallmentOptions)
    },
    installmentOptions(_state, getters) {
      const { installmentOptionsWrapper } = getters

      if (!installmentOptionsWrapper || !installmentOptionsWrapper.isValid())
        return null

      return installmentOptionsWrapper.value
    },
    hasInstallmentSelected(_state, getters) {
      const { isSplitPaymentEnabled, selectedInstallmentOption } = getters

      return !!(isSplitPaymentEnabled && selectedInstallmentOption)
    },
    selectedInstallmentOption(state, getters) {
      const { selectedOptionId: id } = state.smartForm.splitPayment
      const { installmentOptions } = getters

      if (!installmentOptions) return null
      return installmentOptions.find(option => option.id === id) ?? null
    },
    selectedInstallmentOptionId(state) {
      const { selectedOptionId } = state.smartForm.splitPayment
      return selectedOptionId
    },
    firstAmount(_state, getters) {
      const { hasInstallmentSelected, selectedInstallmentOption } = getters
      return hasInstallmentSelected
        ? selectedInstallmentOption.schedules.at(0).amount
        : null
    },
    filterMethodWithInstallments(_state, getters) {
      return method =>
        !getters.hasInstallmentSelected || method.startsWith('CARDS')
    },
    splitPaymentsAllowed(state) {
      const { hasActivated } = state.partialPayment
      return !hasActivated
    },
    hasSingleDisplayedInstallment(state, getters) {
      return (
        getters.installmentOptions.length === 1 &&
        !state.smartForm.splitPayment.showPayNowOption
      )
    },
    /**
     * Originally added in SplitPaymentButtonLabel in KJS-4366, moved
     * implementation here to easily compute a global text version for ARIA
     * compliance.
     *
     * @returns {string}
     * @since KJS-4814
     */
    splitBtnHtml(state, getters) {
      const { totalAmountLabel } = state
      const { hasInstallmentSelected, firstAmount, getAmountLabel, translate } =
        getters

      if (!hasInstallmentSelected) return ''

      const translation = translate('split_payment_button_label')
      const firstAmountLabel = getAmountLabel(firstAmount)

      return translation
        .replace(
          /(?:\[AMOUNT\]\s+)(.+)\s+\[TOTAL\]/,
          `[AMOUNT] <span class="kr-sp-button-total">$1 ${totalAmountLabel}</span>`
        )
        .replace(
          '[AMOUNT]',
          `<span class="kr-sp-button-amount">${firstAmountLabel}</span>`
        )
    },
    /**
     * Return splitBtnHtml innerText value in order to set button title for
     * ARIA compliance.
     *
     * @returns {string}
     * @since KJS-4814
     */
    splitBtnText(state, getters) {
      const { totalAmountLabel } = state
      const { hasInstallmentSelected, firstAmount, getAmountLabel, translate } =
        getters

      if (!hasInstallmentSelected) return ''

      const translation = translate('split_payment_button_label')
      const firstAmountLabel = getAmountLabel(firstAmount)

      return translation
        .replace('[AMOUNT]', firstAmountLabel)
        .replace('[TOTAL]', totalAmountLabel)
    }
  }
}
