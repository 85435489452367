import { mapGetters, mapState, mapActions } from 'vuex'

import tokens from '@/configuration/sources/Tokens.yml'
import Events from '@/configuration/Events'

export const PaymentButtonMixin = {
  inject: {
    context: {
      formId: null,
      namespace: null
    }
  },
  data() {
    return {
      discountUpdate: false
    }
  },
  computed: {
    ...mapGetters(['translate']),
    ...mapState([
      'amountLabel',
      'hasInterests',
      'language',
      'payBtnHTML',
      'redirecting',
      'theme',
      'dna'
    ]),
    ...mapState({
      config: ({ form }) => form.button,
      template: ({ button }) => button.template,
      label: ({ button }) => button.label,
      isAmountNull: ({ amount }) => amount === 0,
      noPayToken: ({ formToken }) =>
        !formToken ||
        formToken === tokens.noToken ||
        formToken === tokens.demoToken
    }),
    styles() {
      if (!this.theme.name) return {}
      return {
        color: this.isDisabled ? this.config.disabled.color : this.config.color,
        backgroundColor: this.isDisabled
          ? this.config.disabled.backgroundColor
          : this.config.backgroundColor,
        borderColor: this.isDisabled
          ? this.config.disabled.borderColor
          : this.config.borderColor
      }
    }
  },
  watch: {
    template: 'setPayBtnHTML',
    label: 'setPayBtnHTML',
    amountLabel: 'setPayBtnHTML',
    hasInterests: 'setPayBtnHTML',
    language: 'setPayBtnHTML',
    dna: {
      handler: 'setPayBtnHTML',
      deep: true
    }
  },
  created() {
    this.startCommonSubscriptions()
    this.setPayBtnHTML()
  },
  methods: {
    ...mapActions(['update']),
    tabReport(e) {
      e.preventDefault()
      if (e.type === 'keydown') {
        this.$bus.$emit(Events.krypton.field.event, {
          formId: this.context.formId,
          name: 'paymentButton',
          type: e.shiftKey ? 'reverseTab' : 'tab'
        })
      }
    },
    startCommonSubscriptions() {
      // Discount update animation class
      this.$busOn(Events.krypton.discount, async msg => {
        this.discountUpdate = false
        await this.$nextTick()
        this.discountUpdate = true
      })
    },
    setPayBtnHTML() {
      // Set the template at the initial point
      let label = this.label
      const isLabelSet = label?.trim()
      let template = this.template
      let displayAmount = true
      const paymentDoneLabel = this.translate('payment_accepted')

      // If there is a defined label, set it
      if (isLabelSet) {
        label = label.replace('%amount-and-currency%', '{price}').trim()
        template = template.replace('{price}', '')
      }

      // If no token / demo token / amount = 0
      if (this.noPayToken || this.isAmountNull) {
        displayAmount = false
        // If no label defined --> default without amount
        if (!isLabelSet) {
          label = this.translate(this.isAmountNull ? 'register' : 'pay')
          template = template.replace('{price}', '')
          // No token/demo token + %amount-and-currency% defined --> No template
          // %amount-and-currency% has been previously replace by {price}
        } else if (~label.indexOf('{price}')) {
          template = ''
        }
        // If token + undefined label --> default with amount
      } else if (!isLabelSet) {
        label = this.translate('pay_with_amount2')
      }

      // If there is no amount label
      if (
        !~label.indexOf('%amount-and-currency%') &&
        !~label.indexOf('{price}')
      ) {
        displayAmount = false
      }

      label = label.replace('%amount-and-currency%', '').trim()

      const payBtnHTML = `${template}`
        .replace('{label}', label)
        .replace('{paymentDone}', paymentDoneLabel)
        .replace('{price}', displayAmount ? this.amountLabel : '')
        .trim()

      this.update({ payBtnHTML })
    }
  }
}
