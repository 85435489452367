import Events from '@/configuration/Events'
import { getApplePayFormattedAmount } from '@/common/util/currencyFormatter'
import { CoBrands } from '@/configuration/sources/Brands.yml'
import {
  brandFormat,
  brandCorrespondence,
  allowedBrands,
  requiredContactFields
} from '@/configuration/sources/smartform/applePay.yml'

export default class ApplePayManager {
  constructor({ $store, $bus, proxy, storeFactory }) {
    this.$store = $store
    this.$bus = $bus
    this.proxy = proxy
    this.storeFactory = storeFactory
    this.paymentStatus = null
  }

  static canStartSession() {
    return typeof ApplePaySession === 'function'
  }

  /**
   * Initiates apple pay payment
   */
  initialize() {
    this.setupSession()
    this.setupListeners()
  }

  start() {
    this.paymentStatus = 'pending' // reset the payment status
    this.session.begin()
  }

  getSupportedNetworks(brands) {
    function transformToApplePayBrand(brand) {
      // LATAM brand variation to generic brand
      let formattedBrand = CoBrands.brands[brand] ?? brand

      // Specific changes of sub-brands must adapt to applePay supported brands
      formattedBrand = brandCorrespondence[formattedBrand] ?? formattedBrand

      // Adapt brand to the format used by ApplePay
      return brandFormat[formattedBrand]
    }
    const { brandSorter } = this.$store.getters
    const sortedAllowedBrands = [...allowedBrands]
      .sort(brandSorter)
      .map(transformToApplePayBrand)
      .filter(b => !!b)

    if (!brands?.length) return sortedAllowedBrands
    const applePayBrandsFromDna = brands
      .map(b => transformToApplePayBrand(b))
      .filter(b => !!b)

    return sortedAllowedBrands.filter(b => applePayBrandsFromDna.includes(b))
  }

  /**
   * Returns an array of required fields for the contact information based on the DNA
   */
  getRequiredContactConfig() {
    const { dna } = this.$store.state
    const isRequired =
      !!dna?.smartForm?.APPLE_PAY?.metadata?.walletShippingAddress

    // If not required, return an empty array
    if (!isRequired) return []
    // List of required fields
    return requiredContactFields
  }

  /**
   * Sets up the session using the API
   */
  setupSession() {
    const {
      amount,
      currency,
      language,
      country,
      shopName,
      smartForm: { dnaCardBrands }
    } = this.$store.state
    this.session = new ApplePaySession(3, {
      countryCode: country,
      currencyCode: currency,
      supportedNetworks: this.getSupportedNetworks(dnaCardBrands),
      merchantCapabilities: ['supports3DS'],
      total: {
        label: shopName,
        amount: getApplePayFormattedAmount(amount, currency, language)
      },
      requiredShippingContactFields: this.getRequiredContactConfig(),
      requiredBillingContactFields: this.getRequiredContactConfig()
    })
  }

  /**
   * Sets up the listeners for the whole payment workflow
   */
  setupListeners() {
    const { isNonSafariApplePayCompatible } = this.$store.getters

    this.onCancel()
    this.onValidate()
    this.onAuthorized()
    this.onError()
    // For the non-apple devices version
    isNonSafariApplePayCompatible()
      .then(isNotSafari => {
        if (isNotSafari) this.onModalClose()
      })
      .catch(console.error)
  }

  canBeAborted() {
    return (
      this.paymentStatus !== 'completed' && this.paymentStatus !== 'refused'
    )
  }

  cancelCallBack(event) {
    // Unless the cancel event is triggered by the payment being completed or refused, throw an error
    if (this.canBeAborted()) {
      const error = { errorCode: 'CLIENT_101', paymentMethod: 'APPLE_PAY' }
      this.$store.dispatch('error', error) // Throw an abort error
    }
    this.$store.dispatch('applePayRealModalClosed')
  }

  onCancel() {
    this.session.oncancel = this.cancelCallBack.bind(this)
  }

  // Non apple device modal with the QR code
  onModalClose() {
    // The applePay SDK (at least the beta) doesn't provide a event for the modal closing
    // We cant detect the user interaction as the modal closes automatically after a set timeout
    // Detect iframe element being removed from apple-pay-modal instead
    const iframeContainer = document
      .querySelector('apple-pay-modal')
      .shadowRoot.querySelector('.modal-content-container')

    const observer = new MutationObserver(mutationList => {
      // Check that a detected mutation has an IFRAME in the removedNodes list
      const isIframeRemoved = mutationList.some(e =>
        Array.from(e.removedNodes).some(e => e.tagName === 'IFRAME')
      )

      // Cancel callback if so
      if (isIframeRemoved) this.cancelCallBack()
    })

    // Observe iframeContainer and return events providing changed childLists
    observer.observe(iframeContainer, { childList: true })
  }

  onValidate() {
    this.session.onvalidatemerchant = ({ validationURL }) => {
      // Setup the applePay session creation listener
      this.$bus.$once(
        Events.krypton.message.applePayMerchantSession,
        ({ merchantSession }) => {
          this.session.completeMerchantValidation(merchantSession)
          this.$store.dispatch('applePayRealModalOpened')
        }
      )
      // Trigger the session creation
      this.proxy.send(
        this.storeFactory.create('getApplePayToken', {
          validationURL
        })
      )
    }
  }

  onAuthorized() {
    this.session.onpaymentauthorized = ({ payment }) => {
      // Listen to the payment event
      this.$bus.$once(
        [
          Events.krypton.message.payment,
          Events.krypton.message.applePayPaymentVerified
        ],
        msg => {
          // Trigger the successful payment on the API
          this.paymentStatus = 'completed'
          this.session.completePayment({
            status: ApplePaySession.STATUS_SUCCESS
          })
          this.$store.dispatch('applePayRealModalClosed')
        }
      )

      // Applepay payment will be triggered from the GetPayloadInfos or the extra fields modal
      this.proxy.send(
        this.storeFactory.create('getPayloadInfos', {
          payload: payment,
          payloadType: 'APPLE_PAY',
          testMode: false
        })
      )
    }
  }

  onError() {
    this.$bus.$once(Events.krypton.message.abortApplePaySession, () => {
      this.session.abort()
      this.$store.dispatch('applePayRealModalClosed')
    })
    this.$bus.$once(Events.krypton.message.applePayPaymentError, () => {
      this.paymentStatus = 'refused'
      this.session.completePayment({
        status: ApplePaySession.STATUS_FAILURE
      })
      this.$store.dispatch('applePayRealModalClosed')
    })
  }
}
