export default class ValidationManager {
  constructor({ $store, storeFactory, strategyDetector }) {
    this.storeFactory = storeFactory
    this.$store = $store
    this.strategyDetector = strategyDetector
  }

  isFieldVisible(formId, fieldName) {
    return this.$store.getters[`cardForm_${formId}/isFieldVisible`](fieldName)
  }

  validate(formId, fields, silent = false, extra = null) {
    let errors = []

    // Generate a validation context with all the values (strategies only) of all the fields
    for (const fieldName in fields) {
      if (!this.isFieldVisible(formId, fieldName)) continue
      const error = fields[fieldName].validate(silent, fields)
      if (error) {
        error.formId = formId
        this.$store.dispatch(`cardForm_${formId}/invalidateField`, fieldName)
        errors.push(error)
      }
    }

    // Extra fields validation: This fields are the customer own fields
    errors = this.validateExtras(formId, extra, errors)

    // Wallet mode with no card selected: Only throw this error
    errors = this.walletValidation(formId, errors)

    // More than 1 error, group them
    if (errors.length > 1)
      return { formId, errorCode: 'CLIENT_300', children: errors }

    // 1 error
    if (errors.length) return errors[0]
  }

  walletValidation(formId, errors) {
    const isWalletMyCards =
      this.$store.getters[`cardForm_${formId}/isWalletMyCards`]
    const { nonSensitiveValues } = this.$store.state[`cardForm_${formId}`]
    if (isWalletMyCards && !nonSensitiveValues.paymentMethodToken) {
      return [{ formId, errorCode: 'CLIENT_311' }]
    }

    return errors
  }

  validateExtras(formId, extra, errors) {
    if (!extra) return errors

    for (const name in extra) {
      if (!this.isFieldVisible(formId, name)) continue
      const { value, required } = extra[name]
      if (required && !value) {
        errors.push({ formId, errorCode: 'CLIENT_304', field: name })
      }
    }

    return errors
  }

  /**
   * Validation method used to only validate extrafields
   */
  extraFieldValidation() {
    const { state } = this.$store
    const formId = state.forms[state.activeForm]

    // Add minimal required info for field validation
    // Don't need any more as we will never validate a sensitive data field from here
    const fields = {}

    Object.keys(state.extrasForm.dna).forEach(fieldName => {
      const strategy = this.strategyDetector.get(fieldName, formId)
      const validate = () => strategy.validate()
      fields[fieldName] = { validate, formId }
    })

    const errors = this.validate(formId, fields)
    if (errors) throw errors
  }
}
