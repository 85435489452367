<template lang="pug">
.kr-popin-modal-header(
  kr-resource
  :class='{ "kr-secure-iframe-visible": redirectIframeOpen, "kr-long-shop-name": !singleLineShopName, "kr-smart-form-modal-header": isSmartForm }'
)
  .kr-popin-modal-header-background(
    v-show='!redirectIframeOpen',
    :style='{ backgroundColor: merchant.header.backgroundColor }'
  )
  .kr-popin-modal-header-background-image(
    v-show='!redirectIframeOpen',
    :style="{ background: popinHeaderBackground }"
  )
  .kr-popin-modal-header-image(v-show='hasHeaderImage')
    .kr-header-border-shadow
    .kr-header-border
    .kr-header-logo-shadow
    .kr-header-logo(:style='{ backgroundColor: merchant.header.image.backgroundColor }')
    img.kr-header-logo(
      :src='headerLogoSrc',
      :style='{ backgroundColor: merchant.header.image.backgroundColor }',
      aria-hidden='true'
    )
  i.fi-lock(v-show='redirectIframeOpen', :style="logoStyles",v-html='logoSVG')
  span.kr-popin-header(v-show='redirectIframeOpen', :style="labelStyles") {{ redirectIframeOpen ? translate(headerTransKey) : "" }}
  span.kr-popin-shop-name(
    v-show='!redirectIframeOpen && shopNameVisible',
    :style='{ background: popinLogoShadow, borderColor: shopNameBorderColor }'
  )
    span(:style='{ color: merchant.header.shopName.color }') {{ shopName }}
  template(v-if="!redirectIframeOpen && amountVisible && !isAmountNull")
    KryptonPopinHeaderLabel(:type="headerLabel")
  span.kr-popin-order-id(v-show='!redirectIframeOpen && orderIdVisible && !hasPartialPayments')
    span(:style='{ color: merchant.header.orderId.color }') {{ orderId }}
  CloseButton(:is-visible="hasCloseButton" v-on:close="onClose()")
</template>

<script>
import { defer } from 'underscore'
import Zepto from 'zepto-webpack'
import { mapActions, mapGetters, mapState } from 'vuex'
import Events from '@/configuration/Events'

/** MIXINS */
import PopinMixin from '@/common/components/mixins/styles/Popin'
import { EventsMixin } from '@/host/components/mixins/Events'
import { EscapeMixin } from '@/host/components/mixins/Escape'

/** COMPONENTS */
import CloseButton from '@/common/components/CloseButton'
import KryptonPopinHeaderLabel from '@/host/components/popin/KryptonPopinHeaderLabel'

export default {
  name: 'KryptonPopinHeader',
  components: { CloseButton, KryptonPopinHeaderLabel },
  mixins: [EventsMixin, EscapeMixin, PopinMixin],
  props: {
    isSmartForm: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      labels: {},
      singleLineShopName: true,
      hasCloseButton: true
    }
  },
  computed: {
    ...mapState([
      'redirectIframeOpen',
      'shopName',
      'orderId',
      'forcedFieldClear',
      'formMode',
      'hasIframeCloseButton',
      'redirecting',
      'redirectTransaction',
      'merchant',
      'processingPayment'
    ]),
    ...mapGetters([
      'isCardFormVisible',
      'isFormPopin',
      'translate',
      'isMobile',
      'isTablet',
      'paymentMethod',
      'hasDeadEndPaymentMethod',
      'isDeadEndPaymentMethod',
      'isExtrasFormVisible',
      'abortedErrorCode',
      'isSmartFormPopin',
      'isGridMode',
      'hasInstallmentSelected',
      'hasPartialPayments'
    ]),
    ...mapState({
      popinHeaderBackground: state => {
        return state.merchant.header.image.src &&
          state.merchant.header.image.type === 'background' &&
          state.merchant.header.image.visibility
          ? `url(${state.merchant.header.image.src}) center center / cover`
          : 'unset'
      },
      popinLogoShadow: state => {
        return state.merchant.header.shopName.gradient
          ? 'linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5))'
          : 'unset'
      },
      shopNameBorderColor: state => state.merchant.header.shopName.borderColor,
      shopNameVisible: state => state.merchant.header.shopName.visibility,
      orderIdVisible: state => state.merchant.header.orderId.visibility,
      amountVisible: state => state.merchant.header.amount.visibility,
      isAmountNull: ({ amount }) => amount === 0,
      headerLogoSrc: state => state.merchant.header.image.src,
      hasHeaderImage: state => {
        return (
          state.merchant.header.image.visibility &&
          state.merchant.header.image.type == 'logo' &&
          !state.redirectIframeOpen
        )
      },
      activeMethod: state => state.smartForm.activeMethod
    }),
    headerLabel() {
      if (this.hasPartialPayments) return 'partial'
      if (this.hasInstallmentSelected) return 'split'
      return 'default'
    },
    headerTransKey() {
      return (
        'header_message' + (this.isMobile || this.isTablet ? '_mobile_1' : '')
      )
    }
  },
  watch: {
    forcedFieldClear(newVal) {
      /**
       * When I receive the fieldClear from the slaves, I can put the ghost
       * iframe back to it's original place. Needs to be here because the ghost is
       * reloaded when the popin is closed
       */
      if (newVal) this.clearPermanentFocusAllFields()
    },
    redirecting(newVal) {
      if (!this.redirectTransaction) this.hasCloseButton = !newVal
    },
    redirectTransaction(newVal) {
      if (newVal) this.hasCloseButton = true
    },
    processingPayment(newVal) {
      this.hasCloseButton = !newVal
    }
  },
  created() {
    // Start subscriptions
    this.$busOn(Events.krypton.popin.close, () => this.onClose())
    this.$busOn(Events.krypton.message.popinLoad, () => this.onLoad())

    // Listen to store changes
    this.$store.subscribe(({ payload }) => {
      if (payload?.hasOwnProperty('shopName')) {
        defer(this.calculateShopNameHeight)
      }
    })
  },
  methods: {
    ...mapActions([
      'finishRedirection',
      'error',
      'closePopin',
      'forceFieldClear',
      'closeMethod',
      'hideExtrasForm'
    ]),
    onClose() {
      this.$emit('close')
    },
    onLoad() {
      this.hasCloseButton = this.hasIframeCloseButton
    },
    calculateShopNameHeight() {
      const shopNameHeight = Zepto(this.$el)
        .find('.kr-popin-shop-name span')
        .height()
      let shopNameLineHeight = Zepto(this.$el)
        .find('.kr-popin-shop-name span')
        .css('line-height')

      if (!shopNameLineHeight) return
      shopNameLineHeight = parseInt(shopNameLineHeight.replace('px', ''))

      this.singleLineShopName = shopNameHeight === shopNameLineHeight
    }
  }
}
</script>
