<template lang="pug">  
  svg.kr-split-payment-circle-graph(xmlns='http://www.w3.org/2000/svg', viewBox='0 0 100 100', fill='none', :style="dynamicStyles")
    circle(cx='50%', cy='50%', r='48', stroke='var(--circle-graph-color)', stroke-width='4')
    //- 100%
    circle(v-if="percentage === 100", cx="50%", cy="50%", :r="chartRadius", fill="var(--circle-graph-color)")
    //- 25% | 50% | 75%
    path(v-else-if="hasPreformattedPath", fill="var(--circle-graph-color)", :d="preformattedPath[percentage]")
    //- * > 0%
    g(v-else-if="percentage > 0" transform-origin="center" transform="rotate(-90)")
      circle(cx='50%', cy='50%', :r="strokeRadius", stroke='var(--circle-graph-color)', :stroke-width="chartRadius", :stroke-dasharray="dasharray")
</template>

<script>
/**
 * Added to display a user-friendly indicator of the percentage to pay.
 *
 * In order to optimize visually while avoiding unnecessary calculations the
 * 4 following cases are covered:
 * - 100% -> display a full disc
 * - 25%, 50%, 75% -> a preformatted path is rendered
 * - Any other non zero value -> a circle with a stroke-dasharray is calculated
 *   based on a percatage of the full arc radius.
 * - 0% Nothing displayed
 *
 * @since KJS-4361
 */
export default {
  name: 'SplitPaymentCircleGraph',
  props: {
    color: {
      type: String,
      default: '#130E37'
    },
    percentage: {
      type: Number,
      default: 100,
      validator: value => value >= 0 && value <= 100
    }
  },
  data() {
    return {
      /**
       * Pie Chart radius WITHIN the viewBox, this is not a radius in px.
       */
      chartRadius: 40,
      preformattedPath: {
        25: 'M90.5 50C90.5 44.7471 89.4654 39.5457 87.4552 34.6927C85.445 29.8396 82.4986 25.4301 78.7843 21.7157C75.0699 18.0014 70.6604 15.055 65.8073 13.0448C60.9543 11.0346 55.7529 10 50.5 10V50H90.5Z',
        50: 'M50.5 90C55.7529 90 60.9543 88.9654 65.8073 86.9552C70.6604 84.945 75.0699 81.9986 78.7843 78.2843C82.4986 74.5699 85.445 70.1604 87.4552 65.3073C89.4654 60.4543 90.5 55.2529 90.5 50C90.5 44.7471 89.4654 39.5457 87.4552 34.6927C85.445 29.8396 82.4986 25.4301 78.7843 21.7157C75.0699 18.0014 70.6604 15.055 65.8073 13.0448C60.9543 11.0346 55.7529 10 50.5 10L50.5 50V90Z',
        75: 'M10.5 50C10.5 57.9112 12.846 65.6448 17.2412 72.2228C21.6365 78.8008 27.8836 83.9277 35.1927 86.9552C42.5017 89.9827 50.5444 90.7748 58.3036 89.2314C66.0628 87.688 73.1902 83.8784 78.7843 78.2843C84.3784 72.6902 88.188 65.5629 89.7314 57.8036C91.2748 50.0444 90.4827 42.0017 87.4552 34.6927C84.4277 27.3836 79.3008 21.1365 72.7228 16.7412C66.1448 12.346 58.4113 10 50.5 10L50.5 50L10.5 50Z'
      }
    }
  },
  computed: {
    dynamicStyles() {
      return {
        '--circle-graph-color': `var(--kr-sp-circle-color, ${this.color})`
      }
    },
    hasPreformattedPath() {
      return this.percentage in this.preformattedPath
    },
    strokeRadius() {
      return this.chartRadius / 2
    },
    /**
     * Using stroke-dasharray property, to display 10% we need to calculate
     * 10% of 2π to get the right angle in radian then multiply by the radius
     * to calculate the arc length.
     * Then repeat for the remaining portion (90%) for the blank part.
     *
     * This should not be called for 0%, 25%, 50%, 75% & 100% which have a
     * precalculated rendered path/circle.
     */
    dasharray() {
      const C = 2 * Math.PI * this.strokeRadius
      const arcLength = this.round((this.percentage * C) / 100)
      const arcLengthRemaining = this.round(((100 - this.percentage) * C) / 100)
      return `${arcLength} ${arcLengthRemaining}`
    }
  },
  methods: {
    /**
     * Round with 2 decimals
     *
     * @param {number} n
     */
    round(n) {
      return Math.floor((n + Number.EPSILON) * 100) / 100
    }
  }
}
</script>
