var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(_vm.component, {
    tag: "component",
    staticClass: "kr-button-animation",
    class: _vm.dynamicClasses,
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }