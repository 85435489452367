<template lang="pug">
  label(v-show="!hasInstallmentSelected") {{ label | capitalize | colon(language) }}
</template>

<script>
import { mapGetters, mapState } from 'vuex'

/**
 * Label "Pay with card" to show when no option with installment is selected.
 *
 * @since KJS-4368
 */
export default {
  name: 'SplitPaymentCardLabel',
  computed: {
    ...mapGetters(['hasInstallmentSelected', 'translate']),
    ...mapState(['language']),
    label() {
      return this.translate('split_payment_panel_separator')
    }
  }
}
</script>
