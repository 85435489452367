<template lang="pug">
  section.kr-sf-opm
    label.kr-sf-opm__label(v-show="showOpmLabel") {{ label | capitalize | colon }}
    .kr-sf-opm__methods
      SmartFormGridOpmGroup(
        type="main",
        :class="dynamicClasses('main')",
        :methods="mainPaymentMethods",
        v-on:selected="onSelected",
        :locked="allLocked"
      )
      SmartFormGridOpmGroup(
        type="group",
        :group="group",
        :class="dynamicClasses('group')",
        :methods="groupPaymentMethods",
        v-on:selected="onSelected",
        :locked="allLocked"
        @back="goBack()"
      )
    SmartFormGridError
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import SmartFormGridOpmGroup from '@/host/components/smartform/grid/opm/Group'
import SmartFormGridError from '@/host/components/smartform/grid/Error'

export default {
  name: 'SmartFormGridOtherMethods',
  components: {
    SmartFormGridOpmGroup,
    SmartFormGridError
  },
  data() {
    return {
      group: ''
    }
  },
  computed: {
    ...mapGetters([
      'translate',
      'hasSeveralPaymentMethods',
      'isWalletSmartForm',
      'hasTokens',
      'hasAnyActiveMethod',
      'hasCardFormExpandedInside',
      'hasOtherPaymentMethods',
      'showOpmLabel'
    ]),
    ...mapGetters({
      paymentMethodGroups: 'effectivePaymentMethodGroupList'
    }),
    ...mapState({
      currentView: state =>
        state.smartForm.otherPaymentMethods.navigation.currentView,
      views: state => state.smartForm.otherPaymentMethods.navigation.views,
      history: state => state.smartForm.otherPaymentMethods.navigation.history
    }),
    ...mapState({
      otherMethodsLabel: ({ smartForm }) =>
        smartForm.labels.otherPaymentMethods,
      registerMode: state => state.amount === 0,
      paymentDone: ({ smartForm }) => smartForm.paymentDone
    }),
    mainPaymentMethods() {
      let methods = []
      // Non-grouped methods
      methods = methods.concat(
        this.paymentMethodGroups.single
          .filter(method => {
            return method !== 'CARDS'
          })
          .map(method => {
            return {
              type: 'method',
              key: method
            }
          })
      )
      // Groups
      for (const group in this.paymentMethodGroups) {
        if (group !== 'single' && this.paymentMethodGroups[group].length > 0)
          methods.push({
            type: 'group',
            key: group
          })
      }

      return methods
    },
    groupPaymentMethods() {
      if (this.group) {
        return this.paymentMethodGroups[this.group].map(method => {
          return {
            type: 'method',
            key: method
          }
        })
      }
      return []
    },
    activeLevel() {
      return this.views.indexOf(this.currentView)
    },
    walletMode() {
      return this.isWalletSmartForm && this.hasTokens
    },
    label() {
      if (this.registerMode) {
        return this.translate('smartform_register_another_payment_method')
      }

      // Customized label
      if (this.otherMethodsLabel) return `${this.otherMethodsLabel}:`
      return this.translate(
        this.hasSeveralPaymentMethods
          ? 'smartform_other_payment_methods'
          : 'smartform_other_payment_method'
      )
    },
    allLocked() {
      return this.paymentDone || this.hasAnyActiveMethod
    },
    dynamicClasses() {
      return view => ({
        [`kr-opm-group--level-${this.activeLevel}`]: true,
        'kr-opm-group--active': this.currentView === view
      })
    }
  },
  methods: {
    ...mapActions(['opmNavigate', 'cleanError']),
    onSelected(method) {
      if (this.isGroup(method)) {
        this.cleanError()
        this.group = method.key
        this.opmNavigate(method.type)
        return
      }
      this.$emit('selected', method.key)
    },
    isGroup(method) {
      return method.type === 'group'
    },
    goBack() {
      this.cleanError()
      this.opmNavigate('main')
    }
  }
}
</script>
