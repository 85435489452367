import { HSLColor } from '@/common/model/color/HSLColor'

export default {
  '--kr-global-color-primary': [
    {
      variable: '--kr-global-color-primaryLight',
      factory(hex) {
        const hsl = HSLColor.fromHex(hex)
        hsl.lighten(90)
        return hsl.toString()
      }
    }
  ]
}
