<template lang="pug">
.kr-simple-modal-wrapper(v-show="show" :class="dynamicClasses.wrapper" ref="content")
  .kr-simple-modal
    header.kr-simple-modal-header
      BrandIcon(v-if="modalData.method" :method="modalData.method")
      h2.kr-simple-modal-header-title(v-html="computedTitle")
      CloseButton(:is-visible="true" v-on:close="onAction('closeModal', 'close')")
    main.kr-simple-modal-content
      section.kr-simple-modal-content-main
        p(v-for="item in content" v-html="contentHtml(item)")
      section.kr-simple-modal-content-optional(v-if="hasOptionalContent")
        .kr-simple-modal-content-optional-item(v-for="item in optionalContent")
          span(v-html="optionalLabelHtml(item)")
          a(v-if="item.link", :href="optionalLinkHref(item)" v-html="optionalLinkHtml(item)")
    footer.kr-simple-modal-footer(:class="dynamicClasses.verticalButtons")
      SimpleModalButton(
        v-for="button in buttons"
        :key="button.name"
        :config="button"
        :loading="loading"
        @click="onAction(button.action, button.name, button.loading)"
      )
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import DetachableMixin from '@/host/components/mixins/Detachable'
import AnimatedModalMixin from '@/host/components/mixins/AnimatedModal'
import BrandIcon from '@/host/components/controls/BrandIcon'
import CloseButton from '@/common/components/CloseButton'
import SimpleModalButton from '@/host/components/controls/SimpleModalButton'

export default {
  name: 'SimpleModal',
  components: { BrandIcon, CloseButton, SimpleModalButton },
  mixins: [DetachableMixin, AnimatedModalMixin],
  computed: {
    ...mapState(['selectedBrand']),
    ...mapState({
      title: state => state.modal.layout.title,
      variables: state => state.modal.layout.variables,
      content: state => state.modal.layout.content,
      optionalContent: state => state.modal.layout.optional,
      buttons: state => state.modal.layout.buttons,
      modalData: state => state.modal.data,
      loading: state => state.modal.loading
    }),
    ...mapGetters(['translate', 'getLayoutVariable', 'verticalModalButtons']),
    hasOptionalContent() {
      return this.optionalContent?.length > 0
    },
    dynamicClasses() {
      return {
        wrapper: {
          'kr-simple-modal-wrapper--opening': this.animation.open.active,
          'kr-simple-modal-wrapper--closing': this.animation.close.active
        },
        verticalButtons: {
          'kr-simple-modal-footer--vertical': this.verticalModalButtons
        }
      }
    },
    computedTitle() {
      const base = this.translate(this.title)
      return this.resolveVariables(this.decorateVariables(base), this.variables)
    }
  },
  created() {
    this.registerEventListeners()
  },
  methods: {
    ...mapActions(['closeModal', 'startModalLoading', 'callModalActions']),
    registerEventListeners() {
      const escapeHandler = e => {
        if ((e.key === `Escape` || e.key === 'Esc') && this.isOpen) {
          onAction('closeModal', 'close')
        }
      }

      document.addEventListener('keyup', escapeHandler)
      this.$once('hook:destroyed', () => {
        document.removeEventListener('keyup', escapeHandler)
      })
    },
    /**
     * Added to wrap `[ORDERID]` by a identifiable span without impacting
     * translations (avoid mix or HTML and i18n strings).
     * Originally added to hide it in e2e tests.
     * Could be generalized later on.
     *
     * @param {string} base
     * @returns {string}
     * @since KJS-4324
     */
    decorateVariables(base) {
      return base.replace(
        /(\[ORDERID\])/,
        '<span class="kr-simple-modal-order-id">$1</span>'
      )
    },
    resolveVariables(base, variables) {
      if (variables) {
        for (const key in variables) {
          const variable = variables[key]
          const value = this.getLayoutVariable(variable, this.modalData)
          base = base.replace(`[${key}]`, value)
        }
      }
      return base
    },
    contentHtml(content) {
      const base = this.translate(content.label)
      return this.resolveVariables(base, content.variables)
    },
    optionalLabelHtml(optional) {
      const base = this.translate(optional.label)
      return this.resolveVariables(base, optional.variables)
    },
    optionalLinkHtml(optional) {
      const base = this.translate(optional.link.label)
      return this.resolveVariables(base, optional.variables)
    },
    optionalLinkHref(optional) {
      let base = optional.link.href
      const matches = base.match(/{{{0,2}\s*([^\s{}]+)\s*}}{0,2}/g)
      if (matches) {
        for (const match of matches) {
          const transKey = match.replace('{{', '').replace('}}', '')
          // Get the translation key and format spaces and new lines
          const translation = this.translate(transKey)
            .replace(/ /g, '%20')
            .replace(/\n/g, '%0D%0A')
          base = base.replace(match, translation)
        }
      }

      return this.resolveVariables(base, optional.variables)
    },
    onAction(actions, name = undefined, loading = false) {
      if (this.isOpen && this.loading) return

      if (loading) this.startModalLoading()

      this.callModalActions({ actions, name })
    }
  }
}
</script>
