<template lang="pug">
  .kr-sp-panel-option(:class="dynamicClasses")
    SplitPaymentPanelOptionActivator(
      v-on:clicked="onActivated()",
      :selected="selected",
      :label="label"
    )
    .kr-sp-panel-option__content(v-if="hasContent", ref="content", :class="contentClasses")
      slot(name="content")
</template>

<script>
import SplitPaymentPanelOptionActivator from '@/host/components/smartform/splitPayment/PanelOptionActivator'

/**
 * Display two things:
 *  #1 (always) The activator clickable by user.
 *  #2 (only if provided) a named slot: this is supposed to be the Timeline
 *  when and option content scheduled payments unlike the 'Pay now' option.
 * @since KJS-4363
 */
export default {
  name: 'SplitPaymentPanelOption',
  components: {
    SplitPaymentPanelOptionActivator
  },
  props: {
    selected: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: true
    }
  },
  computed: {
    hasContent() {
      return !!this.$scopedSlots.content
    },
    dynamicClasses() {
      return {
        'kr-sp-panel-option--selected': this.selected
      }
    },
    /**
     * Let CSS handle the content visibility to enable transitions.
     *
     * @since KJS-4466
     */
    contentClasses() {
      return {
        'kr-sp-panel-option__content--open': this.selected
      }
    }
  },
  mounted() {
    this.setContentHeight()
  },
  methods: {
    onActivated() {
      this.setContentHeight()
      this.$emit('selected')
    },
    /**
     * Added to determine the effective content height in order to set the right
     * max-height in the CSS transition.
     *
     * @since KJS-4466
     */
    setContentHeight() {
      if (!this.hasContent) return

      const $content = this.$refs.content
      const height = $content.firstChild.offsetHeight
      $content.style.setProperty('--kr-sp-opt-content-height', `${height}px`)
    }
  }
}
</script>
