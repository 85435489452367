<template lang="pug">
  span.kr-sp-button-label(v-html="splitBtnHtml")
    //- Render as:
    //- span.kr-sp-button-amount Pay {{ <formattedAmount> }}
    //- out of
    //- span.kr-sp-button-total {{ <formattedTotal> }}
</template>

<script>
import { mapGetters } from 'vuex'

/**
 * Display formatted button label in PaymentButton.
 *
 * @since KJS-4366
 * @see KJS-4814 | Move content implementation to global getters
 */
export default {
  name: 'SplitPaymentButtonLabel',
  computed: {
    ...mapGetters(['splitBtnHtml'])
  }
}
</script>
