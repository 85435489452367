import paymentMethodsConf from '@/configuration/sources/smartform/paymentMethodsConf.yml'
import { logSentryException } from '@/common/util/sentry'
import SamsungPayManager from '@/ghost/service/samsungPay/SamsungPayManager'
import { getAllowedBrands } from '@/ghost/service/samsungPay/SamsungPayData'

export const getSamsungPayState = () => {
  return {
    samsungPay: {
      ready: false,
      testMode: false,
      simulator: {
        open: false
      }
    }
  }
}

export const samsungPayActions = ($locator, app) => {
  return {
    async setupSamsungPayHost({
      state: { isUnitaryTest },
      getters,
      dispatch,
      commit
    }) {
      if (!getters.isSamsungPayCompatible) {
        dispatch('removePaymentMethod', 'SAMSUNG_PAY')
        dispatch('removeDnaPaymentMethod', 'SAMSUNG_PAY')
        return
      }
      if (!getters.isSamsungPaySimulator) {
        const { allReadyQueue, storeFactory } = $locator
        // Need to setup samsungPay from the ghost
        allReadyQueue.send(storeFactory.create('setupSamsungPaySDK'))
        return
      }
      if (isUnitaryTest) return
      const module = await import(
        /* webpackChunkName: "kr-simulator-samsung-pay" */ '@/simulator/samsungpay/Loader'
      )
      const loader = module.default
      loader($locator)
      commit('UPDATE', { samsungPay: { ready: true, testMode: true } })
    },
    // Called from ghost
    async setupSamsungPaySDK({
      state: {
        testKeys,
        isUnitaryTest,
        dna,
        smartForm: { dnaCardBrands }
      },
      getters,
      commit,
      dispatch
    }) {
      if (getters.isSamsungPaySimulator || isUnitaryTest) return

      await $locator.scriptLoader.loadScriptPromise(
        paymentMethodsConf.sources.SAMSUNG_PAY,
        'kr-samsung-pay'
      )

      const { serviceId } = dna.smartForm?.SAMSUNG_PAY?.metadata || {}
      const isReadyResult = await SamsungPayManager.isReadyToPayCall({
        testMode: testKeys,
        dnaBrands: dnaCardBrands,
        samsungPayServiceId: serviceId
      })?.catch(error => ({ error, result: false }))

      // samsung api sets the result to true
      if (!isReadyResult?.result) {
        dispatch('removePaymentMethod', 'SAMSUNG_PAY')
        dispatch('removeDnaPaymentMethod', 'SAMSUNG_PAY')
        console.error(
          'Samsung Pay is not displayed because the browser or payment information does not allow it: isReadyToPay returns ' +
            JSON.stringify(isReadyResult.error)
        )
        logSentryException(isReadyResult.error, 'store/modules/samsungPay')
        return
      }

      commit('UPDATE', { samsungPay: { ready: true, testMode: testKeys } })
    },

    async startSamsungPayPayment({ state, dispatch, getters }) {
      if (!state.samsungPay.ready) {
        dispatch('closeSmartFormPopin')
        return
      }

      dispatch('cleanError')

      const { gatewayMerchantId, serviceId } =
        state.dna.smartForm?.SAMSUNG_PAY?.metadata || {}
      const { domain, protocol } = state.merchant
      const merchantOrigin = `${protocol}//${domain}`

      $locator.samsungPayManager.startPayment({
        orderNumber: getters.ecsOrderId,
        merchantName: state.dna.shopName,
        merchantUrl: merchantOrigin,
        merchantId: gatewayMerchantId,
        countryCode: state.country,
        amount: state.amount,
        currency: state.currency,
        dnaBrands: state.smartForm.dnaCardBrands,
        samsungPayServiceId: serviceId,
        testMode: state.samsungPay.testMode,
        testModeWithoutSimulator: getters.samsungPayTestModeWithoutSimulator
      })
    },
    openSamsungPaySimulator({ commit }) {
      commit('UPDATE', {
        samsungPay: { simulator: { open: true } }
      })
    },
    closeSamsungPaySimulator({ commit }) {
      commit('UPDATE', {
        samsungPay: { simulator: { open: false } }
      })
    }
  }
}
export const googlePayMutations = {}
export const samsungPayGetters = {
  samsungPayTestModeWithoutSimulator: (
    {},
    { googlePayTestModeWithoutSimulator }
  ) => {
    return googlePayTestModeWithoutSimulator
  },
  isSamsungPaySimulator: (
    { testKeys },
    { samsungPayTestModeWithoutSimulator }
  ) => {
    return testKeys && !samsungPayTestModeWithoutSimulator
  },
  isSamsungPaySimulatorOpen: ({ samsungPay: { simulator } }) => simulator.open,
  isSamsungPayCompatible: ({ smartForm: { dnaCardBrands } }) => {
    const allowedCardBrands = getAllowedBrands(dnaCardBrands)
    return !!allowedCardBrands.length
  },
  isSamsungPayActive: (_state, { isActiveMethod }) =>
    isActiveMethod('SAMSUNG_PAY')
}
