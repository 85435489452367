<template lang="pug">
PaymentButton.kr-payment-button(
  kr-resource
  type="button"
  v-show="isVisible"
  :class="dynamicClasses"
  :style="styles"
  :disabled="isDisabled"
  @click="btnClick"
  @keyup.9="tabReport"
  @keydown.9="tabReport"
  tabindex=1
)
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import PaymentButton from '@/host/components/controls/PaymentButton'
import { FormPaymentButtonMixin } from '@/host/components/mixins/FormPaymentButton'

/**
 * @see KJS-4716 | Replace html button by PaymentButton
 */
export default {
  name: 'ExtrasPaymentButton',
  components: { PaymentButton },
  mixins: [FormPaymentButtonMixin],
  computed: {
    ...mapGetters(['isExtrasFormVisible']),
    ...mapState({
      activeMethod: state => state.smartForm.activeMethod
    }),
    isLoading() {
      return (
        !this.hasError &&
        (this.processingPayment === true || this.spinnerVisible) &&
        this.isExtrasFormVisible
      )
    }
  },
  methods: {
    btnClick(e) {
      e.preventDefault()
      if (!this.isDisabled) {
        this.disableButton()
        this.cleanError()
        let paymentStore
        //payment token has been passed before in the getPayloadInfosTrigger
        switch (this.activeMethod) {
          case 'SAMSUNG_PAY':
            paymentStore = this.$storeFactory.create('samsungPayPayment', {
              paymentToken: {}
            })
            break
          case 'GOOGLEPAY':
          case 'APPLE_PAY':
            paymentStore = this.$storeFactory.create(
              'appleOrGooglePayPayment',
              {
                paymentToken: {},
                payloadType: this.activeMethod
              }
            )
            break
        }
        this.$proxy.send(paymentStore)
        this.focusHelper()
      }
    }
  }
}
</script>
