import { render, staticRenderFns } from "./CardLabel.vue?vue&type=template&id=11718e6e&lang=pug&"
import script from "./CardLabel.vue?vue&type=script&lang=js&"
export * from "./CardLabel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/VAD_KJS_build_hotfix/proxy-purebilling/krypton-js-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('11718e6e')) {
      api.createRecord('11718e6e', component.options)
    } else {
      api.reload('11718e6e', component.options)
    }
    module.hot.accept("./CardLabel.vue?vue&type=template&id=11718e6e&lang=pug&", function () {
      api.rerender('11718e6e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/host/components/smartform/splitPayment/CardLabel.vue"
export default component.exports