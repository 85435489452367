<template lang="pug">
  span.kr-popin-amount
    span(:style='{ color: merchant.header.amount.color }') {{ totalAmountLabel }}
</template>

<script>
import { mapState } from 'vuex'

/**
 * Moved from KryptonPopinHeader to a separate component to allow dynamic
 * selection of component based on context.
 *
 * @since KJS-4366
 */
export default {
  name: 'KryptonPopinHeaderLabelDefault',
  computed: {
    ...mapState(['merchant', 'totalAmountLabel'])
  }
}
</script>
