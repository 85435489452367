<template lang="pug">
  .kr-modal(:class="dynamicClasses")
    KryptonPopinHeader(v-on:close="closeModal", :is-smart-form="true")
    SmartFormGridModalContent(:active-view="currentView", :style="dynamicStyles.modal")
      template(v-slot:full="")
        label.kr-modal-view-label(v-if="hasLabel && !hasPartialPayments") {{ label | capitalize | colon }}
        .kr-modal-view-content(v-if="open")
          PartialPaymentPanel
          SmartFormGridWallet(v-if="hasWallet", v-on:selected="openMethod")
          SmartFormGridCards(v-if="hasCards", v-on:selected="openMethod", :only-button="true")
          SmartFormGridModalOpmMain(v-if="hasOtherMethods", v-on:selected="openMethod")
      template(v-slot:group="")
        SmartFormModalBackButton(v-if="isSmartFormPopin", :visible="true")
        .kr-modal-view-content(v-if="open")
          SmartFormGridModalOpmGroup(:group="activeGroup", v-on:selected="openMethod")
      template(v-slot:content="")
        SmartFormModalBackButton(v-if="isSmartFormPopin", :visible="true")
        .kr-modal-view-content(:class="{ 'kr-modal-view-content--no-button': !isSmartFormPopin}")
          slot(name="cardForm", v-if="!renderEmbeddedForm")
      template(v-slot:redirection="")
        SmartFormModalBackButton(v-if="!isCardMethodActive && isSmartFormPopin", :visible="true")
        .kr-modal-content__redirection(v-show="redirectIframeOpen", :style="dynamicStyles.container")
          KryptonGhostContainer
      template(v-slot:extra="")
        .kr-modal-view-content
          slot(name="extra")
      template(v-slot:extras="")
        ExtrasForm(v-if="isSmartFormPopin", display-mode="embedded")
    .kr-modal__error(v-if="hasFormError")
      SmartFormError
    KryptonPopinFooter
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import KryptonPopinHeader from '@/host/components/popin/KryptonPopinHeader'
import KryptonPopinFooter from '@/host/components/popin/KryptonPopinFooter'
import SmartFormGridModalContent from '@/host/components/smartform/grid/modal/Content'
import SmartFormGridWallet from '@/host/components/smartform/grid/Wallet'
import SmartFormGridCards from '@/host/components/smartform/grid/Cards'
import SmartFormGridModalOpmMain from '@/host/components/smartform/grid/modal/opm/Main'
import SmartFormGridModalOpmGroup from '@/host/components/smartform/grid/modal/opm/Group'
import SmartFormModalBackButton from '@/host/components/smartform/ModalBackButton'
import KryptonGhostContainer from '@/host/components/KryptonGhostContainer'
import ExtrasForm from '@/host/components/extrasform/ExtrasForm'
import SmartFormError from '@/host/components/smartform/Error'
import PartialPaymentPanel from '@/host/components/smartform/partialPayment/PartialPaymentPanel'

import { PopinSizeMixin } from '@/host/components/mixins/PopinSize'

export default {
  name: 'SmartFormGridModal',
  components: {
    KryptonPopinHeader,
    KryptonPopinFooter,
    SmartFormGridModalContent,
    SmartFormGridWallet,
    SmartFormGridCards,
    SmartFormGridModalOpmMain,
    SmartFormGridModalOpmGroup,
    SmartFormModalBackButton,
    KryptonGhostContainer,
    ExtrasForm,
    SmartFormError,
    PartialPaymentPanel
  },
  mixins: [PopinSizeMixin],
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpen: false
    }
  },
  computed: {
    ...mapGetters([
      'translate',
      'isCardMethodActive',
      'isSmallScreen',
      'requireFullScreenRedirect',
      'isWalletSmartForm',
      'hasTokens',
      'hasAnyCardBrand',
      'hasSeveralPaymentMethods',
      'isSmartFormPopin',
      'outsideCardsForm',
      'cardsFormExpanded',
      'isSmartFormForcedToken',
      'renderEmbeddedForm',
      'hasCardMethodOption',
      'hasPartialPayments'
    ]),
    ...mapState(['redirectIframeOpen']),
    ...mapState({
      currentView: state => state.smartForm.navigation.currentView,
      registerMode: state => state.amount === 0,
      otherMethodsLabel: state => state.smartForm.labels.otherPaymentMethods,
      activeGroup: state => state.smartForm.activeGroup || ''
    }),
    dynamicStyles() {
      if (!this.redirectIframeOpen) return {}
      return {
        modal: {
          width: `${this.popinSize.width}px`
        },
        container: { width: '100%', height: '100%' }
      }
    },
    dynamicClasses() {
      return {
        'kr-modal--open': this.isOpen,
        'kr-modal--iframe-visible': this.redirectIframeOpen,
        'kr-modal--mobile': this.isSmallScreen,
        'kr-modal--fullscreen':
          this.redirectIframeOpen && this.requireFullScreenRedirect
      }
    },
    hasWallet() {
      return this.isWalletSmartForm && this.hasTokens
    },
    hasCards() {
      return this.hasAnyCardBrand && this.hasCardMethodOption
    },
    hasOtherMethods() {
      return this.hasSeveralPaymentMethods
    },
    hasLabel() {
      return !this.registerMode && !this.hasWallet
    },
    label() {
      // Customized label
      if (this.otherMethodsLabel) return `${this.otherMethodsLabel}:`
      // Default translation
      return this.translate('smartform_list_header')
    },
    hasFormError() {
      return this.currentView !== 'content' && this.currentView !== 'extras'
    }
  },
  watch: {
    open() {
      setTimeout(() => {
        if (this.open) this.isOpen = this.open
      }, 0)
    }
  },
  methods: {
    openMethod(method) {
      this.$emit('selected', method)
    },
    closeModal() {
      this.isOpen = false
      setTimeout(() => {
        if (!this.isOpen) {
          this.$emit('close')
        }
      }, 100)
    }
  }
}
</script>
