import { isBoolean } from 'underscore'
import Events from '@/configuration/Events'
import { hasTransaction, getTransaction } from '@/common/util/error'
import walletModes from '@/configuration/sources/WalletModes.yml'

/**
 * Called in HostApp created hook
 *
 * @since KJS-305
 * @see   KJS-1945  onBlur handling
 */
export const eventSubscribe = ({ $bus, $store, callbackHandler }, KR) => {
  // focus
  $bus.$on(Events.krypton.message.fieldEvent, ({ type, name, formId }) => {
    if (type === 'triggerFocusActions') {
      callbackHandler.runCallbacks(
        'onFocus',
        {
          field: name,
          _type: 'krypton/focus'
        },
        formId
      )
    } else if (type === 'blur') {
      callbackHandler.runCallbacks(
        'onBlur',
        {
          field: name,
          _type: 'krypton/blur'
        },
        formId
      )
    }
  })

  // formCreated
  $bus.$on(Events.krypton.form.ready, () => {
    callbackHandler.runCallbacks('onFormCreated')
  })

  // formReady
  $bus.$on(Events.krypton.sync.ready, () => {
    callbackHandler.runCallbacks('onFormReady')
  })

  // loaded
  $bus.$on(Events.krypton.form.loaded, () => {
    callbackHandler.runCallbacks('onLoaded')
  })

  // log
  $bus.$on(Events.krypton.log, msg => {
    callbackHandler.runCallbacks('onLog', msg)
  })

  // Reset the brand info when the form is removed
  $bus.$on(Events.krypton.destroy, () => {
    KR._resetKR(false)
    $store.dispatch('resetForms')
    $store.dispatch('reset')
  })

  // Installment Change
  $bus.$on(Events.krypton.installments.change, (formId, installmentInfo) => {
    callbackHandler.runCallbacks('onInstallmentChanged', {
      KR,
      installmentInfo,
      formId
    })
  })

  // SmartForm
  $bus.$on(Events.krypton.smartform.paymentMethodSelected, method => {
    callbackHandler.runCallbacks('onPaymentMethodSelected', {
      KR,
      paymentMethodInfo: {
        name: method
      }
    })
  })

  // Partial payment
  $bus.$on(Events.krypton.message.billingPartialPayment, async store => {
    try {
      await $store.dispatch('intercept', {
        name: 'onTransactionCreated',
        args: [store, $store.getters.activeFormId]
      })
    } catch (error) {
      // If the onTransactionCreated returns false or returns a rejected promise we throw an error
      // This is used on src/host/components/mixins/Payment.js to stop the payment, lock the form and avoid the redirect
      // But it doesn't make sense in the context of a partial payment transaction
      // So we do nothing for the moment
    }

    $store.dispatch('addPartialTransaction', store.clientAnswer)
    $store.dispatch('paymentEnd')
    $store.dispatch('closeMethod')
  })

  $bus.$on(Events.krypton.message.declinePartialPaymentApply, res => {
    $store.dispatch('removePartialTransaction', res)
  })

  $bus.$on(Events.krypton.message.closeGooglePaySimulator, res => {
    // The event to close the sim will come from the ghost. see KJS-4025
    $store.dispatch('closeGooglePaySimulator')
  })

  $bus.$on(
    Events.krypton.message.nonSensitiveAutofillUpdate,
    ({ formId, payload }) => {
      $store.dispatch(`cardForm_${formId}/updateNonSensitiveValue`, payload)
    }
  )

  $bus.$on(Events.krypton.message.closeSamsungPaySimulatorStore, res => {
    // The event to close the sim will come from the ghost. see KJS-4587
    $store.dispatch('closeSamsungPaySimulator')
  })

  // Store subscriptions
  $store.subscribe((mutation, state) => {
    const formId =
      mutation.type.indexOf('cardForm_') === 0
        ? mutation.type.split('/')[0].split('_')[1]
        : null
    // brandChanged
    if (formId && mutation?.payload?.selectedBrand) {
      const { bin, selectedBrand } = state[`cardForm_${formId}`]
      callbackHandler.runCallbacks(
        'onBrandChanged',
        {
          KR,
          cardInfo: { bin, brand: selectedBrand.toLowerCase() }
        },
        formId
      )
    }

    // popin closed
    if ($store.getters.isFormPopin) {
      const isPopinOpen = mutation?.payload?.isPopinOpen
      if (isBoolean(isPopinOpen) && !isPopinOpen) {
        callbackHandler.runCallbacks('onPopinClosed')
      }
    }

    // error
    if (mutation?.payload?.error?.errorCode) {
      const formId = mutation?.payload?.error?.formId ?? null
      callbackHandler.runCallbacks('onError', state.error, formId)
      if (hasTransaction(state.error)) {
        callbackHandler.runCallbacks(
          'onTransactionCreated',
          getTransaction(state.error),
          formId
        )
      }
    }

    // 3ds abort
    if (mutation?.payload?.is3dsAbort) {
      const formId = mutation?.payload?.redirectForm
      callbackHandler.runCallbacks('on3dSecureAbort', null, formId)
    }

    // Wallet mode
    if (formId && mutation?.payload?.walletMode) {
      const walletMode = walletModes[state[`cardForm_${formId}`].walletMode]
      callbackHandler.runCallbacks('wallet.onTabChange', walletMode, formId)
    }

    // onFormValid
    if (formId && mutation?.payload?.isFormValid) {
      const { bin, selectedBrand } = state[`cardForm_${formId}`]
      callbackHandler.runCallbacks(
        'onFormValid',
        {
          KR,
          cardInfo: { bin, brand: selectedBrand.toLowerCase() }
        },
        formId
      )
    }

    // Show warning if popin + single payment button is combined as it is not supported
    if (
      mutation?.payload?.smartForm?.singlePaymentButton ||
      mutation?.payload?.smartForm?.type === 'popin'
    ) {
      const { singlePaymentButton, type } = state.smartForm
      if (singlePaymentButton && type === 'popin')
        console.warn(
          'Single Payment Button mode is NOT supported in popin mode'
        )
    }
  })

  // submit
  callbackHandler.onSubmitEventListener()

  // transaction created
  callbackHandler.onTransactionCreatedEventListener()

  // button clicked
  callbackHandler.onClickEventListener()

  // Payment Method triggered
  callbackHandler.onSmartFormClickEventListener()

  // Payment token deleted
  callbackHandler.onPaymentTokenDeletedEventListener()
}
