<template lang="pug">
include templates/SmartButton.pug
.kr-smart-button-wrapper
  +wrapper({ type: 'token'})
  krypton-layer(mode="unified" :method="cleanPaymentMethod")
  SmartButtonError(:reference="paymentMethod")
</template>

<script>
import { mapActions } from 'vuex'

import Modal from '@/configuration/sources/SimpleModal.yml'

import { SmartButtonMixin } from '@/host/components/mixins/SmartButton'
import SmartButtonError from '@/host/components/smartbutton/Error'
import KryptonLayer from '@/host/components/controls/KryptonLayer'

/**
 * Component used for smartForm PaymentMethodToken + forced
 * Cards payment method not supported
 *
 * @since KJS-3308
 */
export default {
  name: 'PaymentTokenSmartButton',
  components: {
    SmartButtonError,
    KryptonLayer
  },
  mixins: [SmartButtonMixin],
  props: {
    paymentMethodToken: { type: Object, required: true }
  },
  data() {
    return {
      brands: '',
      brandFilter: ''
    }
  },
  computed: {
    paymentMethod() {
      const { paymentMethodType, token } = this.paymentMethodToken
      return `${paymentMethodType}:${token}`
    },
    /**
     * If it's not inside the smartform, the payment mehtod must be
     * in the DNA unless it has the always visible prop
     * If it's inside, it will be visible only when there is one
     * payment method declared in the DNA and it's not an integrated
     * cards form
     */
    isVisible() {
      return this.isMethodAvailableInDna(this.cleanPaymentMethod)
    },
    isSelected() {
      return true
    },
    showAmount() {
      return true
    },
    hasRegularLabel() {
      return this.cleanPaymentMethod !== 'CARDS' && !this.showLoadingAnimation
    },
    hasSingleBrand() {
      return false
    },
    methodLabel() {
      return this.paymentMethodToken.fields?.id?.value ?? ''
    },
    regularMethodLabel() {
      return this.methodLabel
    },
    isDisabled() {
      return (
        !this.allIFramesReady ||
        !this.isMethodAvailableInDna(this.cleanPaymentMethod) ||
        this.paymentDone ||
        this.disabledForm
      )
    }
  },
  methods: {
    ...mapActions(['openModal']),
    resolveMethod() {
      const baseMethod = this.paymentMethodToken.paymentMethodType
      return baseMethod.startsWith('PAYPAL') ? 'PAYPAL' : baseMethod
    },
    openMethod() {
      if (this.isDisabled || this.isLoading) return

      const layouts = Modal.layouts
      this.openModal({
        layout: layouts[this.resolveMethod()].confirmTokenPayment,
        method: this.paymentMethodToken.paymentMethodType,
        paymentMethodToken: this.paymentMethodToken.token
      })
    },
    // Override
    async triggerMethod(method, $event) {
      this.openMethod()
    }
  }
}
</script>
