<template lang="pug">
  svg.kr-sp-radio-button(xmlns='http://www.w3.org/2000/svg', viewBox='0 0 100 100', fill='none', :class="dynamicClasses")
    circle(cx='50%', cy='50%', r='48', stroke='var(--circle-graph-color)', stroke-width='4')
    circle(v-if="isChecked", cx="50%", cy="50%", r="24", fill="var(--circle-graph-color)")
</template>

<script>
import { RadioButtonMixin } from '@/host/components/mixins/smartform/RadioButton'

/**
 * Used by SplitPaymentPanelOptionActivator.
 *
 * @since KJS-4363
 */
export default {
  name: 'SplitPaymentPanelOptionRadio',
  mixins: [RadioButtonMixin],
  computed: {
    dynamicClasses() {
      return {
        'kr-sp-radio-button--checked': this.isChecked,
        'kr-sp-radio-button--hover': this.isHovered
      }
    }
  }
}
</script>
