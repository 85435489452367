<template lang="pug">
  span.kr-sp-header-label(:style="dynamicStyles" v-html="content")
    //- Render as:
    //- span.kr-sp-header-amount {{ <formattedAmount> }}
    //- out of
    //- span.kr-sp-header-total {{ <formattedTotal> }}
</template>

<script>
import { mapGetters, mapState } from 'vuex'

/**
 * Display formatted header label in KryptonPopinHeader.
 *
 * @since KJS-4366
 */
export default {
  name: 'SplitPaymentHeaderLabel',
  computed: {
    ...mapGetters(['firstAmount', 'getAmountLabel', 'translate']),
    ...mapState(['merchant', 'totalAmountLabel']),
    content() {
      const translation = this.translate('split_payment_header_text')
      const firstAmount = this.getAmountLabel(this.firstAmount)

      return translation
        .replace(
          '[AMOUNT]',
          `<span class="kr-sp-header-amount">${firstAmount}</span>`
        )
        .replace(
          '[TOTAL]',
          `<span class="kr-sp-header-total">${this.totalAmountLabel}</span>`
        )
    },
    dynamicStyles() {
      // @todo refactor after KJS-2765 completion
      return {
        '--kr-white-label-header-amount-color':
          this.merchant.header.amount.color
      }
    }
  }
}
</script>
