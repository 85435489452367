/**
 * Added to distinguish between regular/unexpected errors and warnings
 * that should not stop the execution of the factory.
 *
 * @since KJS-4764
 */
export default class FactoryWarning extends Error {
  constructor(message, errorCode = null, option = {}) {
    super(message, option)
    this.errorCode = errorCode
  }
}
