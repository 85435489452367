var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "100%",
        height: "100%",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("line", {
        attrs: {
          x1: "50%",
          x2: "50%",
          stroke: "black",
          "stroke-dasharray": "2 2",
          y1: "0",
          y2: "100%",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }