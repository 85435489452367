export default KR => {
  const toggleSkeleton = forceSkeleton =>
    KR._wrapper(`skeleton.${forceSkeleton ? 'show' : 'hide'}`, () => {
      KR.$store.dispatch('update', {
        smartForm: { forceSkeleton },
        smartButton: { forceSkeleton }
      })
    })()

  return {
    show: () => toggleSkeleton(true),
    hide: () => toggleSkeleton(false)
  }
}
