import paymentMethodsConf from '@/configuration/sources/smartform/paymentMethodsConf.yml'

export function initSmartformMethods() {
  const obj = {}
  paymentMethodsConf.whitelist.forEach(method => {
    obj[method] = {
      icon: null,
      label: null
    }
  })
  return obj
}
