import { pick } from 'underscore'
import Events from '@/configuration/Events'
import storeConfiguration from '@/configuration/StoreConfiguration'

export default class PaymentManager {
  eventListenerFn

  constructor($locator) {
    this.$locator = $locator

    // Remove possibly added event listener on the ghost window when its closed
    $locator.$bus.$on(Events.krypton.message.finishedRedirection, () => {
      window.removeEventListener('message', this.eventListenerFn)
    })
  }

  addMessageListener(callback = () => {}, isSmartFormRedirection = false) {
    this.eventListenerFn = this._getEventListener(
      callback,
      isSmartFormRedirection
    )
    window.addEventListener('message', this.eventListenerFn, false)
  }

  handler(answer, isSmartFormRedirection = false) {
    const { application, $store, proxy, storeFactory } = this.$locator
    // If popupPayment, force answer to json
    if (answer._name === 'popupPayment') answer = answer.json()
    const { formId } = answer
    const isSmartPayment = isSmartFormRedirection || application !== 'ghost'

    if (answer?.clientAnswer?.orderStatus) {
      const validAnswer =
        answer.hash && answer.hashAlgorithm && (answer._type || answer.type)
      switch (answer.clientAnswer.orderStatus.toLowerCase()) {
        case 'unpaid':
          $store.dispatch('error', { formId, metadata: { answer } })
          break
        case 'paid':
        case 'running':
          if (validAnswer) {
            const paymentStore = this._createPaymentStore(answer, formId)
            if (isSmartPayment) $store.dispatch('saveTransaction', paymentStore)
            else proxy.send(paymentStore)
          }

          break
        case 'partially_paid':
          if (answer.clientAnswer.transactions[0].status === 'PAID')
            proxy.send(
              storeFactory.create(
                'billingPartialPayment',
                this._createPaymentStore(answer, formId)
              )
            )
          else $store.dispatch('error', { formId, metadata: { answer } })
      }
    } else {
      // Unexpected response, look for error info
      $store.dispatch('error', { formId, metadata: { answer } })
    }
  }

  _getEventListener(callback, isSmartFormRedirection = false) {
    return ({ data }) => {
      // Here we can be receiving a string prefixed by /*krypton-client*/ sent by the communicator
      // We don't care about these messages
      if (typeof data === 'string') return

      // The postMessages sent by the iframe content have the following format: { data: string, action: string }
      if (data.action === 'validate') {
        const answerData = data.data.replace(
          /"clientAnswer":"({.*})"/,
          '"clientAnswer":$1'
        )
        this.handler(JSON.parse(answerData), isSmartFormRedirection)
        callback()
      } else if (data.action === 'close') {
        const app = this.$locator.application
        // For popup, dispatch the action to close and trigger the redirection
        if (app === 'host') this.$locator.$store.dispatch('closeWindow')
        // For iframe, send the action to navigate back
        else
          this.$locator.proxy.send(
            this.$locator.storeFactory.create('navigateBack')
          )
      }
    }
  }

  _createPaymentStore(answer, formId) {
    const payment = pick(answer, storeConfiguration.payment.fields)
    payment.formId = formId
    if (!payment.extra) payment.extra = {}
    if (answer.hashKey) payment.hashKey = answer.hashKey
    if (!payment.rawClientAnswer && answer?.clientAnswer)
      payment.rawClientAnswer = JSON.stringify(answer.clientAnswer)
    return this.$locator.storeFactory.create('payment', payment)
  }
}
