import { isString, isUndefined } from 'underscore'
import Zepto from 'zepto-webpack'
import Currency from '@/configuration/sources/Currency.yml'
import Locales from '@/configuration/sources/Locales.yml'
import applePayConf from '@/configuration/sources/smartform/applePay.yml'
import { logSentryException } from '@/common/util/sentry'

const formatAmount = (amount, decimalPlaces) => {
  return (Math.abs(amount) / Math.pow(10, decimalPlaces)).toFixed(decimalPlaces)
}

const formatCurrency = currency => {
  return !isString(currency) ? 'USD' : currency.toUpperCase()
}

/**
 * Try to find config for the given locale (e.g: en_AU)
 * If not found, fallback to ISO language code (e.g: en_AU -> en)
 * If no config is found for locale nor language, fallback to currency default locale (e.g: USD -> en_US)
 * If not found, fallback to currency default locale (e.g: USD -> en_US -> en)
 *
 * @param {string} locale Locale (e.g: en_US, fr_FR, en, fr)
 * @param {string} currency
 * @see KJS-3607
 */
const getLocaleConfig = (locale, currency) => {
  // HACK: Force the format by currency
  locale = Currency.forcedFormat.includes(currency)
    ? Locales.default[currency]
    : locale.replace('-', '_')

  const currencyLocale = Locales.default[currency] || 'en'
  const config =
    Locales.properties[locale] ||
    Locales.properties[locale.substring(0, 2)] ||
    Locales.properties[currencyLocale] ||
    Locales.properties[currencyLocale.substring(0, 2)]

  if (config.h) {
    return Locales.properties[config.h]
  }
  return config
}

const getPattern = (amount, localPattern) => {
  // Use encode function to work out pattern
  const [positive, negative, zero] = localPattern.split(';')
  if (amount === 0) return isUndefined(zero) ? positive : zero
  if (amount > 0) return positive
  return isUndefined(negative) ? '-' + positive : negative
}

// encodePattern Function - returns a few simple characteristics of the pattern provided
const encodePattern = pattern => {
  let decimalPlaces = 0
  let frontPadding = ''
  let backPadding = ''
  let groupLengths = []

  let patternStarted = false
  let decimalsStarted = false
  let patternEnded = false

  let currentGroupLength = 0
  let zeroLength = 0

  for (const c of pattern) {
    if (!patternStarted && ['#', '0', ',', '.'].indexOf(c) > -1)
      patternStarted = true
    if (!patternStarted) frontPadding += c

    switch (c) {
      case '#':
        ++currentGroupLength
        break
      case '0':
        if (decimalsStarted) {
          ++decimalPlaces
        } else {
          ++currentGroupLength
          ++zeroLength
        }
        break
      case ',':
        groupLengths.push(currentGroupLength)
        currentGroupLength = 0
        break
      case '.':
        groupLengths.push(currentGroupLength)
        decimalsStarted = true
        break
    }

    if (patternStarted && !(['#', '0', ',', '.'].indexOf(c) > -1)) {
      patternEnded = true
      if (!decimalsStarted) groupLengths.push(currentGroupLength)
    }

    if (patternEnded) backPadding += c
  }

  return { decimalPlaces, frontPadding, backPadding, groupLengths, zeroLength }
}

// Zero Padding helper function
const pad = (n, width) => {
  return `${n}`.length >= width
    ? `${n}`
    : new Array(width - `${n}`.length + 1).join('0') + `${n}`
}

const _htmlEntityDecodeFix = element => {
  var re = new RegExp(String.fromCharCode(160), 'g')
  return Zepto('<span></span>').html(element).text().replace(re, ' ').trim()
}

// Format function
const format = (
  amount,
  currency,
  decimalSeparator,
  groupSeparator,
  pattern
) => {
  const symbol = Currency.symbols[currency]
  const { decimalPlaces, groupLengths, zeroLength, frontPadding, backPadding } =
    encodePattern(pattern)
  const finalDecimalPlaces =
    typeof Currency.decimalPlaces[currency] === 'number'
      ? Currency.decimalPlaces[currency]
      : decimalPlaces
  const [integer, decimal] = formatAmount(amount, finalDecimalPlaces).split('.')
  let segment = ''

  // If we actually have some sort of grouping in the values
  if (groupLengths.length > 1) {
    let cursor = integer.length
    let groupIndex = groupLengths.length - 1

    while (cursor > 0) {
      // Always reset to the first group length if the number is big
      if (groupIndex <= 0) groupIndex = 1

      const currentGroupLength = groupLengths[groupIndex]
      const start = cursor - currentGroupLength

      segment = integer.substring(start, cursor) + groupSeparator + segment
      cursor -= currentGroupLength
      --groupIndex
    }

    segment = segment.substring(0, segment.length - 1)
  }

  if (segment.length < zeroLength) segment = pad(segment, zeroLength)

  const formattedNumber =
    frontPadding +
    segment +
    (isUndefined(decimal) ? '' : decimalSeparator + decimal) +
    backPadding

  return _htmlEntityDecodeFix(formattedNumber.replace('!', symbol))
}

export default (amount, currency, locale) => {
  try {
    currency = formatCurrency(currency)
    const localeConfig = getLocaleConfig(locale, currency)
    const {
      p: localPattern,
      d: decimalSeparator,
      g: groupSeparator
    } = localeConfig

    // Use encode function to work out pattern
    const pattern = getPattern(amount, localPattern)

    return format(amount, currency, decimalSeparator, groupSeparator, pattern)
  } catch (error) {
    logSentryException(error, 'common/util/currencyFormatter')
  }
}

export const getFormattedAmount = (amount, currency, locale) => {
  try {
    currency = formatCurrency(currency)
    const localeConfig = getLocaleConfig(locale, currency)
    const symbol = Currency.symbols[currency]
    const {
      p: localPattern,
      d: decimalSeparator,
      g: groupSeparator
    } = localeConfig

    // Use encode function to work out pattern
    const pattern = getPattern(amount, localPattern)

    const { decimalPlaces } = encodePattern(pattern)
    return formatAmount(amount, decimalPlaces)
  } catch (error) {
    logSentryException(
      error,
      'common/util/currencyFormatter.getFormattedAmount'
    )
  }
}

/**
 * Returns the formatted amount for Apple Pay, as it may require a different format
 * {KJS-3008}
 * @param {number} amount
 * @param {string} currency
 * @param {string} locale
 */
export const getApplePayFormattedAmount = (amount, currency, locale) => {
  const currenciesWithNoDecimal = applePayConf.amount.truncate.currencies
  const formattedAmount = getFormattedAmount(amount, currency, locale)

  // Remove decimal if currency is in the list
  if (currenciesWithNoDecimal.includes(currency)) {
    return formattedAmount.substring(0, formattedAmount.lastIndexOf('.'))
  }

  return formattedAmount
}

/**
 * Returns a string with 2 numbers after the '.'
 *
 */
export const getGooglePayFormattedAmount = amount => {
  return formatAmount(amount, 2)
}

/**
 * Returns a string with 2 numbers after the '.'
 *
 */
export const getSamsungPayFormattedAmount = amount => {
  return formatAmount(amount, 2)
}
