import ErrorsConfig from '@/configuration/sources/ErrorsConfiguration.yml'

export const initErrorTemplates = () => {
  return Object.fromEntries(
    ErrorsConfig.withPaymentMethod.map(code => [
      code,
      '{{paymentMethod}} - {{errorMessage}}'
    ])
  )
}
