var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "kr-spinner" }, [
    _c("div", { staticClass: "kr-icon" }, [
      _c(
        "svg",
        {
          attrs: {
            width: "24px",
            height: "24px",
            viewBox: "0 0 64 64",
            version: "1.1",
            xmlns: "http://www.w3.org/2000/svg",
            "xmlns:xlink": "http://www.w3.org/1999/xlink",
          },
        },
        [
          _c("circle", {
            staticClass: "kr-border",
            attrs: {
              cx: "32",
              cy: "32",
              r: "30",
              "stroke-linecap": "round",
              "stroke-width": "4",
              stroke: "#000",
              fill: "none",
            },
          }),
          _c("path", {
            staticClass: "kr-checkmark",
            attrs: {
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              d: "M23.375 31.5488281 28.8840688 38.0578969 42.891932 25.0500338",
              "stroke-width": "3",
              stroke: "#000",
              fill: "none",
            },
          }),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }