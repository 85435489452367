import Events from '@/configuration/Events'
import { getTestCardFromDebugTestCards } from '@/common/util/card'
import { TestCard } from '@/common/model/'

/**
 * Created first with setTestCard in order to standardize test card selection
 * regardless of payment method nor location.
 *
 * E.g:
 *   KR._internals.setTestCard('mastercard.accepted.visa-accepted')
 *   -> Would fill card form with the accepted mastercard card or send its data
 *   to the active simulator (ApplePay or GooglePay)
 *
 * E.g 2:
 *   KR._internals.setTestCard('visa.accepted.visa-accepted')
 *   KR._internals.setTestCard('cb.accepted.visa-accepted')
 *   -> Both card have the same pan/expiry/cvv but a different network
 *   selection mode is set.
 *
 * @since KJS-4253
 */
export default KR => {
  /**
   * Translation card description.
   *
   * @param {object} conf
   * @param {string} conf.key         Key to translate card description
   * @param {string} [conf.currency]  Currency to replace in description
   * @returns {string}
   */
  const getDescription = conf => {
    let label = KR.$store.getters.translate(conf.key)
    label = label.charAt(0).toUpperCase() + label.slice(1)
    if (conf.currency) {
      label = label.replace('%currency%', conf.currency)
    }
    return label
  }

  return {
    setTestCard: testCardId => {
      const testCardConf = getTestCardFromDebugTestCards(testCardId)
      if (!testCardConf) {
        return console.warn(
          `KR._internals.setTestCard: Invalid test card ID: ${testCardId}`
        )
      }
      const { $store, $bus } = KR
      const { isApplePayActive, isGooglePayActive, isSamsungPayActive } =
        $store.getters
      const card = TestCard.create({
        pan: testCardConf.pan,
        brand: testCardConf.brand,
        label: getDescription(testCardConf)
      })

      if (isApplePayActive || isGooglePayActive || isSamsungPayActive) {
        $bus.$emit(Events.krypton.simulator.cards.selected, card)
      } else {
        // Cards
        const networkSelectionMode = card.getNetworkSelectionMode()
        $store.dispatch('update', { networkSelectionMode })
        $store.dispatch('setTestCard', { args: testCardId })
      }
    },
    simulator: {
      /**
       * Sets a callback to be executed when the simulator card selector is clicked.
       *
       * @param {function} callback The function to execute on simulator card selector click.
       */
      onCardSelectorClick: callback => {
        if (typeof callback !== 'function') {
          console.warn(
            'KR._internals.simulator.onCardSelectorClick: Provided callback is not a function.'
          )
          return
        }

        KR._addCallback('simulator.onCardSelectorClick')(callback)
      }
    }
  }
}
