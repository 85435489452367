import {
  FactoryWarning,
  FactoryResultWrapper,
  FactoryLogger,
  Collection,
  InstallmentSchedule
} from '@/common/model/'

const PRIVATE_CONSTRUCTOR_KEY = Symbol()
const MAX_NB_OPTS = 3

/**
 * Used to ensure a correct installment options collection is passed to
 * SplitPaymentPanel component. It cannot be instanciated with new operator.
 * Only the factory should be used in order to validate the data but also
 * sanitize it and sort options by number of installments ascending.

 * @since KJS-4363
 */
export default class InstallmentOptionCollection extends Collection {
  // Factory -------------------------------------------------------------------
  /**
   * @returns {FactoryResultWrapper}
   */
  static create(collection) {
    if (collection instanceof InstallmentOptionCollection) return collection

    const logger = new FactoryLogger()
    const err = this._validate(collection)

    if (err) {
      logger.error(err)
      if (!(err instanceof FactoryWarning)) {
        return new FactoryResultWrapper(null, logger)
      }
    }

    collection = this._sanitize(collection, logger)
    const instance = new InstallmentOptionCollection(
      collection,
      PRIVATE_CONSTRUCTOR_KEY
    )
    return new FactoryResultWrapper(instance, logger)
  }

  static _validate(collection) {
    if (!Array.isArray(collection)) {
      return new TypeError(
        `InstallmentOptionCollection::create: Invalid parameter, collection is expected to be an Array`
      )
    }
    if (collection.length < 1) {
      return new Error(
        `InstallmentOptionCollection.create: Invalid number of options: ${collection.length}; expect value to be between 1 and ${MAX_NB_OPTS}`
      )
    }
    if (collection.length > MAX_NB_OPTS) {
      return new FactoryWarning(
        `InstallmentOptionCollection.create: Invalid number of options: ${collection.length}; expect value to be between 1 and ${MAX_NB_OPTS}`
      )
    }
    for (const idx in collection) {
      const installmentOption = collection[idx]
      const { id, schedules } = installmentOption
      const hasValidId = typeof id === 'string'
      if (!hasValidId)
        return new TypeError(
          `InstallmentOptionCollection.create: Invalid parameter at index [${idx}], property id: ${id}`
        )
      const hasValidSchedules =
        Array.isArray(schedules) || schedules instanceof InstallmentSchedule
      if (!hasValidSchedules)
        return new TypeError(
          `InstallmentOptionCollection.create: Invalid parameter at index [${idx}], property schedules: ${schedules}`
        )
    }
  }

  static _sanitize(collection, logger) {
    return collection
      .map(installmentOption => {
        const wrapper = InstallmentSchedule.create(installmentOption.schedules)
        if (!wrapper.isValid()) {
          logger.merge(wrapper.logger)
        }
        return {
          id: installmentOption.id,
          schedules: wrapper.value
        }
      })
      .filter(({ schedules }) => schedules instanceof InstallmentSchedule)
      .sort((a, b) => a.schedules.length - b.schedules.length)
      .slice(0, MAX_NB_OPTS)
  }

  // Instance ------------------------------------------------------------------
  constructor(collection, $key) {
    if ($key !== PRIVATE_CONSTRUCTOR_KEY) {
      throw new Error(
        `InstallmentOptionCollection: cannot create instance using new operator`
      )
    }
    super(collection)
  }
}
