<template lang="pug">
KryptonPopinHeader(v-on:close="onClose()" :isSmartForm="isSmartForm")
</template>

<script>
import { mapActions, mapState } from 'vuex'

/** COMPONENTS */
import KryptonPopinHeader from '@/host/components/popin/KryptonPopinHeader'

export default {
  name: 'KryptonPopinHeaderWrapper',
  components: { KryptonPopinHeader },
  props: {
    isSmartForm: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(['dccReceiptOpen'])
  },
  methods: {
    ...mapActions(['closePopin', 'forceFieldClear']),
    onClose() {
      this.closePopin()

      // Report by event the closing action of the popin
      this.forceFieldClear()
    }
  }
}
</script>
