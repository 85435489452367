<template lang="pug">
  .kr-sp-panel
    SplitPaymentPanelOption(
      v-if="showPayNowOption"
      v-on:selected="selectOption(PAY_NOW_OPTION)",
      :selected="isOptionSelected(PAY_NOW_OPTION)",
      :label="getOptionLabel(PAY_NOW_OPTION)"
    )
    template(v-for="(option, idx) in installmentOptions")
      SplitPaymentPanelOption(
        v-on:selected="selectOption(idx)",
        :selected="isOptionSelected(idx)",
        :label="getOptionLabel(option)"
        :data-lang="language"
      )
        template(v-slot:content="")
          .kr-sp-option-timeline-wrapper
            SplitPaymentTimeline(:schedule="option.schedules")
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import SplitPaymentPanelOption from '@/host/components/smartform/splitPayment/PanelOption'
import SplitPaymentTimeline from '@/host/components/smartform/splitPayment/Timeline'

import { InstallmentOptionCollection } from '@/common/model/'

const PAY_NOW_OPTION = Symbol('pay_now')

/**
 * SplitPayment main component.
 *
 * @since KJS-4363
 */
export default {
  name: 'SplitPaymentPanel',
  components: {
    SplitPaymentPanelOption,
    SplitPaymentTimeline
  },
  props: {
    installmentOptions: {
      type: InstallmentOptionCollection,
      required: true
    }
  },
  data() {
    return {
      PAY_NOW_OPTION,
      selectedOptionsIdx: PAY_NOW_OPTION
    }
  },
  computed: {
    ...mapGetters(['getAmountLabel', 'translate']),
    ...mapState(['amount', 'language']),
    ...mapState({
      showPayNowOption: state => state.smartForm.splitPayment.showPayNowOption
    })
  },
  watch: {
    showPayNowOption(newVal) {
      // Select the first option automatically if showPayNowOption value is changed dynamically
      if (!newVal) {
        this.selectOption(0)
      }
    }
  },
  mounted() {
    if (!this.showPayNowOption) {
      // If showPayNowOption is set to false select the first option automatically
      this.selectOption(0)
    }
  },
  methods: {
    selectOption(idx) {
      this.selectedOptionsIdx = idx
      this.$emit('option-selected', this.installmentOptions.at(idx)?.id ?? null)
    },
    isOptionSelected(idx) {
      return this.selectedOptionsIdx === idx
    },
    getOptionLabel(option) {
      if (option === PAY_NOW_OPTION) {
        const translation = this.translate('split_payment_pay_now')
        return translation.replace('[AMOUNT]', this.getAmountLabel(this.amount))
      }
      const translation = this.translate('split_payment_installment')
      return translation.replace('[COUNT]', option.schedules.length)
    }
  }
}
</script>
