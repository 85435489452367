import AbstractTrigger from '@/ghost/service/Triggers/AbstractTrigger'

export default class GetReceiptTrigger extends AbstractTrigger {
  receiptWidth = 450
  receiptHeight = 550

  constructor($locator) {
    super($locator, 'getReceipt')
  }

  async onEvent() {
    this._setReceiptListeners()

    const redirectStore = this.storeFactory.create('billingRedirect', {
      width: this.receiptWidth,
      height: this.receiptHeight,
      redirectUrl: this._getReceiptUrl()
    })
    this.$store.dispatch('manualReceiptDisplay', redirectStore)
  }

  _setReceiptListeners() {
    const returnToShopListener = event => {
      if (event.data.action === 'close') {
        window.removeEventListener('message', returnToShopListener)
        // Must navigate back, otherwise in popin mode an empty modal stays open after the redirection
        this.$locator.proxy.send(
          this.$locator.storeFactory.create('navigateBack')
        )
        this.$store.dispatch('finishRedirection')
      }
    }
    window.addEventListener('message', returnToShopListener)
  }

  _getReceiptUrl() {
    const { formToken } = this.$store.state
    const endpoint = this.endpointDetector.getEndpointRoot(formToken)

    let url = endpoint.endpoint.api
    url = this.restAPI.getReceiptUrl(url)
    url = this.restAPI.addJSessionID(url)
    url = this.restAPI.addECSOrderId(url)

    return url
  }
}
