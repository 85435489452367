var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.hasInstallmentSelected,
          expression: "!hasInstallmentSelected",
        },
      ],
    },
    [
      _vm._v(
        _vm._s(_vm._f("colon")(_vm._f("capitalize")(_vm.label), _vm.language))
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }