export class RGBColor {
  static fromHex(h) {
    let r = 0
    let g = 0
    let b = 0

    if (h.length == 4) {
      r = '0x' + h[1] + h[1]
      g = '0x' + h[2] + h[2]
      b = '0x' + h[3] + h[3]
    } else if (h.length == 7) {
      r = '0x' + h[1] + h[2]
      g = '0x' + h[3] + h[4]
      b = '0x' + h[5] + h[6]
    }

    return new RGBColor({ r: Number(r), g: Number(g), b: Number(b) })
  }

  constructor({ r, g, b }) {
    Object.assign(this, { r, g, b })
  }

  toString() {
    return `rgb(${this.r}, ${this.g}, ${this.b})`
  }
}
