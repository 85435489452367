import { v4 } from 'uuid'
import { isString } from 'underscore'

/**
 * Modifiy svg to ensure ID are uniq.
 *
 * @param  {String} svg Image content
 * @return {String}     Modified image content
 * @since  KJS-2030
 * @see    KJS-2162 | Move from KryptonIcon to separate file.
 */
export const ensureUniqId = svg => {
  if (isString(svg) && /kr-svg/.test(svg)) {
    const uuid = v4()
    svg = svg.replace(/kr-svg(?=__)/g, uuid)
  }
  return svg
}

/**
 * Returns an svg from a given URL address
 *
 * @param {String} urlAddress URL address to fetch the SVG from
 * @returns {String} SVG content
 */
export const urlToSvg = async urlAddress => {
  try {
    const url = new URL(urlAddress)
    const isSvg = url.pathname.endsWith('.svg')

    // Not an SVG file, wrap it in an SVG tag
    if (!isSvg)
      return `<svg xmlns="http://www.w3.org/2000/svg"><image href="${urlAddress}" width="100%" height="100%"></image></svg>`

    // SVG file, fetch it and return the content
    const response = await fetch(url, {
      method: 'GET'
    })

    return await response.text()
  } catch (error) {
    console.error(`Could not retrieve asset from: ${urlAddress}`, error)
  }
}
