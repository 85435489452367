<template lang="pug">
  component.kr-button-animation(:class="dynamicClasses", :is="component")
</template>

<script>
import Events from '@/configuration/Events'

import KryptonAnimationSpinningCircle from '@/host/components/controls/animation/SpinningCircle'
import KryptonAnimationSpinningSquares from '@/host/components/controls/animation/SpinningSquares'
import KryptonAnimationBubbles from '@/host/components/controls/animation/Bubbles'
/**
 * Display theme animation and handle frozen state.
 *
 * @since KJS-4716
 */
export default {
  name: 'KryptonButtonAnimation',
  components: {
    KryptonAnimationSpinningCircle,
    KryptonAnimationSpinningSquares,
    KryptonAnimationBubbles
  },
  props: {
    type: {
      type: String,
      default: 'spinning-squares'
    }
  },
  data() {
    return {
      frozen: false,
      componentMap: {
        'spinning-circle': KryptonAnimationSpinningCircle,
        'spinning-squares': KryptonAnimationSpinningSquares,
        bubbles: KryptonAnimationBubbles
      }
    }
  },
  computed: {
    component() {
      return this.componentMap[this.type]
    },
    dynamicClasses() {
      return {
        'kr-button-animation--frozen': this.frozen
      }
    }
  },
  created() {
    this.$busOn(Events.krypton.smartform.spinner.freeze, () => {
      this.frozen = true
    })
    this.$busOn(Events.krypton.smartform.spinner.unfreeze, () => {
      this.frozen = false
    })
  }
}
</script>
