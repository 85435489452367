import regeneratorRuntime from 'regenerator-runtime' // NECESSARY to use async
import PaymentProcessedState from '@/ghost/workflow/payment/state/PaymentProcessedState'
import Events from '@/configuration/Events'
import Zepto from 'zepto-webpack'
import Modal from '@/configuration/sources/SimpleModal.yml'

export default class PaymentProcess {
  constructor($locator, formObject, state) {
    this.$locator = $locator
    this.$store = $locator.$store
    this.restAPI = $locator.restAPI
    this.$bus = $locator.$bus
    this.formToken = formObject.url.formToken
    this.publicKey = formObject.url.publicKey
    this.paymentForm = formObject.url.paymentForm
    this.url = this.restAPI.getProcessPaymentUrl(formObject.endpoint)
    this.state = state
    this.context = state.context
    this.ecsPaymentId = null
  }

  process(values, procResp = null) {}

  /**
   * Builds the request object to send it in the PP
   */
  buildRequest(instructionResult) {
    const formToken = this.formToken
    const publicKey = this.publicKey
    return {
      formToken,
      publicKey,
      instructionResult
    }
  }

  /**
   * Calls the ProcessPayment
   */
  async callProcessPayment(objectData, headers = {}) {
    const url = this.restAPI.addJSessionID(this.url)

    const browserRequests = this.$locator.browserRequests
    const storeFactory = this.$locator.storeFactory
    const { partialPaymentsAllowed, getPaymentMethodCloseButtonType } =
      this.$store.getters
    return await browserRequests.post(
      storeFactory.create('requestData', {
        url,
        objectData: {
          ...objectData,
          clientVersion: this.restAPI.getClientVersion(this.paymentForm),
          device: this.restAPI.getDeviceData(),
          wsUser: this.restAPI.getWSUser(),
          partialPaymentsAllowed,
          paymentMethodCloseButton: getPaymentMethodCloseButtonType
        },
        headers,
        options: {}
      })
    )
  }

  /**
   * Checks if it's an iframe response and opens the popin if it's necessary
   */
  async checkResponse(resp) {
    if (resp.hasOwnProperty('response')) {
      const answer = resp.response.answer
      if (this.shouldOpenPopup(answer)) await this.createBillingRedirect(answer)
    }
  }

  shouldOpenPopup(answer) {
    return (
      answer._type === 'V4/AuthenticationResponseData' &&
      answer.value?.name === 'CHALLENGE' &&
      answer.value?.target?.element === 'IFRAME' &&
      answer.value?.target?.visible
    )
  }

  async createBillingRedirect(answer) {
    const storeFactory = this.$locator.storeFactory
    let { width, height } = answer.value.target
    const {
      dispatch,
      state,
      getters: { isMobile, getActiveCardFormState }
    } = this.$store

    height += 29

    const billingRedirectV2 = storeFactory.create('billingRedirectV2', {
      width,
      height
    })

    if (isMobile && state.show3DSWarning) {
      await dispatch('openAwaitableModal', {
        layout: Modal.layouts.CARDS.pre3dsWarning,
        method: getActiveCardFormState.selectedBrand
      })

      if (this.$store.state.modal.selectedOption === 'close')
        throw { errorCode: 'CLIENT_101' }
    }

    dispatch('displaySmartFormModal')

    // wallet + card-form-expanded -> required to navigate to content from extra
    dispatch('navigate', 'content')

    billingRedirectV2.formId = this.context?.form?.id
    this.$bus.$emit(Events.krypton.payment.redirect, billingRedirectV2)
  }

  /**
   * Process the response, being able to return:
   * - error
   * - payment processed state
   * - lyraAuth response
   */
  processResponse(response) {
    const storeFactory = this.$locator.storeFactory
    const logger = this.$locator.logger
    const lyraAuth = this.$locator.lyraAuth
    const $store = this.$locator.$store
    const { language } = $store.state
    const status = response?.response?.status
    const answer = response?.response?.answer
    const serverDate = response?.response?.serverDate
    const { _type, clientAnswer } = answer

    // Log
    logger.log('info', `ProcessPayment response`, response.response)

    return new Promise((resolve, reject) => {
      if (response.getStatusCode() != 200) {
        // Invalid status code, throw error code CLIENT_992
        reject({
          errorCode: 'CLIENT_992',
          metadata: { answer: response.response }
        })
      } else if (status === 'ERROR') {
        // Error response
        const { errorCode, errorMessage } = answer
        reject({
          code: errorCode,
          msg: errorMessage,
          metadata: answer,
          serverDate
        })
      } else if (_type === '%%webservices_version%%/Charge/RedirectRequest') {
        const { redirectUrl, width, height } = response.response.answer
        resolve(
          storeFactory.create('billingRedirect', {
            redirectUrl,
            width,
            height
          })
        )
      } else if (clientAnswer) {
        resolve(new PaymentProcessedState(this.state))
      } else {
        // Let LyraAuth do the work and wait for the response
        answer.value.language = language.split('-')[0]
        const containerSel = '.app-ghost-container .kr-iframe-container'
        // Empty the container
        Zepto(containerSel).empty()
        try {
          lyraAuth.delegate(answer, resolve, containerSel, '100%')
        } catch (error) {
          reject({ code: 'CLIENT_995', detailMsg: error.message })
        }
      }
    })
  }

  async getProcessedResponse(respOrState, resp) {
    // Fingerprint (3ds), use lyraAuth
    if (respOrState.name === 'FINGERPRINT') {
      return await this.processResponse(resp)
    } else if (respOrState.name === 'CHALLENGE') {
      // Challenge
      this.$store.dispatch('finishRedirection')

      // wallet + card-form-expanded required to navigateBack to extra from content
      if (this.$store.getters.hasNavigatedFrom('extra'))
        this.$store.dispatch('navigateBack')
      // googlepay after 3ds
      if (this.$locator.$store.state.smartForm.activeMethod === 'GOOGLEPAY')
        if (this.$locator.$store.getters.isSmartFormPopin)
          this.$store.dispatch('navigateBack')
        else this.$store.dispatch('closeMethod')
    }
    // Timeout | Challenge, use the same response
    return respOrState
  }
}
