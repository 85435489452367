<template lang="pug">
  component(:is="component")
</template>

<script>
import KryptonPopinHeaderLabelDefault from '@/host/components/popin/KryptonPopinHeaderLabelDefault'
import PartialPaymentHeaderLabel from '@/host/components/smartform/partialPayment/PartialPaymentHeaderLabel'
import SplitPaymentHeaderLabel from '@/host/components/smartform/splitPayment/HeaderLabel.vue'

/**
 * Used by KryptonPopinHeader to slingshot to the right component
 * based on context.
 *
 * @since KJS-4366
 */
export default {
  name: 'KryptonPopinHeaderLabel',
  components: {
    KryptonPopinHeaderLabelDefault,
    PartialPaymentHeaderLabel,
    SplitPaymentHeaderLabel
  },
  props: {
    type: {
      type: String,
      default: 'default',
      validator: value => ['default', 'partial', 'split']
    }
  },
  data() {
    return {
      map: {
        default: KryptonPopinHeaderLabelDefault,
        partial: PartialPaymentHeaderLabel,
        split: SplitPaymentHeaderLabel
      }
    }
  },
  computed: {
    component() {
      return this.map[this.type]
    }
  }
}
</script>
