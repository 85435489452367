import { object, union, values, intersection } from 'underscore'

import { setSentryBasicInfo } from '@/common/util/sentry'
import cardFormModule from '@/store/modules/namespace/cardForm'
import DefaultBrandOrder from '@/configuration/sources/DefaultBrandOrder.yml'
import DebitCardList from '@/configuration/sources/DebitCardList.yml'

const mapCardFormActions = actionNames => {
  const map = {}
  for (const name of actionNames) {
    map[name] = ({ dispatch, state }, params = {}) => {
      const { args, _form = null } = params
      const label = state.activeForm
      const formId = _form ?? state.forms[label]
      if (formId) {
        dispatch(`cardForm_${formId}/${name}`, args)
      }
    }
  }
  return map
}

const mapCardFormGetters = getterNames => {
  const map = {}
  for (const name of getterNames) {
    map[name] = (state, getters) => {
      const label = state.activeForm
      const formId = state.forms[label]
      return getters[`cardForm_${formId}/${name}`]
    }
  }
  return map
}

export const getFormState = () => {
  return {
    formWidget: 'default', // or 'smartForm'
    formType: 'embedded', // or 'popin'
    formMode: 'default', // or 'token|wallet'
    forms: {}, // main: <ID>, clone: <ID>
    activeForm: 'main', // or 'clone'
    renderedCardForm: [],
    disabledForm: false, // Global, include smartform methods and card forms
    forcedBrand: 'AUTO',
    hasDynamicValues: false,
    placeholders: {
      ...getFieldsProperties(null, true)
    },
    labels: {
      ...getFieldsProperties(null, false)
    },
    contentLabels: {
      ...getContentLabelFields(null)
    },
    postUrlSuccess: null,
    postUrlRefused: null,
    getUrlSuccess: null,
    getUrlRefused: null,
    payBtnHTML: '',
    clearOnError: null,
    flexMode: false,
    spaMode: false,
    formPresets: []
  }
}

export const formActions = ($locator, app) => {
  return {
    ...mapCardFormActions([
      'disableField',
      'enableField',
      'hideField',
      'showField',
      'unsetInputFilled',
      'setInputFilled',
      'validateField',
      'invalidateField',
      'viableField',
      'inviableField',
      'forceFieldClear',
      'forceFieldBlur',
      'fieldClear',
      'resetBrand',
      'updateNonSensitiveValue',
      'startWalletPayment',
      'closeWalletPayment',
      'setWalletMode',
      'resetBinOptions',
      'resetForm',
      'deleteDNAToken',
      'selectBrand',
      'setPaymentBrand',
      'disableCardForm',
      'enableCardForm',
      'setTestCard',
      'setBrandFallback',
      'toggleSelectField'
    ]),
    updateActiveForm({ dispatch, state }, params) {
      const formId = state.forms[state.activeForm]
      dispatch(`cardForm_${formId}/update`, params)
    },
    addFormPreset({ dispatch, commit }, preset) {
      commit('ADD_FORM_PRESET', preset)
      dispatch('forEachForm', [preset.action, preset.params])
    },
    forEachForm({ dispatch, state }, [action, params]) {
      for (const label in state.forms) {
        const id = state.forms[label]
        dispatch(`cardForm_${id}/${action}`, params)
      }
    },
    registerCardFormModule({ state, dispatch }, { id }) {
      const { $store } = $locator

      if ($store.hasModule([`cardForm_${id}`])) return
      $store.registerModule([`cardForm_${id}`], cardFormModule(app))
      state.formPresets.forEach(({ action, params }) => {
        dispatch(`cardForm_${id}/${action}`, params)
      })
    },
    unregisterCardFormModule(ctx, { id }) {
      const { $store } = $locator

      if (!$store.hasModule([`cardForm_${id}`])) return
      $store.unregisterModule([`cardForm_${id}`])
    },
    addForm({ commit }, { label, id }) {
      commit('UPDATE', {
        forms: {
          [label]: id
        }
      })
    },
    resetForms({ state, dispatch, commit }) {
      if (app === 'host') {
        for (const label in state.forms) {
          const id = state.forms[label]
          dispatch('unregisterCardFormModule', { id })
        }
        commit('RESET_FORM_PRESET')
        commit('RESET_BRAND')
        commit('RESET_FORM_LIST')
      }
    },
    renderCardForm({ commit }, id) {
      commit('RENDER_CARD_FORM', id)
    },
    focusForm({ state, commit }, label) {
      if (!(label in state.forms)) {
        console.warn(`Cannot focus unregistered form "${label}"`)
      }
      commit('UPDATE', {
        activeForm: label
      })
    },
    enableButton({ commit }) {
      commit('UPDATE', { button: { disabled: false } })
    },
    disableButton({ commit }) {
      commit('UPDATE', { button: { disabled: true } })
    },
    /**
     * Careful with the name: this enables/disables the whole form
     * (card forms, and payment methods included)
     */
    disableForm({ commit, dispatch }) {
      commit('UPDATE', { disabledForm: true })
      dispatch('forEachForm', ['disableCardForm'])
    },
    enableForm({ commit, dispatch }) {
      dispatch('forEachForm', ['enableCardForm'])
      commit('UPDATE', { disabledForm: false })
    }
  }
}

export const formMutations = {
  RESET_BRAND: state => {
    const valuesToClean = ['forcedBrand']
    const defaultFormState = getFormState()
    for (const field of valuesToClean) {
      state[field] = defaultFormState[field]
    }
    setSentryBasicInfo(state)
  },
  ADD_FORM_PRESET: (state, preset) => {
    state.formPresets.push(preset)
  },
  RENDER_CARD_FORM: (state, id) => {
    state.renderedCardForm.push(id)
  },
  RESET_FORM_LIST: state => {
    state.forms = {}
  },
  RESET_FORM_PRESET: state => {
    state.formPresets.length = 0
  }
}
export const formGetters = {
  ...mapCardFormGetters([
    'isFormFilled',
    'isFieldDisabled',
    'isFieldVisible',
    'isFieldInvalid',
    'isFieldViable',
    'isDefaultBrand',
    'isWalletMyCards',
    'isTokenActive',
    'isAnyTokenActive'
  ]),
  getActiveCardFormState: state => {
    const { forms, activeForm } = state
    return state[`cardForm_${forms[activeForm]}`]
  },
  getActiveCardFormGetter: (state, getters) => getterName => {
    const { forms, activeForm } = state
    return getters[`cardForm_${forms[activeForm]}/${getterName}`]
  },
  isCardFormVisible:
    (
      { formType, formWidget, redirectIframeOpen, activeForm, forms },
      { isCardMethodActive, hasCardMethodAvailable, isExtrasFormVisible }
    ) =>
    formId => {
      return (
        ((formType === 'embedded' && formWidget === 'default') ||
          !redirectIframeOpen ||
          formId !== forms[activeForm] ||
          (formWidget === 'smartForm' && !isCardMethodActive)) &&
        (hasCardMethodAvailable || isExtrasFormVisible)
      )
    },
  isBrandAvailable: (state, getters) => brand =>
    !!~getters.availableBrands.indexOf(brand),
  isDebitBrand: state => brand => {
    return !!~DebitCardList.brands.indexOf(brand)
  },
  availableBrands: state =>
    state?.dna?.cards ? Object.keys(state.dna.cards) : [],
  brandSorter:
    ({ dna }) =>
    (brandA, brandB) => {
      const brandOrder = union(
        values(dna?.brandPriority || {}),
        DefaultBrandOrder
      )
      const indexA = brandOrder?.indexOf(brandA)
      const indexB = brandOrder?.indexOf(brandB)
      if (~indexA && !~indexB) return -1
      else if (!~indexA && ~indexB) return 1
      return indexA - indexB
    },
  brandsWithPriority:
    ({ dna }) =>
    (brands = []) => {
      const brandPriority = dna?.brandPriority
      return intersection(brands, brandPriority).length === brands.length
    },
  isAnyCardFormDisabled: state => {
    return Object.values(state.forms).some(
      id => state[`cardForm_${id}`].disabledCardForm
    )
  },
  isBrandForced: ({ forcedBrand }) => forcedBrand !== 'AUTO',
  activeFormId: ({ activeForm, forms }) => forms[activeForm],
  isCloneForm: () => formId => formId.startsWith('CF'),
  isCardFormRendered: ({ renderedCardForm }) => {
    return formId => {
      return renderedCardForm.includes(formId)
    }
  },
  /**
   * Return payBtnHtml innerText value in order to set button title for
   * ARIA compliance.
   *
   * @returns {string}
   * @since KJS-4814
   */
  payBtnText: ({ payBtnHTML }) => {
    const domParser = new DOMParser()
    const document = domParser.parseFromString(payBtnHTML, 'text/html')
    return document.body.textContent?.trim()
  }
}

export const getFieldsProperties = (value, isPlaceholder) => {
  return object([
    ['pan', value],
    ['expiryDate', value],
    ['securityCode', value],
    isPlaceholder
      ? [
          'installmentNumber',
          {
            default: value,
            singlePayment: value,
            singlePaymentCredit: value
          }
        ]
      : ['installmentNumber', value],
    ['firstInstallmentDelay', value],
    ['identityDocumentType', value],
    ['identityDocumentNumber', value],
    ['cardHolderName', value],
    ['cardHolderMail', value],
    ['doRegister', value]
  ])
}

export const getContentLabelFields = value => {
  return {
    installmentNumber: {
      singular: value,
      plural: value
    },
    firstInstallmentDelay: {
      singular: value,
      plural: value
    }
  }
}
