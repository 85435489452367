<template lang="pug">  
  .kr-brand-icon(:class="dynamicClasses", v-html="methodIconContent")
</template>

<script>
import { mapGetters } from 'vuex'
import { assets } from '@/common/loader/assets'
import { resolveBrand } from '@/common/util/brand.js'

export default {
  name: 'BrandIcon',
  props: {
    method: {
      type: String,
      default: 'DEFAULT'
    },
    compact: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      icon: null,
      isCards: false,
      assetsLoading: true,
      methodIconContent: '<span class="kr-placeholder"></span>'
    }
  },
  computed: {
    ...mapGetters(['getCustomPaymentMethodIcon']),
    dynamicClasses() {
      return {
        [`kr-${this.method.toLowerCase()}`]: true,
        'kr-cards': this.isCards,
        'kr-loading-assets': this.assetsLoading
      }
    }
  },
  watch: {
    async method() {
      this.assetsLoading = true
      await this.loadIcon()
    },
    compact: 'loadIcon'
  },
  async created() {
    await this.loadIcon()
  },
  methods: {
    async loadIcon() {
      const { regular, paymentMethods } = assets

      // Get the appropriate brand if its a card brand (resolve coBrands)
      const resolvedMethod = resolveBrand(this.method, false)

      this.isCards = !!regular.branded[resolvedMethod]
      const methRegIconDef = paymentMethods.regular[resolvedMethod]
      let methodIcon = methRegIconDef && (await methRegIconDef())
      // Grid compact mode specific icons
      const compIconDef = paymentMethods.compact[resolvedMethod]
      const compactMethodIcon = compIconDef && (await compIconDef())
      methodIcon = (this.compact ? compactMethodIcon : methodIcon) || methodIcon
      const regIconDef = regular.branded[resolvedMethod]
      this.icon = (regIconDef && (await regIconDef())) || methodIcon

      this.assetsLoading = false
      await this.setMethodIconContent()
    },
    async setMethodIconContent() {
      const placeholder = '<span class="kr-placeholder"></span>'
      this.methodIconContent =
        (await this.getCustomPaymentMethodIcon(this.method)) ||
        this.icon ||
        placeholder
    }
  }
}
</script>
