<template lang="pug">
  .kr-methods-group(:kr-payment-method="name", :class="{ 'kr-locked': isDisabled }")
    button.kr-methods-group-activator(
      ref="activator"
      type="button"
      @click="openGroup"
      @mouseover="hover = true"
      @mouseleave="hover = false"
      :class="dynamicClasses"
      v-onresize="onResize"
    )
      SmartFormRadioButton(v-if="hasRadioButton", ref="radioButton", :checked="isSelected", :hover="hover")
      span.kr-method-icon(v-for="method in iconsConfig", ref="icons", v-html="method.icon", :class="'kr-' + method.name", :kr-payment-method="method.name")
      SmartFormMethodTooltip.kr-extra-brands(
        v-show="showTooltipLabel",
        ref="tooltip",
        :label="tooltipLabel",
        :attach="!shouldDetach"
      )
        .kr-methods-group-tooltip
          span.kr-method-icon(v-for="brand in tooltipIcons", v-html="brand.icon", :class="'kr-' + brand.name")
      label.kr-methods-group-label(ref="label", :class="{ 'kr-locked': isDisabled }") {{ label }}
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import PreloadedAssets from '@/configuration/PreloadedAssets'
import PaymentMethodsConf from '@/configuration/sources/smartform/paymentMethodsConf.yml'
import { assets } from '@/common/loader/assets'
import { getElementFont, measureTextWidth } from '@/common/util/dom'

import SmartFormMethodTooltip from '@/host/components/smartform/MethodTooltip'
import SmartFormRadioButton from '@/host/components/smartform/RadioButton'
import { IconContainerMixin } from '@/host/components/mixins/IconContainer'
import DomReactMixin from '@/host/components/mixins/DomReact'
import { SmartFormClickMixin } from '@/host/components/mixins/SmartFormClick'
import { FlashWarningSubscriberMixin } from '@/host/components/mixins/FlashWarningSubscriber'

/**
 * @since KJS-2043
 */
export default {
  name: 'SmartFormMethodsGroup',
  components: {
    SmartFormMethodTooltip,
    SmartFormRadioButton
  },
  mixins: [
    IconContainerMixin,
    DomReactMixin,
    FlashWarningSubscriberMixin,
    SmartFormClickMixin
  ],
  props: {
    name: { type: String, default: '' },
    paymentMethods: { type: Array, default: () => [] },
    locked: { type: Boolean, default: false },
    deltaWidth: { type: Number, default: 8 },
    iconsFilter: { type: String, default: 'all' },
    showTooltip: { type: Boolean, default: true }
  },
  data() {
    return {
      max: null,
      assets: null,
      hover: false,
      iconsConfig: []
    }
  },
  computed: {
    ...mapGetters([
      'translate',
      'hasSmartButton',
      'isSmartFormPopin',
      'isSinglePaymentButton'
    ]),
    ...mapState(['allIFramesReady', 'disabledForm', 'isUnitaryTest']),
    ...mapState({
      selectedMethod: state => state.smartForm.selectedMethod,
      radioButtonConfig: state => state.form.smartform.radioButton
    }),
    dynamicClasses() {
      return {
        'kr-methods-group-activator--spbtn': this.isSinglePaymentButton,
        'kr-methods-group-activator--selected': this.isSelected,
        'kr-methods-group-activator--warning': this.applyWarningStyle,
        [`kr-methods-group-${this.name.toLowerCase()}`]: true,
        'kr-locked': this.isDisabled
      }
    },
    isSelected() {
      return !!~this.paymentMethods.indexOf(this.selectedMethod)
    },
    label() {
      const key = `smartform_group_${this.name.toLowerCase()}`
      if (key !== this.translate(key)) return this.translate(key)
      const configLabel = PaymentMethodsConf.labels[this.name]
      if (configLabel) return configLabel
      return this.name
    },
    isDisabled() {
      return !this.allIFramesReady || this.locked || this.disabledForm
    },
    groupNameWidth() {
      if (!this._isMounted || this.isUnitaryTest) {
        return 0
      }
      const font = getElementFont(this.$refs.label)
      return measureTextWidth(this.label, { font })
    },
    radioButtonWidth() {
      if (!this._isMounted) {
        return 0
      }
      if (!this.isSinglePaymentButton || !this.$refs.radioButton) return 0
      const $el = this.$refs.radioButton.$el
      const computedStyles = window.getComputedStyle($el)

      return (
        $el.clientWidth +
        parseFloat(computedStyles.paddingLeft) +
        parseFloat(computedStyles.paddingRight)
      )
    },
    hasRadioButton() {
      return (
        this.isSinglePaymentButton && this.radioButtonConfig.visibility === true
      )
    },
    shouldDetach() {
      return this.isSmartFormPopin
    },
    showTooltipLabel() {
      return !!this.tooltipLabel
    },
    /**
     * Overload tooltipLabel computed value from IconContainer mixin.
     *
     * @since KJS-4711
     */
    tooltipLabel() {
      return this.tooltipIcons.length > 0 ? `+${this.tooltipIcons.length}` : ''
    },
    /**
     * If a specific filter is on (e.g: grouping for Cofidis), then we want to
     * display every icon in the tooltip if the configuration allows it.
     *
     * @since KJS-4711
     */
    tooltipIcons() {
      if (this.iconsFilter !== 'all') {
        if (PaymentMethodsConf.groups[this.name].tooltip !== true) return []
        return this.icons.all
      }
      return this.icons.outstanding
    }
  },
  watch: {
    max: 'applyMax',
    async paymentMethods() {
      await this.updateIcons()
      await this.$nextTick()
      this.forceResize()
    },
    icons: {
      deep: true,
      async handler() {
        if (this.iconsFilter === 'all') {
          this.iconsConfig = this.icons.main.slice(0, this.icons.number)
        }
      }
    }
  },
  /**
   * created handled being asynchronous, Vue will not wait for it ends
   * to start mounting the component. Therefore the icons will not appears
   * in the DOM until the assets are loaded, and it will be impossible
   * to correctly calculate how many icons can be displayed, given their
   * size is not known.
   *
   * @see KJS-2535
   */
  async mounted() {
    if (!this.isUnitaryTest) {
      this.assets = assets
      await this.updateIcons()

      if (this.iconsFilter === 'all') {
        this.iconsConfig = this.icons.main
        await this.$nextTick()
        this.forceResize()
        return
      }
      if (!this.assets) {
        this.iconsConfig = []
        return
      }

      const iconDef =
        this.assets.paymentMethods.regular[this.iconsFilter.toUpperCase()]
      this.iconsConfig = [
        {
          icon: iconDef && (await iconDef()),
          name: this.iconsFilter
        }
      ]

      await this.$nextTick()
      this.forceResize()
    }
  },
  methods: {
    forceResize() {
      const computedStyles = window.getComputedStyle(this.$refs.activator)
      const width =
        this.$refs.activator.clientWidth -
        parseFloat(computedStyles.paddingLeft) -
        parseFloat(computedStyles.paddingRight)
      this.onResize({ width })
    },
    async openGroup() {
      if (this.locked || this.isDisabled) return

      if (
        await this.interruptsExecution(
          `GROUP_${this.name.toUpperCase()}`,
          'openPopin'
        )
      )
        return false

      this.$emit('selected', this.name)
    },
    async getBrandConf(method) {
      const iconDef = this.assets.paymentMethods.regular[method]
      const icon =
        (iconDef && (await iconDef())) || PreloadedAssets.regular.cardOutline
      return {
        icon,
        name: method.toLowerCase()
      }
    },
    async updateIcons() {
      const paymentMethods = this.paymentMethods.filter(
        method => !this.hasSmartButton(method)
      )
      const paymentMethodsConf = []
      for (const method of paymentMethods) {
        paymentMethodsConf.push(await this.getBrandConf(method))
      }
      this.icons.all = paymentMethodsConf
      this.setIconsNumber(this.icons.all.length)
    },
    applyMax() {
      if (this.applyMax === 'number' && this.max > 0) {
        this.setIconsNumber(this.max)
      }
    },
    onResize(size) {
      this.setIconsNumber(this.getIconsLimit(size.width))
    },

    /**
     * Upon label-wrapper resize, evaluate the best card icons limit.
     *
     * The limit width is the button width,
     *   minus the tooltip width & group label with.
     *
     * Methods icons width can be calculated as below:
     * Given
     *   - the number of methods:           n
     *   - The method icon width:           20px
     *   - Gap between each icon:           5px
     *
     * Then the total width is (20 + 5)n
     * Hence 25n
     *
     * @since KJS-2355
     */
    getIconsLimit(width) {
      const limitWidth =
        width - this.groupNameWidth - this.radioButtonWidth - this.deltaWidth
      const iconsRef = this.$refs.icons
      if (!iconsRef) return
      const iconW = iconsRef[0].clientWidth
      const gap = this.calculateGap(iconW)
      const calculator = n => {
        const tooltipW =
          n === this.icons.all.length ? 0 : this.$refs.tooltip.$el.clientWidth
        return (iconW + gap) * n + tooltipW
      }

      return this.calculateMaximumIcons(
        limitWidth,
        this.icons.all.length,
        calculator
      )
    }
  }
}
</script>
