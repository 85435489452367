import { isEmpty } from 'underscore'
import AbstractTrigger from '@/ghost/service/Triggers/AbstractTrigger'
import Events from '@/configuration/Events'

/**
 * This class handles the reception of the samsungPayPayment store action
 * from the ghost, calls the processPayment and executes the workflow
 */
export default class SamsungPayPaymentTrigger extends AbstractTrigger {
  constructor($locator) {
    super($locator, 'samsungPayPayment')
    this.browserRequests = $locator.browserRequests
    this.setupListeners()
  }

  setupListeners() {
    /*
     * For samsung pay with extra fields, need to save the payment token data to be
     * used later after the extras form validation
     */
    this.$bus.$on(Events.ghost.samsungPay.payload, ({ payload }) => {
      this.payload = payload
    })
  }

  /**
   * SamsungPay Payment
   * @method onEvent
   */
  async onEvent({ payload }) {
    try {
      // For extra fields
      if (isEmpty(payload) && this.payload) payload = this.payload

      await super.onEvent({ payload })

      if (this.$store.getters.extrasFormHasData) {
        // Required to properly reset form $ button after error
        this.$store.dispatch('paymentStart')

        // Validate extra fields form
        this.$locator.validationManager.extraFieldValidation()
      }

      // Process the payment
      const { response } = await this.processSamsungPayPayment(payload)
      // Validate the response
      if (this.isValid(response)) {
        // Transaction - send the data to the payment handler
        this.$locator.paymentManager.handler(response.answer)
      } else {
        this.onError({
          metadata: { answer: response.answer },
          paymentMethod: 'SAMSUNG_PAY'
        })
      }
    } catch (error) {
      this.onError(error)
    }
  }

  onError(error, path = 'ghost/service/SamsungPayPaymentTrigger.onEvent') {
    super.onError(error, path)
    if (!this.$store.getters.isExtrasFormVisible)
      this.$store.dispatch('closeMethod')
    this.proxy.send(this.storeFactory.create('samsungPayPaymentError'))
  }

  /**
   * Calls the ProcessPayment
   *
   * @param {Object} payload
   * @returns {Promise}
   */
  async processSamsungPayPayment(payload) {
    const { objectData, url } = this.restAPI.buildProcessPaymentWalletPayload({
      payload,
      payloadType: 'SAMSUNG_PAY',
      endpoint: this.endpoint
    })

    const requestData = this.storeFactory.create('requestData', {
      url,
      objectData,
      headers: {},
      options: {}
    })
    return await this.browserRequests.post(requestData)
  }

  /**
   * Validates the server response object
   *
   * @param {*} response
   * @returns {boolean}
   */
  isValid(response) {
    return !(
      response.status === 'ERROR' ||
      response.answer?.errorCode ||
      response.answer.clientAnswer?.transactions[0].errorCode
    )
  }
}
