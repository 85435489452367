<template lang="pug">
PaymentButton.kr-popin-button(
  type="button"
  v-if="isVisible"
  v-show="showing"
  @click="open()"
  :class="dynamicClasses"
  :style="styles"
)
</template>

<script>
import _ from 'underscore'
import { mapState } from 'vuex'
import { dynamicMapState } from '@/common/util/store'
import Events from '@/configuration/Events'

import PaymentButton from '@/host/components/controls/PaymentButton'
import tokens from '@/configuration/sources/Tokens.yml'

export default {
  name: 'KryptonPopinButton',
  components: { PaymentButton },
  props: ['krVisible'],
  data() {
    return {
      showing: true
    }
  },
  computed: {
    ...mapState(['isPopinOpen', 'formToken', 'formId']),
    ...mapState({
      buttonConfig: state => state.form.button,
      isVisible: state => state.form.popin.button
    }),
    ...dynamicMapState('context.namespace', ['disabledCardForm']),
    styles() {
      return {
        color: this.buttonConfig.color,
        backgroundColor: this.buttonConfig.backgroundColor,
        borderColor: this.buttonConfig.borderColor
      }
    },
    dynamicClasses() {
      return {
        'kr-disabled': this.disabled,
        'kr-loading': this.isPopinOpen
      }
    },
    disabled() {
      return this.disabledCardForm || this.formToken === tokens.noToken
    }
  },
  watch: {
    formToken: 'checkPopinPosition'
  },
  created() {
    this.startSubscriptions()
  },
  methods: {
    startSubscriptions() {
      this.$busOn(Events.krypton.popin.open, message => {
        this.open()
      })

      this.$busOn(Events.krypton.form.hideForm, message => {
        this.showing = false
      })

      this.$busOn(Events.krypton.form.showForm, message => {
        this.showing = true
      })
    },
    open() {
      if (!this.disabled) {
        this.$store.dispatch('openPopin')
      }
    }
  }
}
</script>
