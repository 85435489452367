/**
 * Log errors occuring during a factory method execution.
 *
 * @since KJS-4764
 */
export default class FactoryLogger {
  constructor() {
    this.errors = []
  }

  /**
   * @param {any} e Error
   * @returns {this}
   */
  error(e) {
    this.errors.push(e)
    return this
  }

  /**
   * @param {FactoryLogger} logger
   * @returns {this}
   */
  merge(logger) {
    this.errors.push(...logger.errors)
    return this
  }
}
