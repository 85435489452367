var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVisible
    ? _c("PaymentButton", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showing,
            expression: "showing",
          },
        ],
        staticClass: "kr-popin-button",
        class: _vm.dynamicClasses,
        style: _vm.styles,
        attrs: { type: "button" },
        on: {
          click: function ($event) {
            return _vm.open()
          },
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }