var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "kr-popin-total-amount" }, [
    _c("span", { staticClass: "kr-total-amount-label" }, [
      _vm._v(_vm._s(_vm.translate("partial_payment_total_amount"))),
    ]),
    _c(
      "span",
      {
        staticClass: "kr-total-amount-value",
        style: { color: _vm.merchant.header.amount.color },
      },
      [_vm._v(_vm._s(_vm.totalAmountLabel))]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }