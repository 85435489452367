<template lang="pug">
  .kr-card-form(v-if="isRendered" :class="dynamicClasses")
    slot
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { dynamicMapState } from '@/common/util/store'

import { CardFormThemeMixin } from '@/host/components/mixins/CardFormTheme'
import BinUpdateRequest from '@/host/components/mixins/BinUpdateRequest'
import InputFocusHandler from '@/host/components/mixins/InputFocusHandler'
import OutOfViewMixin from '@/host/components/mixins/OutOfView'

/**
 * .kr-embedded form is now in rendered as a component to:
 * - Allow passing the form ID to any component in the subtree
 * - Better component reading inside Vue debug tool.
 *
 * @since KJS-3010
 */
export default {
  name: 'KryptonCardForm',
  mixins: [
    CardFormThemeMixin,
    BinUpdateRequest,
    InputFocusHandler,
    OutOfViewMixin
  ],
  provide() {
    return {
      context: this.context
    }
  },
  data() {
    return {
      context: {
        formId: null,
        namespace: null
      }
    }
  },
  computed: {
    ...mapState(['formMode', 'isPopinOpen']),
    ...mapGetters([
      'hasCardMethodAvailable',
      'isCardFormRendered',
      'isWallet',
      'isWalletSmartForm',
      'hasCustomOrder'
    ]),
    ...dynamicMapState('context.namespace', ['disabledCardForm']),
    dynamicClasses() {
      return {
        ...this.dynamicThemeClasses,
        'kr-embedded-enabled': !this.disabledCardForm,
        'kr-embedded-disabled': this.disabledCardForm,
        'kr-embedded--no-card': !this.hasCardMethodAvailable,
        'kr-out-of-view-form': this.outOfView,
        'kr-popin-open': this.isPopinOpen,
        'kr-wallet': this.isWallet && !this.isWalletSmartForm,
        'kr-custom-order': this.hasCustomOrder
      }
    },
    // Set by DNA reader for main form, by FormRenderer for clone in order to
    // Allow reactivity for forms added later on
    isRendered() {
      return this.isCardFormRendered(this.context.formId)
    }
  },
  created() {
    this.context.formId = this.$attrs['kr-form']
    this.context.namespace = `cardForm_${this.context.formId}`
    // Clean the display:none style attribute if present
    if (this.$attrs['style']?.includes('display:none'))
      this.$attrs['style'] = this.$attrs['style'].replace('display:none', '')
    this.cleanStaticClass()
    this.$locator.cardFormController.registerForm(this)
  },
  methods: {
    /**
     * When kr-out-of-view-form is added to template before the templateCompiler
     * loads the app (see KR.addForm), it is added to the component staticClass
     * (undocumented) component attributes data.
     *
     * This led to some unexpected behaviours where an update of dynamicClasses
     * could restore it despite KR.showForm being called to remove it.
     * (e.g: after "isPopinOpen" update).
     *
     * Therefore we need to remove it from the staticClass and let the component
     * handle its dynamic value.
     *
     * @since KJS-4335
     */
    cleanStaticClass() {
      const $vnData = this.$vnode.data

      if ($vnData.staticClass.includes('kr-out-of-view-form')) {
        $vnData.staticClass = $vnData.staticClass
          .split(' ')
          .filter(className => className !== 'kr-out-of-view-form')
          .join(' ')
        this.outOfView = true
      }
    },
    /**
     * Return a Proxy of current instance.
     * Accessible properties:
     *  - getProperty
     *
     * @returns {Proxy & { getProperty: Function }} Proxy to limit accessible properties
     * @since KJS-4240
     */
    proxy() {
      return new Proxy(this, {
        get(target, prop) {
          if (prop === 'getProperty') {
            return target[prop]
          }
        }
      })
    },
    /**
     * Access root element property.
     * Originally used to access offsetTop in KryptonPopinButton.
     *
     * @since KJS-4240
     */
    getProperty(prop) {
      return this.$el[prop]
    }
  }
}
</script>
