export default {
  abortByUser: [],
  actionNewForm: ['formId', 'dna'],
  actionSecurityCodeHelper: [],
  billingRedirect: {
    fields: [
      '_type',
      'redirectUrl',
      'width',
      'height',
      'template',
      'postData',
      'allowIFrame',
      'hideAtStartup',
      'hideTimeout',
      'formType'
    ]
  },
  billingRedirectV2: ['_type', 'width', 'height'],
  billingTransaction: {
    fields: [
      'clientAnswer',
      'hash',
      'hashAlgorithm',
      'hashKey',
      '_type',
      'receipt',
      'serverDate',
      'extra',
      'formId',
      'rawClientAnswer'
    ],
    methods: {
      isValid: ({ clientAnswer, hash }) =>
        clientAnswer?.transactions[0]?.uuid?.length &&
        hash?.length &&
        clientAnswer?.transactions[0]?.status?.length &&
        clientAnswer?.orderDetails?.mode?.length
    }
  },
  billingPartialPayment: [
    'clientAnswer',
    'hash',
    'hashAlgorithm',
    'hashKey',
    'serverDate',
    '_type',
    'form',
    'extra',
    'rawClientAnswer',
    'formId'
  ],
  echo: ['awareId', 'source', 'fieldName', 'formId'],
  ready: ['source'],
  fieldEvent: ['name', 'type', 'formId'],
  fieldLength: ['name', 'length', 'formId'],
  fieldUpdate: ['length', 'name', 'configuration', 'brand', 'formId'],
  fieldValue: ['name', 'value', 'formId'],
  fieldInvalid: ['name', 'formId'],
  fieldValid: ['name', 'formId'],
  fieldViable: ['name', 'formId'],
  fieldInviable: ['name', 'formId'],
  focus: ['name', 'formId'],
  pay: {
    fields: ['formId', 'formToken', 'extra', 'refererUrl', 'googlePayData'],
    defaults: {
      refererUrl: window.document.referrer,
      googlePayData: null
    }
  },
  getTransaction: [
    'paymentMethod',
    'formToken',
    'publicKey',
    'remoteId',
    'dna'
  ],
  payment: {
    fields: [
      'clientAnswer',
      'hash',
      'hashAlgorithm',
      'hashKey',
      'serverDate',
      '_type',
      'form',
      'extra',
      'rawClientAnswer',
      'formId'
    ],
    defaults: {
      form: {},
      extra: {},
      orderStatus: ''
    },
    methods: {
      post: ({ hash, hashKey, hashAlgorithm, clientAnswer, form, extra }) => {
        const obj = {
          'kr-hash': hash,
          'kr-hash-algorithm': hashAlgorithm,
          'kr-answer-type': clientAnswer['_type'],
          'kr-answer': JSON.stringify(clientAnswer)
        }
        if (hashKey) obj['kr-hash-key'] = hashKey

        for (const formKey in form) {
          if (!(formKey in obj)) obj[formKey] = form[formKey]
        }

        for (const extraKey in extra) {
          if (!(extraKey in obj)) obj[extraKey] = extra[extraKey].value
        }

        return obj
      },
      isValid: store =>
        store.clientAnswer?.transactions[0]?.uuid?.length &&
        store.hash?.length &&
        store.clientAnswer?.transactions[0]?.status?.length &&
        store.clientAnswer?.orderDetails?.mode?.length
    }
  },
  paymentEnd: [],
  paymentStart: [],
  popinLoad: [],
  requestData: {
    fields: ['url', 'objectData', 'options', 'headers', 'timeout'],
    methods: {
      prepareRequest: store => {
        return [store.url, store.objectData, store.options]
      },
      getHeaders: store => {
        return store.options.headers
      },
      setHeader: (store, ...args) => {
        let name = args[0]
        let value = args[1]

        if (typeof store.options.headers === 'undefined')
          store.options.headers = {}
        store.options.headers[name] = value
      }
    }
  },
  requestResponse: {
    fields: ['response', 'statusCode', 'timestamp', 'headers'],
    methods: {
      getError: store => {
        let statusCode = parseInt(store.statusCode, 10)
        if (statusCode == 200) return null
        return {
          code: 'REQUEST_RESPONSE_STORE_001',
          message: `Status code from server ${statusCode}`
        }
      },
      getStatusCode: store => {
        return parseInt(store.statusCode, 10)
      }
    }
  },
  loaded: ['formId'],
  log: ['type', 'message', 'data'],
  validate: {
    fields: ['silent', 'throwError', 'formId'],
    defaults: {
      silent: true,
      throwError: true
    }
  },
  validationResponse: {
    fields: ['formId', 'result', 'message', 'code', 'error', 'silent'],
    defaults: {
      children: [],
      silent: false
    }
  },
  sync: {
    fields: ['data', 'origin', 'metadata', 'force'],
    defaults: {
      force: false
    }
  },
  globalSync: [],
  popinLoad: [],
  deleteToken: ['formId', 'paymentMethodToken', 'method'],
  payWithToken: ['formId', 'paymentMethodToken', 'method'],
  declinePartialPaymentCall: ['transactionUuid'],
  declinePartialPaymentApply: ['clientAnswer', 'transactionUuid'],
  closeModals: [],
  focusPopup: [],
  redirect: {
    fields: ['paymentMethod']
  },
  finishedRedirection: [],
  navigateBack: [],
  getApplePayToken: ['validationURL'],
  applePayMerchantSession: ['merchantSession'],
  appleOrGooglePayPayment: ['paymentToken', 'payloadType'],
  getPayloadInfos: ['payload', 'testMode', 'payloadType'],
  abortApplePaySession: [],
  getPayloadInfosError: [],
  applePayPaymentError: [],
  applePayPaymentVerified: [],
  setInitialAmount: [],
  closeReceipt: [],
  closeSimpleModal: [],
  setupGooglePaySDK: [],
  startGooglePay: [],
  closeGooglePaySimulator: [],
  googlePaySimulatorAction: ['type', 'payload'],
  nonSensitiveAutofillUpdate: ['formId', 'payload'],
  samsungPayPayment: ['payload'],
  samsungPayPaymentError: [],
  launchSamsungPayPayment: [],
  setupSamsungPaySDK: [],
  samsungPaySimulatorAction: ['type', 'payload'],
  closeSamsungPaySimulatorStore: [],
  getReceipt: []
}
