var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVisible
    ? _c(
        "div",
        {
          staticClass: "kr-help-button-wrapper",
          class: [
            _vm.isVisible ? "kr-visible" : "",
            _vm.positionClass,
            _vm.isDisabled ? "kr-disabled" : "",
          ],
        },
        [
          _c("div", { staticClass: "kr-help-button", style: _vm.css }, [
            _c(
              "div",
              {
                staticClass: "kr-help-icon-wrapper",
                class: [_vm.isInvalid ? "kr-error" : ""],
                style: { backgroundColor: _vm.css.color },
                on: {
                  click: function ($event) {
                    return _vm.openModal()
                  },
                },
              },
              [
                _c("span", {
                  class: _vm.customIcon
                    ? "kr-custom-help-icon"
                    : "kr-help-icon",
                  domProps: { innerHTML: _vm._s(_vm.customIcon || _vm.icon) },
                }),
              ]
            ),
          ]),
          _c(
            "KryptonDialog",
            {
              attrs: { "content-class": "kr-help-button-wrapper" },
              model: {
                value: _vm.open,
                callback: function ($$v) {
                  _vm.open = $$v
                },
                expression: "open",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "kr-help-modal-wrapper",
                  on: {
                    click: function ($event) {
                      return _vm.closeModal()
                    },
                  },
                },
                [
                  _c("div", { staticClass: "kr-help-modal-background" }),
                  _c(
                    "div",
                    {
                      staticClass: "kr-help-modal",
                      style: [_vm.modalSize],
                      attrs: { role: "dialog" },
                    },
                    [
                      _c("div", { staticClass: "kr-help-modal-header" }, [
                        _c("span", {
                          staticClass: "kr-help-modal-close-button",
                          domProps: { innerHTML: _vm._s(_vm.closeIcon) },
                          on: {
                            click: function ($event) {
                              return _vm.closeModal()
                            },
                          },
                        }),
                      ]),
                      _c("div", {
                        staticClass: "kr-help-modal-content",
                        domProps: { innerHTML: _vm._s(_vm.htmlContent) },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }