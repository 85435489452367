var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      ref: "content",
      staticClass: "kr-simple-modal-wrapper",
      class: _vm.dynamicClasses.wrapper,
    },
    [
      _c("div", { staticClass: "kr-simple-modal" }, [
        _c(
          "header",
          { staticClass: "kr-simple-modal-header" },
          [
            _vm.modalData.method
              ? _c("BrandIcon", { attrs: { method: _vm.modalData.method } })
              : _vm._e(),
            _c("h2", {
              staticClass: "kr-simple-modal-header-title",
              domProps: { innerHTML: _vm._s(_vm.computedTitle) },
            }),
            _c("CloseButton", {
              attrs: { "is-visible": true },
              on: {
                close: function ($event) {
                  return _vm.onAction("closeModal", "close")
                },
              },
            }),
          ],
          1
        ),
        _c("main", { staticClass: "kr-simple-modal-content" }, [
          _c(
            "section",
            { staticClass: "kr-simple-modal-content-main" },
            _vm._l(_vm.content, function (item) {
              return _c("p", {
                domProps: { innerHTML: _vm._s(_vm.contentHtml(item)) },
              })
            }),
            0
          ),
          _vm.hasOptionalContent
            ? _c(
                "section",
                { staticClass: "kr-simple-modal-content-optional" },
                _vm._l(_vm.optionalContent, function (item) {
                  return _c(
                    "div",
                    { staticClass: "kr-simple-modal-content-optional-item" },
                    [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(_vm.optionalLabelHtml(item)),
                        },
                      }),
                      item.link
                        ? _c("a", {
                            attrs: { href: _vm.optionalLinkHref(item) },
                            domProps: {
                              innerHTML: _vm._s(_vm.optionalLinkHtml(item)),
                            },
                          })
                        : _vm._e(),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]),
        _c(
          "footer",
          {
            staticClass: "kr-simple-modal-footer",
            class: _vm.dynamicClasses.verticalButtons,
          },
          _vm._l(_vm.buttons, function (button) {
            return _c("SimpleModalButton", {
              key: button.name,
              attrs: { config: button, loading: _vm.loading },
              on: {
                click: function ($event) {
                  return _vm.onAction(
                    button.action,
                    button.name,
                    button.loading
                  )
                },
              },
            })
          }),
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }