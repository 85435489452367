<template lang="pug">
  SmartFormGrid(v-if="isGridMode")
    template(v-slot:cardForm="")
      slot
    template(v-slot:extra="")
      slot(name="extra")
  SmartFormLegacy(v-else)
    template(v-slot:cardForm="")
      slot
    template(v-slot:extra="")
      slot(name="extra")
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Events from '@/configuration/Events'

import SmartFormLegacy from '@/host/components/smartform/SmartFormLegacy'
import SmartFormGrid from '@/host/components/smartform/grid/SmartForm'

export default {
  name: 'SmartForm',
  components: {
    SmartFormLegacy,
    SmartFormGrid
  },
  computed: {
    ...mapState(['allIFramesReady']),
    ...mapGetters(['isGridMode', 'cardsFormExpanded', 'getFormElement'])
  },
  watch: {
    async isGridMode(val) {
      this.ensureAttributeCoherence()

      // Trigger the relocation actions
      await this.$nextTick()
      this.$bus.$emit(Events.iframe.relocation, { slaves: true })
    },
    async cardsFormExpanded() {
      /**
       * Skip the relocation if the form is not ready yet.
       * See: KJS-4521
       */
      if (!this.allIFramesReady) return
      this.$bus.$emit(Events.krypton.form.relocation, {})

      // Trigger the relocation actions
      await this.$nextTick()
      this.$bus.$emit(Events.iframe.relocation, { slaves: true })
      this.getFormElement().style.removeProperty('display') // Show (in case it's hidden)
    }
  },
  mounted() {
    this.ensureAttributeCoherence()
  },
  methods: {
    /**
     * This was originally directly implemented in isGridMode watcher.
     * However if application is booted without grid and we switch to grid mode
     * before this component could be mounted, the watcher is not called and
     * the attribute kr-grid not set. Hence an additional check should be run
     * when the components is mounted.
     *
     * @since KJS-4204
     */
    ensureAttributeCoherence() {
      // Ignore if component has not parent - Required for most unit tests but
      // also necessary as a safeguard in production
      if (!(this.$el.parentElement instanceof Element)) return

      if (this.isGridMode) {
        this.$el.parentElement.setAttribute('kr-grid', '')
      } else {
        this.$el.parentElement.removeAttribute('kr-grid')
      }
    }
  }
}
</script>
