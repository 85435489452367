var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "kr-sp-radio-button",
      class: _vm.dynamicClasses,
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 100 100",
        fill: "none",
      },
    },
    [
      _c("circle", {
        attrs: {
          cx: "50%",
          cy: "50%",
          r: "48",
          stroke: "var(--circle-graph-color)",
          "stroke-width": "4",
        },
      }),
      _vm.isChecked
        ? _c("circle", {
            attrs: {
              cx: "50%",
              cy: "50%",
              r: "24",
              fill: "var(--circle-graph-color)",
            },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }