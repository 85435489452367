var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "kr-split-payment-circle-graph",
      style: _vm.dynamicStyles,
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 100 100",
        fill: "none",
      },
    },
    [
      _c("circle", {
        attrs: {
          cx: "50%",
          cy: "50%",
          r: "48",
          stroke: "var(--circle-graph-color)",
          "stroke-width": "4",
        },
      }),
      _vm.percentage === 100
        ? _c("circle", {
            attrs: {
              cx: "50%",
              cy: "50%",
              r: _vm.chartRadius,
              fill: "var(--circle-graph-color)",
            },
          })
        : _vm.hasPreformattedPath
        ? _c("path", {
            attrs: {
              fill: "var(--circle-graph-color)",
              d: _vm.preformattedPath[_vm.percentage],
            },
          })
        : _vm.percentage > 0
        ? _c(
            "g",
            {
              attrs: { "transform-origin": "center", transform: "rotate(-90)" },
            },
            [
              _c("circle", {
                attrs: {
                  cx: "50%",
                  cy: "50%",
                  r: _vm.strokeRadius,
                  stroke: "var(--circle-graph-color)",
                  "stroke-width": _vm.chartRadius,
                  "stroke-dasharray": _vm.dasharray,
                },
              }),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }