<template lang="pug">
  .kr-methods-list(:class="dynamicClasses.wrapper")
    .kr-methods-list-header(
      v-if="renderListHeader"
    ) {{ listHeaderLabel | capitalize | colon }}
    .kr-methods-list-inner-wrapper
      PartialPaymentPanel
      .kr-methods-list-options.kr-methods-list-options--extra(v-if="renderExtraOptions" :class="dynamicClasses.listOptions")
        .kr-methods-list-row(v-show="walletMode")
          .kr-smart-form-list-section-name {{ walletHeaderLabel | capitalize | colon }}
          SmartFormWallet(v-if="walletMode" v-on:selected="loadMethod", :all-locked="allLocked", :compact="isCompactWallet" :methods="filteredPaymentMethods.toString()" )
        .kr-methods-list-row(v-if="renderIsolatedCards")
          .kr-smart-form-list-section-name(v-show="!hasCardFormExpanded || (walletMode && !registerMode)") {{ registerCardLabel | capitalize | colon  }}
          .kr-methods-list-options-item(v-if="!hasCardFormExpanded" :class="methodClass('CARDS')")
            SmartFormMethodLabel(
              key="CARDS"
              method="CARDS"
              :locked="allLocked"
              v-on:selected="loadMethod"
            )
          .kr-methods-list-card-form-wrapper(v-if="hasCardFormExpanded")
            SplitPaymentCardLabel.kr-smart-form-list-section-name(v-if="shouldRenderSPCardHeader")
            SmartFormCardHeader(v-if="hasCardHeader")
            .kr-smart-form-list-section-name(v-if="registerMode || hasPartialPayments") {{ registerCardLabel | capitalize | colon  }}
            slot(name="cardForm")
        .kr-smart-form-list-section-name.kr-smart-form-list-section-name--other(v-if="hasAnyItem") {{ otherLabel | capitalize | colon  }}
      .kr-methods-list-options(:class="dynamicClasses.listOptions")
        template(v-for="(methods, key) in items")
          template(v-if="key === 'single'")        
            .kr-methods-list-options-item(
              v-for="method in methods",
              :key="method",
              :class="methodClass(method)"
            )
              // Ungroupped methods
              SmartFormMethodLabel(
                :key="method"
                :method="method"
                :locked="allLocked"
                v-on:selected="loadMethod"
              )
          // Groups
          .kr-methods-list-options-item(v-if="key !== 'single'")
            SmartFormMethodsGroup(
              :key="key"
              :name="key"
              :payment-methods="methods",
              :locked="allLocked",
              :icons-filter="getIcons(key)"
              v-on:selected="loadGroup"
            )
    SmartFormError(v-if="mode === 'groups'")
    SmartFormGroupError(v-else-if="mode === 'methods'")
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import paymentMethodsConf from '@/configuration/sources/smartform/paymentMethodsConf.yml'
import SmartFormError from '@/host/components/smartform/Error'
import SmartFormGroupError from '@/host/components/smartform/groups/Error'
import SmartFormMethodsGroup from '@/host/components/smartform/groups/MethodsGroup'
import SmartFormMethodLabel from '@/host/components/smartform/MethodLabel'
import SmartFormCardHeader from '@/host/components/smartform/CardHeader'
import SmartFormWallet from '@/host/components/smartform/wallet/Wallet'
import PartialPaymentPanel from '@/host/components/smartform/partialPayment/PartialPaymentPanel'
import SplitPaymentCardLabel from '@/host/components/smartform/splitPayment/CardLabel'

export default {
  name: 'SmartFormMethodsListLabel',
  components: {
    SmartFormCardHeader,
    SmartFormMethodLabel,
    SmartFormMethodsGroup,
    SmartFormWallet,
    SmartFormError,
    SmartFormGroupError,
    PartialPaymentPanel,
    SplitPaymentCardLabel
  },
  props: {
    isModal: { type: Boolean, default: false },
    allLocked: { type: Boolean, default: false },
    mode: { type: String, default: 'methods' }
  },
  computed: {
    ...mapState(['amount']),
    ...mapState({
      activeMethod: state => state.smartForm.activeMethod,
      errorCode: state => state.error.errorCode,
      otherMethodsLabel: state => state.smartForm.labels.otherPaymentMethods
    }),
    ...mapGetters([
      'shouldRenderSPCardHeader',
      'sortedPaymentMethods',
      'cardsFormExpanded',
      'groupedPaymentMethods',
      'activeGroupPaymentMethods',
      'hasCardHeader',
      'hasCardMethodAvailable',
      'hasCardMethodOption',
      'hasSmartButton',
      'hasTokens',
      'isExtrasFormVisible',
      'isGroupActive',
      'isSinglePaymentButton',
      'isSmartFormCompact',
      'isSmartFormOpen',
      'isSmartFormPopin',
      'isSmartFormSmall',
      'isWalletSmartForm',
      'numOfPaymentMethods',
      'outsideCardsForm',
      'translate',
      'hasPartialPayments',
      'isCompactWallet'
    ]),
    ...mapGetters({
      paymentMethods: 'sortedPaymentMethods',
      paymentMethodGroups: 'effectivePaymentMethodGroupList'
    }),
    dynamicClasses() {
      return {
        wrapper: {
          'kr-methods-list--compact': this.isCompact,
          'kr-method-active': this.activeMethod,
          'kr-error': this.errorCode,
          'kr-group-active':
            this.isGroupActive &&
            this.mode === 'methods' &&
            this.isSmartFormPopin
        },
        listOptions: {
          'kr-methods-list-options--spbtn': this.isSinglePaymentButton,
          'kr-methods-list-options--compact': this.isCompact,
          'kr-methods-list-options--wallet': this.walletMode,
          'kr-methods-list-options--register': this.registerMode
        }
      }
    },
    renderListHeader() {
      return (
        this.isModal &&
        !this.registerMode &&
        !this.walletMode &&
        !this.hasPartialPayments
      )
    },
    listHeaderLabel() {
      // Customized label
      if (this.otherMethodsLabel) return `${this.otherMethodsLabel}:`
      // Default translation
      return this.translate('smartform_list_header')
    },
    walletHeaderLabel() {
      if (!this.amount) {
        return this.translate('smartform_wallet_remove_payment_method')
      }
      return this.translate('smartform_wallet_mycard')
    },
    /**
     * By default:
     *  Pay with another card
     *
     * If amount=0 and no card token:
     *   Register my card
     *
     * If amount=0 and any card token exist:
     *   Register another card
     */
    registerCardLabel() {
      if (this.registerMode) {
        if (this.walletMode) {
          return this.translate('smartform_wallet_register_another_card')
        }
        return this.translate('smartform_register_card')
      }
      return this.translate('smartform_wallet_newcard')
    },
    registerOtherPaymentMethods() {
      return this.translate('smartform_register_another_payment_method')
    },
    otherLabel() {
      if (this.registerMode) {
        return this.translate('smartform_register_another_payment_method')
      }

      // Customized label
      if (this.otherMethodsLabel) return `${this.otherMethodsLabel}:`
      const key =
        this.numOfPaymentMethods > 1
          ? 'smartform_other_payment_methods'
          : 'smartform_other_payment_method'
      return this.translate(key)
    },
    isCompact() {
      return this.isSmartFormCompact && !this.isSmartFormSmall
    },
    walletMode() {
      return this.isWalletSmartForm && this.hasTokens
    },
    registerMode() {
      return !this.amount
    },
    items() {
      let items = {}
      if (this.mode === 'groups') {
        // Clean up groups with no methods
        items = Object.fromEntries(
          Object.entries(this.paymentMethodGroups).filter(
            ([key, values]) => values.length > 0
          )
        )
      } else {
        const pm = this.isGroupActive
          ? this.activeGroupPaymentMethods.filter(
              method => !this.hasSmartButton(method)
            )
          : this.paymentMethods
        items = { single: pm }
      }
      for (const key in items) {
        items[key] = items[key].filter(method => this.shouldDisplay(method))
      }
      return items
    },
    hasAnyItem() {
      for (const key in this.items) {
        if (this.items[key].length > 0) return true
      }
      return false
    },
    filteredPaymentMethods() {
      if (this.isGroupActive) {
        return this.activeGroupPaymentMethods.filter(
          method => !this.hasSmartButton(method)
        )
      }

      // If there are groups, filter out the methods that are already in a group
      if (this.mode === 'groups') {
        return this.paymentMethods.filter(
          method => !this.groupedPaymentMethods.includes(method)
        )
      }

      if (this.hasCardFormExpanded) {
        // Default, just filter out the cards
        return this.paymentMethods.filter(method => method !== 'CARDS')
      }
      return this.paymentMethods
    },
    hasCardFormExpanded() {
      return this.cardsFormExpanded && !this.outsideCardsForm
    },
    renderIsolatedCards() {
      return (
        this.hasCardMethodAvailable &&
        !this.outsideCardsForm &&
        (this.hasCardFormExpanded ||
          this.walletMode ||
          this.registerMode ||
          this.hasPartialPayments)
      )
    },
    hasExtraOptions() {
      return this.walletMode || this.renderIsolatedCards
    },
    renderExtraOptions() {
      if (!this.hasExtraOptions) return false
      if (this.isModal) {
        return !this.isGroupActive
      }
      return true
    }
  },
  methods: {
    loadMethod(method) {
      this.$emit('selected', method)
    },
    loadGroup(groupName) {
      this.$emit('group-selected', groupName)
    },
    hasCards(methodList) {
      return !!~methodList.indexOf('CARDS')
    },
    methodClass(method) {
      return 'kr-' + method.toLowerCase()
    },
    shouldDisplay(method) {
      if (this.mode === 'groups') {
        if (method === 'CARDS') {
          return (
            this.hasCardMethodOption &&
            !this.outsideCardsForm &&
            !this.renderIsolatedCards
          )
        } else {
          return !this.hasSmartButton(method)
        }
      } else {
        if (this.renderIsolatedCards) return method !== 'CARDS'
        return true
      }
    },
    getIcons(method) {
      return paymentMethodsConf.groups[method].icons
    }
  }
}
</script>
