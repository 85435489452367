import { FactoryResultWrapper, FactoryWarning } from '@/common/model/'

/**
 * Watch DNA changes in store, if the dna.installments parsing failed or
 * generated warnings (object could be instantiated), log errors or dispatch
 * warning accordingly.
 *
 * @since KJS-4764
 */
export default () => {
  return store => {
    store.watch(
      state => state?.dna?.installments,
      installments => {
        const wrapper = store.getters.installmentOptionsWrapper

        if (wrapper instanceof FactoryResultWrapper) {
          wrapper.getErrors().forEach(err => {
            if (err instanceof FactoryWarning && err.errorCode) {
              console.warn(err)
              store.dispatch('warning', err.errorCode)
            } else if (err instanceof Error) {
              console.error(err)
            }
          })
        }
      }
    )
  }
}
