import regeneratorRuntime from 'regenerator-runtime' // NECESSARY to use async
import _ from 'underscore'
import AbstractState from '@/ghost/workflow/payment/state/AbstractState'
import PaymentProcessedState from '@/ghost/workflow/payment/state/PaymentProcessedState'
import SimpleProcess from '@/ghost/workflow/payment/state/process/SimpleProcess'
import FingerprintProcess from '@/ghost/workflow/payment/state/process/FingerprintProcess'
import ChallengeProcess from '@/ghost/workflow/payment/state/process/ChallengeProcess'
import RedirectProcess from '@/ghost/workflow/payment/state/process/RedirectProcess'
import Events from '@/configuration/Events'
import { logSentry } from '@/common/util/sentry'

/**
 * Manages 3d secure v2 payment process
 */
export default class ProcessState extends AbstractState {
  constructor(...args) {
    super(...args)
    this.states = [SimpleProcess, FingerprintProcess, ChallengeProcess]
    this.currentStateIndex = 0
    this.ecsPaymentId = null
    this.shopId = this.$locator.$store.state.shopId
  }

  nextState(procResp) {
    if (this.isPaymentDone(procResp)) {
      this.currentStateIndex = this.states.length
      logSentry(
        `Payment`,
        `v2 Payment done at PP call ${this.currentStateIndex + 1}`
      )
    } else if (this.isPaymentRedirect(procResp)) {
      this.currentStateIndex = 0
      logSentry('Payment', 'v2 Redirection payment')
      return RedirectProcess
    } else {
      this.currentStateIndex += 1
      const isFingerprint =
        this.states[this.currentStateIndex] instanceof FingerprintProcess
      logSentry('Payment', `v2 ${isFingerprint ? 'Fingerprint' : 'Challenge'}`)
    }
    return this.states[this.currentStateIndex]
  }

  /**
   * Payment process through the different process states
   *
   * @param {} formObject Form instance
   * @return {Promise}
   */
  async processPayment(formObject) {
    this.formToken = formObject.url.formToken
    this.publicKey = formObject.url.publicKey

    // Payment start
    let response = formObject.url
    let procResp = null
    let state = null
    let currentState = this.states[this.currentStateIndex]
    while (this.currentStateIndex < this.states.length) {
      state = new currentState(this.$locator, formObject, this)
      logSentry('Payment', `v2 PP call ${this.currentStateIndex + 1}`)
      ;[response, procResp] = await state.process(response, procResp)
      logSentry(
        'Payment',
        `v2 Response received PP call ${this.currentStateIndex + 1}`
      )
      currentState = this.nextState(procResp) // Move to next state
    }
    // Finish the payment
    return this.paymentDoneProcess(response, procResp)
  }

  /**
   * Checks if the response is a payment instance
   */
  isPaymentDone(response) {
    return response instanceof PaymentProcessedState
  }

  /**
   * Checks if the response is a payment instance
   */
  isPaymentRedirect(response) {
    return (
      response.is && _.isFunction(response.is) && response.is('billingRedirect')
    )
  }

  handleReceipt(billingOrRedirect) {
    const { $bus, $store, storeFactory } = this.$locator

    return new Promise((resolve, reject) => {
      function receiptMessage(event) {
        if (event.data.action === 'close') {
          window.removeEventListener('message', receiptMessage)
          $store.dispatch('finishRedirection')
          resolve()
        }
      }

      if (
        billingOrRedirect.is('billingTransaction') &&
        billingOrRedirect?.receipt?.display === true
      ) {
        $store.dispatch('displaySmartFormModal')
        billingOrRedirect.receipt.redirectUrl = billingOrRedirect.receipt.url
        const { redirectUrl, width, height } = billingOrRedirect.receipt
        const billingRedirect = storeFactory.create('billingRedirect', {
          redirectUrl,
          width,
          height,
          hideCloseButton: true
        })
        billingRedirect.formId = this.context?.form?.id
        $bus.$emit(Events.krypton.payment.redirect, billingRedirect)
        $store.dispatch('setDccReceiptOpen', true)
        window.addEventListener('message', receiptMessage, false)
        $bus.$on(Events.krypton.message.closeReceipt, () => {
          receiptMessage({ data: { action: 'close' } })
        })
      } else {
        resolve()
      }
    })
  }

  /**
   * Moves to the next state and creates the transaction object
   */
  async paymentDoneProcess(response, state) {
    const context = this.context
    const formId = this.context?.form?.id
    const responseHandler = this.$locator.responseHandler

    context.changeState(state)
    const paymentResponse = response.response
    let billingOrRedirect = await responseHandler.factoryBillingOrRedirect(
      paymentResponse
    )

    // If an explicit null was sent (GooglePay|ApplePay), revert to undefined
    billingOrRedirect.formId = formId ?? undefined
    await this.handleReceipt(billingOrRedirect)

    return billingOrRedirect
  }
}
